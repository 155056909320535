import { useEffect } from "react";

const SyncStateWithUrlParam = (paramName: any, paramState: any, setState: any, defaultValue?: any) => {
  useEffect(() => {
    const storedParam = new URLSearchParams(window.location.search).get(paramName);
    if (storedParam) {
      setState({
        [paramName]: JSON.parse(storedParam),
      });
    }
  }, [paramName, setState]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const safeParamState = paramState || {};
    if (paramState && paramState !== defaultValue && paramState.length) {
      params.set(paramName, JSON.stringify(paramState));
    } else if (safeParamState[paramName] && safeParamState[paramName] !== defaultValue && safeParamState[paramName].length) {
      params.set(paramName, JSON.stringify(paramState[paramName]));
    } else {
      params.delete(paramName);
    }
    const queryString = params.toString();
    const url = queryString ? `${window.location.pathname}?${queryString}` : window.location.pathname;
    window.history.replaceState({}, '', url);

  }, [paramName, paramState]);
};

export default SyncStateWithUrlParam;