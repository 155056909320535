import {
  CreateAssetModelItemInput,
  ExternalProductCodeInput,
  ItemExternalProductCodeTypeEnum,
  ItemTypeEnum,
  UpdateAssetModelItemInput,
} from 'app/types/schema';

import { AssetModelItemFormInput } from '../type';
import { removeExtraSpacesAndNewlines } from 'app/utils/removeMultipleSpaces';

function createExternalProductCodesData(
  values: AssetModelItemFormInput,
): ExternalProductCodeInput[] {
  const { noUpcCode, type, upcCode, asinCode, gtinCode } = values;

  return !noUpcCode && (type === ItemTypeEnum.Asset || type === ItemTypeEnum.Inventory || type === ItemTypeEnum.AssetModel)
    ? [
        upcCode && { type: ItemExternalProductCodeTypeEnum.Upc, code: upcCode },
        asinCode && { type: ItemExternalProductCodeTypeEnum.Asin, code: asinCode },
        gtinCode && { type: ItemExternalProductCodeTypeEnum.Gtin, code: gtinCode },
      ].filter(Boolean) as ExternalProductCodeInput[]
    : [];
}

export function createAssetModelInput(values: AssetModelItemFormInput): CreateAssetModelItemInput {
  const {
    attachments,
    brand,
    categoryId,
    costOverride,
    description,
    formattedDescription,
    mName,
    manufacturerId,
    modelNumber,
    pickableThroughOrderRequest,
    title,
    unitCost,
    vendorId,
  } = values;

  return {
    attachments,
    brand,
    categoryId,
    costOverride: costOverride ? Number(costOverride) : 0,
    description: description || '',
    formattedDescription,
    externalProductCodes: createExternalProductCodesData(values),
    mName,
    manufacturerId: manufacturerId || undefined,
    modelNumber,
    pickableThroughOrderRequest,
    title,
    unitCost: unitCost ? Number(unitCost) : 0,
    vendorId: vendorId || undefined,
    type: ItemTypeEnum.AssetModel,
  };
}

export function updateAssetModelInput(values: AssetModelItemFormInput): UpdateAssetModelItemInput {
  const {
    attachments,
    brand,
    categoryId,
    costOverride,
    description,
    formattedDescription,
    mName,
    manufacturerId,
    modelNumber,
    pickableThroughOrderRequest,
    title,
    unitCost,
    vendorId,
    id,
  } = values;

  return {
    attachments,
    brand,
    categoryId,
    costOverride: costOverride ? Number(costOverride) : 0,
    description: description || '',
    formattedDescription: formattedDescription || '',
    externalProductCodes: createExternalProductCodesData(values),
    itemId: id || '',
    mName,
    manufacturerId: manufacturerId || '',
    modelNumber,
    pickableThroughOrderRequest,
    title,
    unitCost: unitCost ? Number(unitCost) : 0,
    vendorId: vendorId || undefined,
  };
}
