import React, { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  DataGridHeadCell,
  TextField,
} from '@procurenetworks/procure-component-library';
import { isAccessible as checkIsAccessible } from 'app/components/AccessControl';
import Link from 'app/components/Link';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { ValueComponentProps } from 'app/types/contact';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  ShippingTransactionSchema,
  ShippingTransactionStatusEnum,
} from 'app/types/schema';
import Box from 'app/ui-components';

import ShipmentStatusView from '../ShipmentStatusView';
import { getShippingStatusOptions } from './utils';

interface ShippingStatusValueNodeProps {
  headCell: DataGridHeadCell;
  row: ShippingTransactionSchema;
  state: {
    extraProps: {
      onOpenDeliveryDetails: Function;
      onStatusChange: Function;
    };
    headers: DataGridHeadCell[];
    page: number;
    rows: ShippingTransactionSchema[];
    rowsPerPage: number;
    total: number;
  };
}

const ShippingStatusValueNode: React.FC<ValueComponentProps> = (props) => {
  const { state, row } = props as ShippingStatusValueNodeProps;
  const { extraProps } = state;
  const { onOpenDeliveryDetails, onStatusChange } = extraProps || {};
  const { status } = row;
  const [isAccessible, setAccessible] = useState<boolean>(false);
  const currentUser = useCurrentUser();
  const options = useMemo(() => {
    return getShippingStatusOptions(status);
  }, [status]);
  const currentStatus = options?.find((status) => status?.value === row?.status);

  const [selectedValue, setSelectedValue] = useState(currentStatus);

  useEffect(() => {
    if (currentUser) {
      if (
        checkIsAccessible(
          currentUser.workspacePermissions,
          AllowedPermissionActionsEnum.Edit,
          AllowedPermissionsSubjectEnum.Shipment,
        )
      ) {
        setAccessible(true);
      }
    }
  }, [currentUser]);

  /*** If Status is "delivered", we are showing a delivery details by opening pop up on Click */
  if (status === ShippingTransactionStatusEnum.Delivered) {
    return (
      <Link
        className="sku-link truncate"
        title={props.row.status}
        onClick={() => {
          onOpenDeliveryDetails(props.row);
        }}>
        <ShipmentStatusView status={props.row.status} />
      </Link>
    );
  }

  /*** If Status is "packed" or an "out for delivery" we will show drop-down to change a status */
  if (isAccessible) {
    if (
      [ShippingTransactionStatusEnum.OutForDelivery, ShippingTransactionStatusEnum.Packed].includes(
        status,
      )
    ) {
      return (
        <Box className="w-full h-full flex items-center justify-center">
          <Autocomplete
            disableClearable
            getOptionDisabled={(option: any) => option.disabled}
            label=""
            options={options}
            sx={{ width: '100%' }}
            renderInput={({ inputProps, ...rest }: any) => (
              <TextField
                {...rest}
                inputProps={{ ...inputProps, value: selectedValue?.label, readOnly: true }}
                sx={{...rest.sx, display: 'flex'}}
              />
            )}
            value={selectedValue}
            onChange={(event: React.SyntheticEvent, option: any) => {
              event.stopPropagation();
              onStatusChange(row, option?.value);
              setSelectedValue(option);
            }}
            size="small"
          />
        </Box>
      );
    }
  }

  return <ShipmentStatusView status={props.row.status} />;
};

export default ShippingStatusValueNode;
