import { useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormTextInput from 'app/components/Form/FormTextInput';
import FormCheckBoxInput from 'app/components/ProcureForm/FormCheckBoxInput';
import Assets from 'app/i18n/Assets';
import { ButtonSize } from 'app/types/button';
import { Maybe } from 'app/types/schema';

import { upcCodeOrAsinCodeValidator } from '../../../../../assetsInventory/utils/rules';
import { AssetFieldProps } from '../../types';
import FormLabel from 'app/components/Form/FormLabel';
import { textValidator } from 'app/components/Form/utils/validators';

const AssetUpcCodeField = (props: AssetFieldProps) => {
  const { formState, disabled } = props;
  const { control, noUpcCode, onFindItemClick, isItemTypeAsset, onNoUpcCodeChange, fetchedProductCodes, upcCode, asinCode } = formState;

  const { upc, asin, fetched } = fetchedProductCodes;

  const upcCodeValidator = useCallback(
    (value: Maybe<string>, fieldName: string) => {
      if (!noUpcCode) {
        if (!fetched) {
          return true;
        }
        if((!asinCode && !upcCode) || ((fieldName === 'upcCode' ? asinCode : upcCode) && !value)) return true;
        return upcCodeOrAsinCodeValidator(value);
      }
      return true;
    },
    [noUpcCode, fetched, upcCode, asinCode]
  );

  const isDisabled = useMemo(() => {
    return !fetched || (fetched && (asin || upc));
  },[fetched, asinCode, upcCode]);

  if (!isItemTypeAsset) {
    return null;
  }

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="productCodeInput"
        render={({ field, fieldState }) => (
          <FormTextInput
            {...field}
            endEndorsement={
              <Button
                disabled={noUpcCode || disabled || !field.value}
                size={ButtonSize.medium}
                theme="success"
                onClick={onFindItemClick}
                classes="min-w-[99px]">
                {Assets.FormLabels.FindUpc}
              </Button>
            }
            error={fieldState.error}
            isDisabled={noUpcCode || disabled}
            isRequired={!noUpcCode}
            label={Assets.FormLabels.UpcCode}
            testId="upcCode-input"
          />
        )}
        rules={{
          validate: (value) => (noUpcCode || upcCode || asinCode ? true : textValidator(Assets.FormValidationMessages.FindItemInvalid)(value)),
        }}
      />
      <div className="mb-20 mt-[16px]">
        <Controller
          control={control}
          name="noUpcCode"
          render={({ field, fieldState }) => (
            <FormCheckBoxInput
              {...field}
              classNames="!p-0 mr-[8px]"
              disabled={disabled}
              label={Assets.FormLabels.NoUpcCode}
              onChange={(value) => {
                field?.onChange(value);
                onNoUpcCodeChange(value);
              }}
            />
          )}
        />
      </div>
      {
        !noUpcCode ? <>
          <div className="mb-20 mt-[16px] flex items-center space-x-4">
            <Controller
              control={control}
              name="upcCode"
              render={({ field, fieldState }) => (
                <FormTextInput
                  {...field}
                  startGrid={
                    <FormLabel className="w-[100px]">
                      {Assets.FormLabels.UpcCodeLabel}
                    </FormLabel>
                  }
                  isDisabled={isDisabled}
                  className="max-w-[300px]"
                  inputControlClassName="h-[40px]"
                  error={fieldState.error}
                />
              )}
              rules={{
                validate: (value) => (upcCodeValidator(value, 'upcCode')),
              }}
            />
          </div>

          <div className="mb-20 mt-[16px] flex items-center space-x-4">
            <Controller
              control={control}
              name="asinCode"
              render={({ field, fieldState }) => (
                <FormTextInput
                  {...field}
                  startGrid={
                    <FormLabel className="w-[100px]">
                      {Assets.FormLabels.AsinCodeLabel}
                    </FormLabel>
                  }
                  isDisabled={isDisabled}
                  className="max-w-[300px]"
                  inputControlClassName="h-[40px]"
                  error={fieldState.error}
                />
              )}
              rules={{
                validate: (value) => (upcCodeValidator(value, 'asinCode')),
              }}
            />
          </div>
        </> : null
      }
    </div>
  );
};

export default AssetUpcCodeField;
