import { useCallback, useState } from 'react';

import useEntityManager from '../../../../../components/EntityManager/useEntityManager';
import { ItemInStock } from '../../../../../orders/views/OrderRequest/type';

interface State {
  categoryId: string;
  open: boolean;
  selectedRowId: string | null;
  sku: string;
  meta: any;
}

const useItemInStockWithSearchDialogState = () => {
  const { search, table } = useEntityManager({
    selection: true,
  });

  const [state, _setState] = useState<State>({
    categoryId: '',
    open: false,
    selectedRowId: null,
    sku: '',
    meta: null,
  });

  const setState = useCallback(
    (nextState: Partial<State>) => {
      _setState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setState],
  );

  const onShowItemInStockSearchDialog = useCallback(
    (
      itemInStock?: {
        sku?: string;
        id?: string;
      } | null,
      searchValue?: string,
      meta?: any,
    ) => {
      setState({
        open: true,
        selectedRowId: itemInStock?.id || null,
        sku: itemInStock?.sku || '',
        meta: meta || null,
      });

      search.onChange(itemInStock?.sku || searchValue || '');
    },
    [setState, search],
  );

  const onHideItemInStockSearchDialog = useCallback(() => {
    setState({
      open: false,
      categoryId: '',
    });
    search.onChange('');
  }, [setState, search]);

  const onCategoryIdChange = useCallback(
    (updatedCategoryId: string) => {
      setState({
        categoryId: updatedCategoryId,
      });
    },
    [setState],
  );

  const clearSelectedRowId = useCallback(() => {
    setState({
      selectedRowId: null,
    });
  }, [setState]);

  return {
    state: {
      open: state.open,
      sku: state.sku,
      search,
      table,
      categoryId: state.categoryId,
      selectedRowId: state.selectedRowId,
      meta: state.meta,
    },
    actions: {
      onShowItemInStockSearchDialog,
      onHideItemInStockSearchDialog,
      onCategoryIdChange,
      clearSelectedRowId,
    },
  };
};

export type UseItemInStockWithSearchDialogStateReturnType = ReturnType<
  typeof useItemInStockWithSearchDialogState
>;

export default useItemInStockWithSearchDialogState;
