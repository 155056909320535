import { FieldError } from 'react-hook-form';

type Props = { error?: Partial<FieldError>, className?: string };

function FormError(props: Props) {
  const { error, className, ...rest } = props;

  if (!error) {
    return null;
  }

  return (
    <p className={`mt-4 text-[14px] text-red-800 ${className ? className : ''}`} {...rest}>
      {error.message || 'Required'}
    </p>
  );
}

export default FormError;
