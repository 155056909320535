import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeletedInventoriesTableQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.InventoryItemFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  page?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type DeletedInventoriesTableQuery = { __typename?: 'Query', inventoryItems: { __typename?: 'InventoryItemConnection', totalCount: number, edges: Array<{ __typename?: 'InventoryItemEdge', node: { __typename?: 'InventoryItemSchema', id: string, sku: string, title: string, description?: string | null, model?: string | null, deletedAt?: string | null, attachments?: Array<{ __typename?: 'MediaSchema', url?: string | null }> | null, category?: { __typename?: 'CategorySchema', id: string, name: string } | null } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const DeletedInventoriesTableDocument = gql`
    query deletedInventoriesTable($filters: InventoryItemFilters, $sorts: [SortProps!], $limit: Float, $page: Float) {
  inventoryItems(filters: $filters, sorts: $sorts, limit: $limit, page: $page) {
    edges {
      node {
        id
        sku
        attachments {
          url
        }
        title
        description
        model
        category {
          id
          name
        }
        deletedAt
      }
    }
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    totalCount
  }
}
    `;

export function useDeletedInventoriesTableQuery(options?: Omit<Urql.UseQueryArgs<DeletedInventoriesTableQueryVariables>, 'query'>) {
  return Urql.useQuery<DeletedInventoriesTableQuery>({ query: DeletedInventoriesTableDocument, ...options });
};