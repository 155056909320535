import { createTheme } from '@mui/material';
import { CustomIcons } from '@procurenetworks/procure-component-library';

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiChip-root': {
            backgroundColor: 'var(--grey300)',
            fontWeight: 500,
            fontSize: 12,
          },

          '& .MuiFormHelperText-root': {
            marginLeft: '0 !important',
          },
        },
        input: {
          fontSize: '16px !important',
          minHeight: 24,
          maxHeight: '48px !important',
          paddingTop: '12px',
          paddingBottom: '12px',
          paddingLeft: '12px !important',
          '&.resize-none': {
            paddingLeft: '5px',
          },
          '&.MuiInput-inputSizeSmall, &.MuiInputBase-inputSizeSmall': {
            fontSize: '12px !important',
            minHeight: 20,
            maxHeight: '34px !important',
            paddingTop: '6px',
            paddingBottom: '6px',
            // maxWidth: '80px !important',
          },
          '&.Mui-disabled': {
            background: 'transparent',
          },
        },

        option: {
          fontFamily: '"Inter", sans-serif',
          color: `var(--grey900)`,
          '&[aria-selected="true"], &.Mui-focused': {
            backgroundColor: `var(--blue300) !important`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: '1px solid var(--grey500)',
          borderRadius: '8px !important',
          backgroundColor: 'var(--white)!important',
          paddingTop: '0 !important',
          paddingBottom: '0 !important',
          '& #demo-simple-select': {
            width: '137px',
            fontSize: '16px !important',
            color: 'var(--grey900)',
            height: '38px',
            padding: '0px 20px !important',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          // '&:not(.MuiAutocomplete-inputRoot)': {
          //   padding: '0 !important',
          // },

          '&.Mui-focused': {
            border: '1px solid var(--blue900)',
          },
          '&.Mui-disabled': {
            backgroundColor: 'var(--grey200)!important',
          },
        },
        notchedOutline: {
          border: 'none',

          '& legend': {
            display: 'none',
          },
          '&.Mui-disabled': {
            '& fieldset': {
              backgroundColor: 'var(--grey200)!important',
            },
          },
        },
        input: {
          paddingTop: `12px !important`,
          paddingBottom: `12px !important`,
          paddingLeft: `12px !important`,
          minHeight: '24px',
          fontSize: '16px !important',

          '&..MuiInput-inputSizeSmall': {
            fontSize: '14px !important',
            paddingTop: `12px !important`,
            paddingBottom: `12px !important`,
            paddingLeft: `14px !important`,
            minHeight: '34px',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'var(--grey800)',

          '&.Mui-focused': {
            color: 'var(--grey800)',
          },
        },
        outlined: {
          display: 'none',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          border: '1px solid var(--grey500)',
          borderRadius: '8px',
          paddingTop: `12px`,
          paddingLeft: `14px`,
          paddingBottom: `12px`,
          minHeight: '24px !important',
          fontSize: '16px !important',

          '&.Mui-focused': {
            border: '1px solid var(--blue900)',
          },
          '&:before': {
            border: 'none !important',
          },
          '&:after': {
            border: 'none !important',
          },
          '&.Mui-disabled': {
            backgroundColor: 'var(--grey200) !important',
          },

          '& .MuiInputBase-inputSizeSmall': {
            paddingBottom: '0px !important',
          },
          '&.MuiInput-inputSizeSmall': {
            fontSize: '14px !important',
            paddingTop: `12px`,
            paddingLeft: `14px`,
            paddingBottom: `12px`,
            minHeight: '34px',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormHelperText-root.Mui-error': {
            fontSize: '14px',
            color: 'var(--red800) !important',
          },
        },
      },
    },
    // @ts-ignore
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: '0px !important',
          '& .MuiDataGrid-columnHeaderTitleContainerContent': {
            height: '44px !important',
            // paddingRight: '10px',
          },
          '& .MuiDataGrid-iconButtonContainer': {
            '& .MuiButtonBase-root': {
              padding: '0px !important',
            },
          },
        },
        paper: {
          borderRadius: '8px !important',
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10); !important',
        },
        panelWrapper: {
          background: 'var(--white) !important',
          borderRadius: '8px !important',
        },
        panelHeader: {
          padding: '8px 16px !important',
          '& .MuiFormControl-root': {
            '& .MuiInput-root': {
              height: '50px',
            },
            '& .MuiInputLabel-standard': {
              marginLeft: '14px',
              marginTop: '10px',
              fontSize: '16px',
              color: 'var(-grey800)',
              fontWeight: 500,
              fontFamily: 'Inter',
            },
            '& .MuiInputLabel-shrink': {
              marginTop: '-2px',
              marginLeft: '0px',
            },
          },
        },
        panelFooter: {
          border: '1px solid var(--grey300)',
          padding: '10px',
          '& button': {
            background: 'var(--blue800) !important',
            borderRadius: '8px',
            height: '44px',
            width: 'fit-content',
            fontSize: '15px',
            color: 'var(--white)',
            fontWeight: 500,
          },
        },
        panelContent: {
          '&::-webkit-scrollbar': {
            display: 'none !important',
          },
          scrollbarWidth: 'none !important',
          msOverflowStyle: 'none !important',
          '@media (max-width: 1366px)': {
            maxHeight: '225px !important',
          },
          '@media (max-width: 768px)': {
            maxHeight: '175px !important',
          },
        },
        columnsPanel: {
          padding: '8px 16px !important',
        },
        columnsPanelRow: {
          height: '35px !important',
          marginBottom: '5px !important',

          '& .MuiFormControlLabel-root': {
            display: 'flex',
            gap: '5px',
            '& .MuiSwitch-root': {
              width: '44px',
              height: '24px',
              padding: '0',
              display: 'flex',
              '& .MuiSwitch-thumb': {
                width: '20px',
                height: '20px',
                marginTop: '-2px',
              },
              '& .MuiSwitch-track': {
                borderRadius: '12px',
              },
              '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(17.5px) !important',
                color: '#fff !important',
              },
            },
          },
          '&.MuiTypography-root': {
            marginLeft: '15px !important',
            color: 'var(--grey900)',
            fontWeight: 600,
          },
        },
        detailPanelToggleCell: {
          outline: 'none !important',
          border: '1px solid var(--grey400)',
          borderRadius: '6px',
          padding: '2px !important',
          marginLeft: '15px !important',
        },
        columnHeaders: {
          outline: 'none !important',
          height: '44px !important',
          minHeight: '44px !important',
          backgroundColor: 'var(--grey100)',
          color: 'var(--grey900)',
        },
        columnHeader: {
          outline: 'none !important',
          color: 'var(--grey900)',
          fontWeight: '500',
          height: '44px !important',
          minHeight: '44px !important',
          '& .MuiAutocomplete-root': {
            minWidth: '150px',

            '& input': {
              paddingLeft: '0 !important',
              background: 'transparent',
            },
          },
        },

        columnHeaderTitle: {
          outline: 'none !important',
          height: '44px !important',
          minHeight: '44px !important',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        cell: {
          outline: 'none !important',
          color: 'var(--grey900)',
        },
        cellCheckbox: {
          justifyContent: 'center',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
            fontWeight: '500',
            color: 'var(--grey900)',
          },
          '& .MuiTablePagination-spacer': {
            display: 'none !important',
          },
          '@media screen and (max-width: 768px)': {
            '&.MuiToolbar-gutters': {
              paddingLeft: '0px !important',
            },
          },
          '& .MuiTablePagination-select': {
            paddingRight: '5px !important',
          },
          '& .MuiIconButton-root': {
            height: '36px',
            width: '36px',
            borderRadius: '8px',
            border: `1px solid var(--grey900)`,
          },
          '& .MuiTablePagination-displayedRows': {
            paddingLeft: '24px',
            margin: '0px',
            height: '36px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderLeft: `1px solid var(--grey300)`,
          },
          '& .MuiIconButton-root.Mui-disabled': {
            background: 'var(--grey300)',
          },
          '& .MuiInputBase-root.MuiInputBase-colorPrimary': {
            borderRadius: '8px',
            border: `1px solid var(--grey500)`,
            marginLeft: '8px',
            padding: '6px 10px',
            height: '32px',
          },
          '&.MuiTablePagination-root': {
            marginLeft: 'auto !important',
          },
          '& .MuiTablePagination-root.MuiTablePagination-caption.MuiTablePagination-input': {
            '& .MuiTablePagination-spacer': {
              display: 'initial !important',
            },
          },
        },
        toolbar: {
          paddingLeft: '0 !important',
          '@media screen and (max-width: 768px)': {
            paddingLeft: '0 !important',
          },
        },
        select: {
          borderRadius: '8px',
          padding: '0px !important',
        },
        actions: {
          '& > button': {
            border: '1px solid var(--grey500)',
            borderRadius: '8px',
            marginLeft: 8,
            padding: 6,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: <CustomIcons.CheckIcon />,
        icon: <CustomIcons.DefaultCheckIcon />,
        indeterminateIcon: <CustomIcons.IndeterminateIcon />,
      },
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            fontFamily: 'Inter !important',
            fontSize: '16px !important',
          },
          '& .MuiSvgIcon-root': {
            fill: 'transparent',
            fontSize: '24px',
          },
          '&.Mui-checked .MuiSvgIcon-root': {
            fill: 'var(--green900)',
            fontSize: '24px',
          },
          '&.Mui-disabled': {
            opacity: '0.50 !important',
            cursor: 'none',
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        checkedIcon: <CustomIcons.RadioCheck />,
        icon: <CustomIcons.RadioDefault />,
      },
      styleOverrides: {
        root: {
          '& + .MuiFormControlLabel-label': {
            color: 'var(--grey800)',
            fontWeight: '500',
            fontSize: '16px !important',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&:has(.MuiCalendarPicker-root)': {
            borderRadius: '8px',
            border: '1px solid var(--grey300)',
            boxShadow:
              '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08) !important',
          },
          '&.MuiTableContainer-root': {
            borderRadius: '12px !important',
            border: '1px solid var(--grey300)',
          },
          '&#location-table-container': {
            boxShadow: 'none !important',
            border: '0px',
            borderRadius: '12px 12px 0 0 !important',
          },
          '& .MuiPaper-root': {
            boxShadow:
              '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            color: 'var(--grey900)',
            fontWeight: '500',
          },
          '& .MuiPickersDay-today': {
            backgroundColor: 'var(--grey200)',
          },
          '& .Mui-selected': {
            backgroundColor: 'var(--blue800)',
            color: 'var(--white)',
            fontWeight: '500',
          },
          '& .Mui-disabled': {
            color: 'var(--grey200)',
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableHead-root': {
            backgroundColor: 'var(--grey100) !important',
          },
          '& .MuiTableBody-root': {
            '& .MuiTableRow-root': {
              borderBottom: '0.5px solid var(--grey300) !important',
            },
          },

          tableRow: {
            borderBottom: '1px solid var(--grey300) !important',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          '& .MuiMenuItem-root': {
            color: 'var(--grey900)  !important',
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: 'var(--blue300)  !important',
            color: 'var(--grey900)  !important',
          },
          '& .Mui-selected': {
            backgroundColor: 'var(--blue300)  !important',
            color: 'var(--grey900)  !important',
          },
        },
      },
    },
  },
});

export default theme;
