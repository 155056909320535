import { useCallback, useMemo } from 'react';
import { Button } from '@procurenetworks/procure-component-library';

import { SnackbarService } from '../../../../components/Snackbar';
import Orders from '../../../../i18n/Orders';
import { OrderRequestItemSchema, OrderRequestItemStatusEnum, AddRowOrderDetailsSchema } from '../../../../types/schema';
import { useCancelOrderRequestItemReturnMutation } from '../../graphql/mutations/generated/cancelOrderRequestItemReturn';
import useOrderReturnPermissionCheck from '../../hook/useOrderReturnPermissionCheck';
import { OrderItemsExtraPropType } from '../OrderItems/type';

interface Props {
  orderRequestItem: OrderRequestItemSchema & AddRowOrderDetailsSchema;
  state: OrderItemsExtraPropType['state'];
  actions: OrderItemsExtraPropType['actions'];
}

const OrderRequestItemActionView = (props: Props) => {
  const { orderRequestItem, state, actions } = props;
  const { orderDetail } = state;
  const { status, parentOrderRequestItemId } = orderRequestItem;

  const { isReturnButtonDisabled } = useOrderReturnPermissionCheck({
    orderDetail,
  });

  const isReturnPacked = useMemo(
    () => status === OrderRequestItemStatusEnum.ReturnPacked,
    [status],
  );
  const isReturnOutForDelivery = useMemo(
    () => status === OrderRequestItemStatusEnum.ReturnOutForDelivery,
    [status],
  );

  const [{ fetching: fetchingCancelOrderRequestItemReturn }, onCancelOrderRequestItemReturn] =
    useCancelOrderRequestItemReturnMutation();

  const onPrintReturnLabel = useCallback(async () => {
    localStorage.setItem(
      'printReturnedOrderDetails',
      JSON.stringify({
        orderRequestId: orderDetail.id,
        orderRequestItemId: orderRequestItem.id,
      }),
    );
    window.open('/orders/print-returned-order', '_blank');
  }, [orderDetail.id, orderRequestItem.id]);

  const onCancelReturn = useCallback(async () => {
    const trackingIds =
      orderRequestItem.trackingDetails?.map((trackingDetail) => trackingDetail.trackingId) || [];

    if (trackingIds.length) {
      const response = await onCancelOrderRequestItemReturn({
        input: {
          trackingIds: trackingIds,
        },
      });

      if (response?.data?.unpackShippingTransactionsOfTrackingIds?.success) {
        SnackbarService.show({
          message: Orders.SuccessMessages.OrderReturnCancelled,
        });
      }
    }
  }, [orderRequestItem.trackingDetails, onCancelOrderRequestItemReturn]);

  return (
    <div style={{ minWidth: '400px', width: 'max-content' }} className="flex gap-[16px] h-full items-center">
      {isReturnPacked ? (
        <div className="flex">
          <Button
            onClick={onCancelReturn}
            loading={fetchingCancelOrderRequestItemReturn}
            disabled={isReturnButtonDisabled}>
            {Orders.Actions.CancelReturn}
          </Button>
        </div>
      ) : null}

      {isReturnPacked || isReturnOutForDelivery ? (
        <div className="flex">
          <Button
            theme="info"
            onClick={onPrintReturnLabel}
            disabled={fetchingCancelOrderRequestItemReturn}>
            {Orders.Actions.PrintReturnLabel}
          </Button>
        </div>
      ) : null}

      {orderRequestItem.isNewItem && <div>
        <Button
          theme="danger"
          onClick={() => actions.handleRemoveRow(orderRequestItem.id)}
        >
          {Orders.Actions.Remove}
        </Button>
      </div>
      }
    </div>
  );
};

export default OrderRequestItemActionView;
