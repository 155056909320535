import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import useFormFileUploader from '../../../../../../components/ProcureForm/FormFileUploader/hook/useFormFileUploader';
import { CreateMediaInput } from '../../../../../../types/schema';
import { parseProduceDataToAssetFormValues } from '../../../../../assets/hook/useAssetForm/utils';
import usePersistAttachmentsHelper from '../../../../../components/FindUPC/components/ProductDetailFormModal/hook/usePersistUpcProductData';
import { FindUpcContextType } from '../../../../../components/FindUPC/context/FindUPCProvider';
import useFindUPC from '../../../../../components/FindUPC/context/useFindUPC';
import { AssetModelItemFormInput } from '../../type';

interface Props {
  defaultValues: AssetModelItemFormInput;
}

const useAssetModelForm = (props: Props) => {
  const { defaultValues } = props;
  const [fetchedProductCodes, setFetchedProductCodes] = useState({
    upc: false,
    asin: false,
    gtin: false,
    fetched: false,
  });

  const { control, handleSubmit, watch, setValue, resetField, reset, getValues } = useForm({
    defaultValues: {
      ...defaultValues,
      description: defaultValues.formattedDescription || defaultValues.description,
      findUpc: false,
    },
  });

  const id = watch('id');
  const productCodeInput = watch('productCodeInput');
  const upcCode = watch('upcCode');
  const asinCode = watch('asinCode');
  const noUpcCode = watch('noUpcCode');
  const showFindUpcSection = watch('findUpc');
  const attachments = watch('attachments');

  const defaultAttachments = useMemo(() => {
    return (
      defaultValues?.attachments?.map((attachment: CreateMediaInput, index: number) => {
        return {
          id: `attachment-${index}`,
          ...attachment,
          resolutions: attachment?.resolutions || [],
        };
      }) || []
    );
  }, [defaultValues?.attachments]);

  const {
    inputProps: attachmentInputProps,
    setAttachments: setAssetAttachment,
    addFileFromUrl: addAssetAttachmentFromUrl,
  } = useFormFileUploader('attachments', defaultAttachments);

  const { parseNoUpcAssetAttachments, parseProduceDataImagesToAssetAttachments } =
    usePersistAttachmentsHelper(attachmentInputProps);

  const { search: findUpcCodeSearch } = useFindUPC() as FindUpcContextType;

  const onFindItemClick = useCallback(() => {
    setValue('findUpc', true);
    findUpcCodeSearch?.onChange(productCodeInput);
  }, [setValue, productCodeInput, findUpcCodeSearch?.onChange]);

  const onNoUpcCodeChange = useCallback(
    (noUpcCodeValue: boolean) => {
      if (noUpcCodeValue) {
        setValue('findUpc', false);
        parseNoUpcAssetAttachments();
      
        if (!defaultValues.upcCode && !defaultValues.asinCode) {
          resetField('upcCode', { defaultValue: '' });
          resetField('asinCode', { defaultValue: '' });
        }
      }
    },
    [parseNoUpcAssetAttachments],
  );

  const onSaveProductData = useCallback(
    (values: any) => {
      const productImages = values?.images || [];
      parseProduceDataImagesToAssetAttachments(productImages);
      // Upload Product image Url to S3
      productImages.forEach((image: string) => {
        addAssetAttachmentFromUrl(image);
      });
      setFetchedProductCodes({
        upc: !!values.upcCode,
        asin: !!values.asinCode,
        gtin: !!values.gtinCode,
        fetched: true
      });
      if (!values.upcCode && !values.asinCode) {
        setValue("noUpcCode", true);
      }

      reset({
        ...getValues(),
        ...parseProduceDataToAssetFormValues(values),
      });
    },
    [
      reset,
      getValues,
      attachments,
      addAssetAttachmentFromUrl,
      parseProduceDataImagesToAssetAttachments,
      setAssetAttachment,
    ],
  );

  useEffect(() => {
    if(!productCodeInput) {
      setValue('findUpc', false);
    }
  }, [productCodeInput]);

  return {
    formState: {
      control,
      attachmentInputProps,
      noUpcCode,
      onNoUpcCodeChange,
      onFindItemClick,
      handleSubmit,
      id,
      fetchedProductCodes,
      upcCode,
      asinCode,
    },
    showFindUpcSection: !!showFindUpcSection && !noUpcCode && productCodeInput,
    onSaveProductData,
  };
};

export type UseAssetModelFormReturnType = ReturnType<typeof useAssetModelForm>;

export default useAssetModelForm;
