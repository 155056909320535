import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import moment from 'moment/moment';
import FormCheckBoxInput from 'app/components/ProcureForm/FormCheckBoxInput';
import {
  CategoryFilterLabelNode,
  Cost,
  QuantityInputNode,
  SKUItem,
  VendorFilterLabelNode,
} from 'app/modules/assetsInventory/Views/Confirmation/EditTransactions/utils/valueNodes';
import { dropdownHeaderStyle } from 'app/modules/components/EntityManager/EntityManagerTable/styles';

import TableImageView from '../../../../components/TableImageView';
import BrandLabelNode from '../components/BrandLabelNode';
import ModelLabelNode from '../components/ModelLabelNode';
import ModelNumberLabelNode from '../components/ModelNumberLabelNode';
import ReadMore from 'app/modules/components/ReadMore';

export const getAssetsTableColumns = (options?: {
  isSiteIdSelected?: boolean;
  siteId?: string;
  isEditable?: boolean;
}): DataGridHeadCell[] => {
  const { isSiteIdSelected, isEditable } = options || {};

  const hideColumIds = isSiteIdSelected ? [] : ['location', 'minimumQuantity', 'maximumQuantity'];

  return (
    [
      {
        id: 'attachments',
        label: 'Image',
        value: 'attachments',
        valueNode: ({ row, state }) => {
          return (
            <TableImageView
              attachments={row.attachments}
              onImageClick={state?.extraProps?.showImageViewDialog}
            />
          );
        },
        width: 100,
        sortable: false,
      },
      {
        id: 'sku',
        label: 'SKU',
        value: 'sku',
        valueNode: SKUItem,
        sortable: true,
        width: 130,
      },
      {
        id: 'title',
        label: 'Title',
        value: 'title',
        sortable: true,
      },
      {
        id: 'mName',
        label: 'Model',
        classes: dropdownHeaderStyle,
        labelNode: ModelLabelNode,
        value: 'mName',
        sortable: true,
        width: 250,
      },
      {
        id: 'modelNumber',
        label: 'Model Number',
        classes: dropdownHeaderStyle,
        labelNode: ModelNumberLabelNode,
        value: 'modelNumber',
        sortable: true,
        width: 250,
      },
      {
        id: 'serialNumber',
        label: 'Serial Number',
        value: 'serialNumber',
        sortable: true,
        width: 180,
      },
      {
        id: 'categoryId',
        label: 'Category',
        classes: dropdownHeaderStyle,
        labelNode: CategoryFilterLabelNode,
        value: 'categoryId',
        valueGetter: (params: any) => {
          const { category } = params?.row || {};
          return category?.name;
        },
        width: 220,
        sortable: false,
      },
      {
        id: 'vendorId',
        label: 'Vendor',
        classes: dropdownHeaderStyle,
        labelNode: VendorFilterLabelNode,
        value: 'vendorId',
        valueGetter: (params: any) => {
          const { vendor } = params?.row || {};
          return vendor?.companyName;
        },
        sortable: false,
        width: 220,
      },
      {
        id: 'brand',
        label: 'Brand',
        classes: dropdownHeaderStyle,
        labelNode: BrandLabelNode,
        value: 'brand',
        sortable: false,
        width: 220,
      },
      {
        id: 'manufacturerId',
        label: 'Manufacturer',
        value: 'manufacturerId',
        sortable: false,
        width: 150,
        valueNode: ({ row }) => (
          <span className="truncate" title={row?.manufacturer?.name || ''}>
            {row?.manufacturer?.name || ''}
          </span>
        ),
      },
      {
        id: 'description',
        label: 'Description',
        value: 'description',
        sortable: false,
        width: 150,
        valueNode: ({ row }) => <ReadMore text={row.description} />,
      },
      {
        id: 'cost',
        label: 'Cost',
        value: 'cost',
        sortable: false,
        rowAlign: 'right',
        width: 100,
        valueNode: Cost,
      },
      {
        id: 'totalQuantity',
        label: 'Quantity',
        value: 'totalQuantity',
        sortable: false,
        rowAlign: 'right',
        width: 120,
      },
      {
        id: 'minimumQuantity',
        label: 'Min',
        value: 'minimumQuantity',
        sortable: false,
        rowAlign: 'right',
        valueNode: QuantityInputNode,
        width: 100,
      },
      {
        id: 'maximumQuantity',
        label: 'Max',
        value: 'maximumQuantity',
        sortable: false,
        rowAlign: 'right',
        valueNode: QuantityInputNode,
        width: 100,
      },
      {
        id: 'purchaseDate',
        label: 'Purchase Date',
        value: 'purchaseDate',
        sortable: false,
        valueNode: ({ row }) => {
          return (
            <span
              className="truncate"
              title={row.purchaseDate ? moment(row.purchaseDate).format('L') : ''}>
              {row.purchaseDate ? moment(row.purchaseDate).format('L') : ''}
            </span>
          );
        },
        width: 200,
      },
      {
        id: 'replacementDate',
        label: 'Replacement Date',
        value: 'replacementDate',
        sortable: false,
        valueNode: ({ row }) => {
          return (
            <span
              className="truncate"
              title={row.replacementDate ? moment(row.replacementDate).format('L') : ''}>
              {row.replacementDate ? moment(row.replacementDate).format('L') : ''}
            </span>
          );
        },
        width: 180,
      },
      {
        id: 'warrantyExpiryDate',
        label: 'Warranty',
        value: 'warrantyExpiryDate',
        sortable: false,
        valueNode: ({ row }) => {
          const warrantyDate = row.warrantyExpiryDate
            ? moment(row.warrantyExpiryDate).format('L')
            : '';
          return (
            <span className="truncate" title={warrantyDate}>
              {warrantyDate}
            </span>
          );
        },
        width: 150,
      },
      {
        id: 'pickableThroughOrderRequest',
        label: 'Requestable?',
        value: 'pickableThroughOrderRequest',
        rowAlign: 'center',
        valueNode: ({ row, state }) => {
          const { extraProps } = state;
          return (
            <FormCheckBoxInput
              medium
              classNames={`${row.editable ? 'cursor-pointer' : 'cursor-default'}`}
              disabled={!row.editable || !isEditable}
              value={
                row.editable && isEditable
                  ? row.editableFields?.pickableThroughOrderRequest
                  : row.pickableThroughOrderRequest
              }
              onChange={(value: any) => {
                if (extraProps?.setEditableSiteState) {
                  extraProps.setEditableSiteState(row.id, {
                    pickableThroughOrderRequest: value,
                  });
                }
              }}
            />
          );
        },
        width: 150,
        sortable: false,
        type: 'boolean',
      },
    ] as DataGridHeadCell[]
  )
    .map((item) => {
      return {
        ...item,
      } as DataGridHeadCell;
    })
    .filter((item) => {
      return !hideColumIds.includes(item.id);
    });
};
