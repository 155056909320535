import { forwardRef, HTMLInputTypeAttribute, useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import { Grid, Input } from '@mui/material';
import { Maybe } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import FormError from '../FormError';
import FormLabel from '../FormLabel';

export interface FormTextInputProps {
  className?: string;
  inputClassName?: string;
  containerClassName?: string;
  inputControlClassName?: string;
  errorClassName?: string;
  label?: string;
  id?: string;
  testId?: string;
  isRequired?: boolean;
  name?: string;
  value?: Maybe<string | number>;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  onKeyDown?: (e: any) => void;
  onKeyUp?: (e: any) => void;
  type?: HTMLInputTypeAttribute;
  error?: Partial<FieldError>;
  placeholder?: string;
  isDisabled?: boolean;
  autoFocus?: boolean;
  showCharacterLimit?: boolean;
  characterLimit?: number;
  spacing?: string | number;
  endEndorsement?: React.ReactElement;
  textAlign?: 'left' | 'right';
  size?: string | number;
  startAdornment?: React.ReactElement;
  startGrid?: React.ReactElement;
}

const FormTextInput = forwardRef<HTMLDivElement, FormTextInputProps>((props, ref) => {
  const {
    className,
    inputClassName,
    containerClassName,
    inputControlClassName = 'max-h-[50px]',
    errorClassName,
    error,
    label,
    name,
    isRequired,
    value,
    onBlur,
    onChange,
    onKeyDown,
    onKeyUp,
    type = 'text',
    placeholder,
    isDisabled,
    autoFocus,
    characterLimit,
    showCharacterLimit = true,
    endEndorsement,
    testId,
    spacing,
    textAlign,
    startAdornment,
    startGrid,
    id = '',
  } = props;

  const onChangeText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value;
      onChange?.(text);
    },
    [onChange],
  );

  const inputActions = {
    onBlur,
    onChange: onChangeText,
    onKeyDown,
    onKeyUp,
  };

  return (
    <Box ref={ref} className={cx('flex flex-col', className)} data-testId={testId}>
      {label ? <FormLabel isRequired={isRequired}>{label}</FormLabel> : null}
      <Grid
        container
        alignItems="center"
        className={cx('mt-[6px]', containerClassName)}
        gap="16px"
        spacing={spacing || 1}>
        {startGrid ? (
          <Grid item xs="auto" className='!pt-0'>
            {startGrid}
          </Grid>
        ) : null}
        <Grid item xs className="!pt-0">
          <Box className={cx('flex rounded-[8px]', inputClassName)}>
            <Input
              aria-label={name}
              autoFocus={autoFocus || false}
              className={`w-[100%] flex-1 py-[8px] px-12 ${inputControlClassName}`}
              slotProps={{ input: { maxLength: characterLimit } }}
              disabled={isDisabled}
              endAdornment={
                showCharacterLimit && characterLimit ? (
                  <span className="mx-8 self-center text-[12px] font-semibold text-grey-800">
                    {value?.toString().length}/{characterLimit}
                  </span>
                ) : null
              }
              id={id}
              name={name}
              placeholder={placeholder}
              startAdornment={startAdornment}
              style={{
                textAlign: textAlign || 'inherit',
              }}
              type={type}
              value={value ?? ''}
              {...inputActions}
            />
          </Box>
        </Grid>
        {endEndorsement ? (
          <Grid item className="mr-16px !pt-0 !pl-0" xs="auto">
            {endEndorsement}
          </Grid>
        ) : undefined}
      </Grid>
      <FormError error={error} className={errorClassName}/>
    </Box>
  );
});

export default FormTextInput;
