import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import capitalize from 'lodash/capitalize';
import routes from 'app/consts/routes';
import { Link } from 'app/libs/navigation';
import { dropdownHeaderStyle } from 'app/modules/components/EntityManager/EntityManagerTable/styles';
import { getLocationName } from 'app/modules/locations/utils/location';
import { ItemTypeEnum } from 'app/types/schema';
import { formatDate } from 'app/utils/date';

import FilterLabelNode from '../../FilterLabelNode';
import { getTransactionStatusLabel } from './transactionStatus';
import { getTransactionTypeTitle } from './transactionTypeFilter';
import SyncStateWithUrlParam from 'app/utils/queryParams';

// This function prefix `All` label with column type
const getOptions = (options: any[], type: string, labelKey = 'name') => {
  return options.map((ele) => {
    const label = ele[labelKey] === 'All' ? `${type}: ${ele[labelKey]}` : ele[labelKey];

    return { id: ele.id, name: label, filters: ele.filters };
  });
};

export const getTransactionHistoryColumns = (itemType: ItemTypeEnum): DataGridHeadCell[] => [
  {
    id: 'type',
    label: 'Transaction Type',
    value: 'type',
    sortable: false,
    classes: dropdownHeaderStyle,
    labelNode: ({ state }) => {
      const { extraProps } = state;
      const { filterState, setFilterState } = extraProps || {};

      SyncStateWithUrlParam("typeId", filterState?.typeId, setFilterState, '-1');

      return (
        <FilterLabelNode
          filterKey="typeId"
          filterState={state.extraProps?.filterState}
          items={
            state.extraProps?.transactionTypes.map((ele: any) => {
              return { id: ele.id, name: `${ele.label}`, filters: ele.filters };
            }) || []
          }
          label="Type"
          optionPrefix="Type"
          optionLabelKey="name"
          optionValueKey="id"
          setFilterState={state.extraProps?.setFilterState}
          controllerHeight="34px"
          wrapperClass='!mt-0'
        />
      )
    },
    valueNode: ({ row }) => (
      <Link
        className="transaction-link truncate"
        to={
          itemType === ItemTypeEnum.Inventory
            ? routes.ConfirmationInventoryEditTransactions(row.parentTransactionId)
            : routes.ConfirmationAssetEditTransactions(row.parentTransactionId)
        }>
        {getTransactionTypeTitle(row.type, row.subType).toUpperCase()}
      </Link>
    ),
    width: 250,
  },
  {
    id: 'quantity',
    label: 'Quantity',
    value: 'quantity',
    sortable: true,
    width: 140,
    rowAlign: 'right',
  },
  {
    id: 'sourceSite.name',
    label: 'From',
    value: 'sourceSite.name',
    classes: dropdownHeaderStyle,
    sortable: false,
    labelNode: ({ state }) => {
      const { extraProps } = state;
      const { filterState, setFilterState } = extraProps || {};

      SyncStateWithUrlParam("sourceSiteId", filterState?.sourceSiteId, setFilterState, '-1');

      return (
        <FilterLabelNode
          filterKey="sourceSiteId"
          filterState={state.extraProps?.filterState}
          items={state.extraProps?.siteOptions || []}
          label="From Site"
          optionPrefix="From Site"
          optionLabelKey="name"
          optionValueKey="id"
          setFilterState={state.extraProps?.setFilterState}
          controllerHeight="34px"
          wrapperClass='!mt-0'
        />
      )
    },
    valueNode: ({ row }) => <span>{row?.sourceSite?.name || ''}</span>,
    width: 250,
  },
  {
    id: 'sourceLocation.name',
    label: ' From Location',
    value: 'sourceLocation.name',
    sortable: false,
    // labelNode: ({ state }) => (
    //   <FilterLabelNode
    //     filterKey="sourceLocationId"
    //     filterState={state.extraProps?.filterState}
    //     items={state.extraProps?.locationOptions || []}
    //     label="Location"
    //     optionLabelKey="name"
    //     optionValueKey="id"
    //     setFilterState={state.extraProps?.setFilterState}
    //   />
    // ),
    valueNode: ({ row }) => {
      const locationName = getLocationName(row?.sourceSite?.id, row?.sourceLocation);
      return (
        <span className="truncate" title={locationName}>
          {locationName}
        </span>
      );
    },
    width: 250,
  },
  {
    id: 'destinationSite.name',
    label: 'To',
    value: 'destinationSite.name',
    classes: dropdownHeaderStyle,
    sortable: false,
    labelNode: ({ state }) => {
      const { extraProps } = state;
      const { filterState, setFilterState } = extraProps || {};

      SyncStateWithUrlParam("destinationSiteId", filterState?.destinationSiteId, setFilterState, '-1');

      return (
        <FilterLabelNode
          filterKey="destinationSiteId"
          filterState={state.extraProps?.filterState}
          items={state.extraProps?.siteOptions || []}
          label="To Site"
          optionPrefix="To Site"
          optionLabelKey="name"
          optionValueKey="id"
          setFilterState={state.extraProps?.setFilterState}
          controllerHeight="34px"
          wrapperClass='!mt-0'
        />
      )
    },
    valueNode: ({ row }) => (
      <span className="truncate" title={row?.destinationSite?.name || ''}>
        {row?.destinationSite?.name || ''}
      </span>
    ),
    width: 220,
  },
  {
    id: 'destinationLocation.name',
    label: 'To Location',
    value: 'destinationLocation.name',
    sortable: false,
    // labelNode: ({ state }) => (
    //   <FilterLabelNode
    //     filterKey="destinationLocationId"
    //     filterState={state.extraProps?.filterState}
    //     items={state.extraProps?.locationOptions || []}
    //     label="Location"
    //     optionLabelKey="name"
    //     optionValueKey="id"
    //     setFilterState={state.extraProps?.setFilterState}
    //   />
    // ),
    valueNode: ({ row }) => {
      const locationName = getLocationName(row?.destinationSite?.id, row?.destinationLocation);
      return (
        <span className="truncate" title={locationName}>
          {locationName}
        </span>
      );
    },
    width: 220,
  },
  {
    id: 'createdBy.firstName',
    label: 'User',
    value: 'createdBy.firstName',
    classes: dropdownHeaderStyle,
    sortable: false,
    labelNode: ({ state }) => {
      const { extraProps } = state;
      const { filterState, setFilterState } = extraProps || {};

      SyncStateWithUrlParam("userId", filterState?.userId, setFilterState, '-1');

      return (
        <FilterLabelNode
          filterKey="userId"
          filterState={state.extraProps?.filterState}
          items={state.extraProps?.userOptions || []}
          label="User"
          optionPrefix="User"
          optionLabelKey="name"
          optionValueKey="id"
          setFilterState={state.extraProps?.setFilterState}
          controllerHeight="34px"
          wrapperClass='!mt-0'
        />
      )
    },
    valueNode: ({ row }) => (
      <span className="truncate" title={row?.createdBy?.name}>
        {row?.createdBy?.name}
      </span>
    ),
    width: 220,
  },
  {
    id: 'status',
    label: 'Status',
    value: 'status',
    valueNode: ({ row }) => (
      <span className="truncate" title={getTransactionStatusLabel(row?.status)}>
        {getTransactionStatusLabel(row?.status)}
      </span>
    ),
    sortable: true,
    width: 120,
  },
  {
    id: 'createdAt',
    label: 'Date',
    value: 'createdAt',
    sortable: true,
    valueNode: ({ row }) => (
      <span className="truncate" title={formatDate(row.createdAt)}>
        {formatDate(row.createdAt)}
      </span>
    ),
    width: 180,
  },
];
