import ImageViewDialog from '../../../../../components/ImageViewDialog';
import { OrderDetailsData } from '../../type';
import useOrderDetails from '../../hook/useOrderDetails';
import OrderDetailsForm from '../../../../components/OrderDetailsForm';
import { Box, Divider, Grid, InputAdornment } from '@mui/material';
import EntityManager from 'app/modules/components/EntityManager';
import Orders from '../../../../../../i18n/Orders';
import OrderDetailSteps from '../../../../components/OrderDetailSteps';
import OrderActions from '../../../../components/OrderActions';
import OrderItems from '../../../../components/OrderItems';
import { useCallback, useContext, useMemo } from 'react';
import {
  updateChildOrderRequestFromChildTenantInput,
  updateExternalOrderRequestFromParentTenantInput,
  updateOrderRequestInput,
} from '../../../../dto/dto';
import { DeliveryDetailInput } from '../../../../components/OrderDeliveryDetailForm/types';
import { useUpdateOrderRequestMutation } from '../../../../graphql/mutations/generated/updateOrderRequest';
import { SnackbarService } from '../../../../../../components/Snackbar';
import { useReturnOrderRequestItemsMutation } from '../../../../graphql/mutations/generated/returnOrderRequestItems';
import { ReturnOrderRequestItemsInput } from '../../../../../../types/schema';
import { OrderDetailContext } from '../../../../provider/OrderDetailsContext';
import { useUpdateExternalOrderRequestFromChildTenantMutation } from '../../../../graphql/mutations/generated/updateExternalOrderRequestFromChildTenantInput';
import { useUpdateExternalOrderRequestFromParentTenantMutation } from '../../../../graphql/mutations/generated/UpdateExternalOrderRequestFromParentTenantInput';
import { ItemInStock } from '../../../OrderRequest/type';
import { CustomIcons } from '@procurenetworks/procure-component-library';
import analytics from 'app/analytics';

interface Props {
  orderDetail: OrderDetailsData;
  itemInStockItems: ItemInStock[];
}

const OrderDetailView = (props: Props) => {
  const { orderDetail, itemInStockItems } = props;

  const { isParentTenantOrderView, isChildTenantOrderView } = useContext(OrderDetailContext);

  const [{ fetching: updateOrderFetching }, onUpdateOrderRequestMutation] =
    useUpdateOrderRequestMutation();
  const [{ fetching: returnOrderFetching }, onReturnOrderRequestMutation] =
    useReturnOrderRequestItemsMutation();
  const [
    { fetching: updatingExternalOrderRequestFromChildTenantOrder },
    onUpdateExternalOrderRequestFromChildTenantMutation,
  ] = useUpdateExternalOrderRequestFromChildTenantMutation();
  const [
    { fetching: updatingExternalOrderRequestFromParentTenantOrder },
    onUpdateExternalOrderRequestFromParentTenantMutation,
  ] = useUpdateExternalOrderRequestFromParentTenantMutation();

  const fetching = useMemo(() => {
    return (
      updateOrderFetching ||
      returnOrderFetching ||
      updatingExternalOrderRequestFromChildTenantOrder ||
      updatingExternalOrderRequestFromParentTenantOrder
    );
  }, [
    updateOrderFetching,
    returnOrderFetching,
    updatingExternalOrderRequestFromChildTenantOrder,
    updatingExternalOrderRequestFromParentTenantOrder,
  ]);

  const { state, actions } = useOrderDetails({
    orderDetail,
  });
  const { handleSubmit, orderView, orderRequestItems } = state;

  const handlePostUpdateOrder = useCallback(() => {
    analytics?.track('Edited', { name: 'Order' });
    SnackbarService.show({
      message: Orders.SuccessMessages.OrderUpdated,
    });
    actions.onCancel();
  }, [actions.onCancel]);

  const updateInternalOrder = useCallback(
    async (deliveryDetails: DeliveryDetailInput) => {
      const response = await onUpdateOrderRequestMutation({
        input: updateOrderRequestInput(deliveryDetails, orderRequestItems, orderDetail),
      });

      if (response?.data?.updateOrderRequest?.success) {
        handlePostUpdateOrder();
      }
    },
    [orderDetail, orderRequestItems, handlePostUpdateOrder, onUpdateOrderRequestMutation],
  );

  const updateExternalOrderRequestFromParentTenant = useCallback(
    async (deliveryDetails: DeliveryDetailInput) => {
      const response = await onUpdateExternalOrderRequestFromParentTenantMutation({
        input: updateExternalOrderRequestFromParentTenantInput(
          deliveryDetails,
          orderRequestItems,
          orderDetail,
        ),
      });

      if (response?.data?.updateExternalOrderRequestFromParentTenant?.success) {
        handlePostUpdateOrder();
      }
    },
    [
      orderDetail,
      orderRequestItems,
      actions.onCancel,
      onUpdateExternalOrderRequestFromParentTenantMutation,
    ],
  );

  const updateExternalOrderRequestFromChildTenant = useCallback(
    async (deliveryDetails: DeliveryDetailInput) => {
      let isExternalOrderItemValid = true;
      orderRequestItems.forEach((order: any) => {
        if (order?.item && !order?.item.id) {
          SnackbarService.showError({ message: Orders.ErrorMessages.OrderNewItemEmpty });
          isExternalOrderItemValid = false;
        }
      })

      if (isExternalOrderItemValid) {
        const response = await onUpdateExternalOrderRequestFromChildTenantMutation({
          input: updateChildOrderRequestFromChildTenantInput(
            deliveryDetails,
            orderRequestItems,
            orderDetail,
            itemInStockItems,
          ),
        });

        if (response?.data?.updateExternalOrderRequestFromChildTenant?.success) {
          handlePostUpdateOrder();
        }
      }
    },
    [
      orderDetail,
      orderRequestItems,
      onUpdateExternalOrderRequestFromChildTenantMutation,
      handlePostUpdateOrder,
      itemInStockItems,
    ],
  );

  const onUpdateOrder = useCallback(
    async (deliveryDetails: DeliveryDetailInput) => {
      if (fetching) {
        return;
      }

      if (isParentTenantOrderView) {
        await updateExternalOrderRequestFromParentTenant(deliveryDetails);
      } else if (isChildTenantOrderView) {
        await updateExternalOrderRequestFromChildTenant(deliveryDetails);
      } else {
        await updateInternalOrder(deliveryDetails);
      }
    },
    [
      orderRequestItems,
      isParentTenantOrderView,
      isChildTenantOrderView,
      updateInternalOrder,
      updateExternalOrderRequestFromParentTenant,
      updateExternalOrderRequestFromChildTenant,
      fetching,
    ],
  );

  const handleUpdateOrder = useMemo(
    () => handleSubmit(onUpdateOrder),
    [onUpdateOrder, handleSubmit],
  );

  const onReturnOrder = useCallback(
    async (input: ReturnOrderRequestItemsInput) => {
      if (fetching) {
        return;
      }

      const response = await onReturnOrderRequestMutation({
        input: input,
      });

      if (response?.data?.returnOrderRequestItems?.success) {
        SnackbarService.show({
          message: Orders.SuccessMessages.OrderReturned,
        });
        actions.onCancel();
      }
    },
    [orderDetail, orderRequestItems, onReturnOrderRequestMutation, actions.onCancel, fetching],
  );

  return (
    <>
      <Divider />
      <Box className="flex flex-wrap gap-[48px]">
        <Box
          className="
          m-auto 
          w-[300px] 
          pt-6 
          md:m-0 
          md:w-[416px] 
          lg:w-[416px] 
          xl:w-[416px]
          ">
          <OrderDetailsForm state={state} disabled={fetching} />
        </Box>
        <Box className="flex-1 w-full overflow-x-auto min-w-[416px]">
          <EntityManager.Title title={Orders.Items} />
          <OrderDetailSteps orderDetail={orderDetail} />
          <OrderActions state={state} actions={actions} />

          <OrderItems
            state={state}
            actions={actions}
            key={`order-view-${orderView}`}
            onUpdateOrder={handleUpdateOrder}
            onReturnOrder={onReturnOrder}
            disabled={fetching}
            filterNode={
              <EntityManager.Search
                {...state.search}
                placeholder="Search"
                startAdornment={
                  <InputAdornment position="start" className="mt-[4px] ml-[5px]">
                    <CustomIcons.SearchIcon />
                  </InputAdornment>
                }
              />
            }
          />
        </Box>
      </Box>
      <ImageViewDialog
        id="order-item-table-image-view"
        imageUrl={state.imagePreviewState.imageUrl}
        open={state.imagePreviewState.open}
        onClose={actions.imagePreviewActions.onClose}
      />
    </>
  );
};

export default OrderDetailView;
