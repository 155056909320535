import { useCallback, useEffect, useMemo, useState } from 'react';
import { cx } from '@emotion/css';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import { LocationTypeEnum, SortOrderEnum } from 'app/types/schema';
import { adaptNodeEdgeToNode } from '../../../../components/AsyncMultiSelect/utils';
import { useFormSiteSelectQuery } from 'app/modules/locations/components/FormSiteSelect/graphql/queries/generated/formSiteSelect';
import { SiteSelectorOption, SiteSelectorProps } from './types';

const getOptionLabel = (option: any) => option.name;
const getOptionValue = (option: any) => option.id;

const AllSiteOption = {
  name: 'All Sites',
  id: 'none',
};

const SiteSelector = (props: SiteSelectorProps) => {
  const {
    className = 'mt-8',
    disabled,
    placeholder,
    value,
    onBlur,
    onChange,
    enabledAllOption,
    autoFocus,
    types,
    clearable = true,
    locationIds,
    currentSelectedOption,
    size = 'medium',
  } = props;

  const defaultSelectedValue = useMemo(() => {
    return enabledAllOption && value === AllSiteOption.id ? AllSiteOption : null;
  }, [value, enabledAllOption]);

  const [selectedValue, setSelectedValue] = useState<SiteSelectorOption | null>(
    defaultSelectedValue,
  );
  const match = require('autosuggest-highlight/match');
  const parse = require('autosuggest-highlight/parse');

  const [{ fetching: isDisabled, data: selectedData }] = useFormSiteSelectQuery({
    requestPolicy: 'network-only',
    variables: {
      filters: {
        locationIds: value ? [value] : [],
        types: types || [LocationTypeEnum.Site],
      },
    },
    pause: !value || !!selectedValue || value === AllSiteOption.id,
  });

  useEffect(() => {
    if (!value) {
      if (enabledAllOption) {
        setSelectedValue(AllSiteOption);
      } else {
        setSelectedValue(defaultSelectedValue);
      }
    } else if (value === 'none') {
      setSelectedValue(defaultSelectedValue);
    }
  }, [value]);

  useEffect(() => {
    const selectedOption = selectedData?.locations.edges[0]?.node;
    if (selectedOption) {
      setSelectedValue(selectedOption);
      onChange?.(selectedOption.id, selectedOption);
    }
  }, [selectedData?.locations.edges]);

  const [{ fetching: isLoading, data: optionsData }] = useFormSiteSelectQuery({
    variables: {
      filters: {
        search: '',
        types: types || [LocationTypeEnum.Site],
        locationIds: locationIds,
      },
      sorts: [
        {
          sortField: 'name',
          sortOrder: SortOrderEnum.Asc,
        },
      ],
      limit: 10000,
    },
  });

  const options = useMemo<any>(() => {
    const updatedOptions =
      optionsData?.locations.edges?.map(adaptNodeEdgeToNode) || [];

    if (enabledAllOption) {
      return [AllSiteOption, ...updatedOptions];
    }
    return updatedOptions;
  }, [optionsData?.locations.edges]);

  const onValueChange = useCallback(
    (event: React.SyntheticEvent, option: any) => {
      if (option === AllSiteOption) {
        setSelectedValue(option);
        onChange?.('');
      } else if (option) {
        setSelectedValue(option);
        onChange?.(option.id, option);
      } else {
        onChange?.('');
      }
    },
    [onChange, setSelectedValue],
  );

  return (
    <Autocomplete
      className={cx('rounded disabled:bg-grey-500', className)}
      disableClearable={!clearable || false}
      disabled={disabled || isDisabled}
      getOptionLabel={getOptionLabel}
      label={''}
      loading={isLoading}
      openOnFocus={autoFocus}
      options={options}
      size={size}
      renderInput={(params) => <TextField {...params} placeholder={placeholder} sx={{display: "flex"}}/>}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.name, inputValue);
        const parts = parse(option.name, matches);

        return (
          <li {...props} key={option.id}>
            <div>
              {parts.map((part: any, index: any) => (
                <span
                  key={index}
                  style={{
                    fontWeight: 400,
                  }}>
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
      value={currentSelectedOption ? currentSelectedOption : (selectedValue as any)}
      onBlur={onBlur}
      onChange={onValueChange}
    />
  );
};

export default SiteSelector;