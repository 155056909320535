import React, { useMemo } from 'react';
import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import { ValueComponentProps } from '@procurenetworks/procure-component-library/build/components/ui/DataGrid/DataGrid.types';
import FormLabel from 'app/components/Form/FormLabel';

import TableCostView from '../../../components/TableCostView';
import { ORDER_VIEW } from '../../views/OrderDetail/type';
import OrderRequestItemActionView from '../OrderRequestItemActionView';
import OrderRequestItemCostView from '../OrderRequestItemCostView';
import OrderRequestItemDescription from '../OrderRequestItemDescriptionView';
import OrderRequestItemImageView from '../OrderRequestItemImageView';
import OrderRequestItemNotesView from '../OrderRequestItemNotesView';
import OrderRequestItemProjectView from '../OrderRequestItemProjectView';
import OrderRequestItemQuantityView from '../OrderRequestItemQuantityView';
import OrderRequestItemStatus from '../OrderRequestItemStatus';
import OrderRequestItemTitleView from '../OrderRequestItemTitleView';
import { OrderItemsExtraPropType } from './type';
import AddOrderRowButton from '../AddOrderRowButton';
import useOrderEditPermissionCheck from '../../hook/useOrderEditPermissionCheck';

export const FOOTER_TOTAL_ROW_ID = 'FOOTER_ORDER_DETAILS_ROW';

interface ExtendedDataGridHeadCell extends DataGridHeadCell {
  minWidth?: number;
}
const FooterValueNodeWrapper = (
  props: ValueComponentProps & {
    children: React.ReactNode;
    className?: string;
  },
) => {
  const { children, headCell, row, state, className } = props;
  const { extraProps, rows } = state;
  const isFooterRow = useMemo(() => row.id === FOOTER_TOTAL_ROW_ID, [row.id]);

  const { isOrderDetailsEditable } = useOrderEditPermissionCheck({
    orderDetail: extraProps?.state?.orderDetail,
  });

  const { isOrderDetailEditable, isOrderDetailEditableWithPackedStatus } = useMemo(() => {
    const { isOrderDetailEditable, isOrderDetailEditableWithPackedStatus } =
      isOrderDetailsEditable();

    return {
      isOrderDetailEditable,
      isOrderDetailEditableWithPackedStatus,
    };
  }, [isOrderDetailsEditable]);

  if (isFooterRow) {
    switch (headCell.id) {
      case 'quantity':
        return (
          <div className="footer-row">
            <span className="font-bold">Total:</span>
          </div>
        );
      case 'cost':
        return (
          <div className="footer-row">
            <TableCostView classes="font-bold" cost={row.cost} />
          </div>
        );
      case 'image':
        if (state?.extraProps?.state?.orderView == 'EDIT_ORDER_VIEW') {
          return (
            <div className="footer-row">
              <AddOrderRowButton actions={extraProps?.actions} rows={rows} type={extraProps?.state?.orderDetail.type} isDisabled={!isOrderDetailEditable || isOrderDetailEditableWithPackedStatus}  />
            </div>
          );
        }
        return null;
      default:
        return null;
    }
  }

  return (
    <div key={row.id + '-' + headCell.id} className={`w-[100%] ${className || ''}`}>
      {children}
    </div>
  );
};

function getStateAndActions(props: ValueComponentProps) {
  const { state } = props;
  const { extraProps } = state;
  return {
    state: extraProps?.state as OrderItemsExtraPropType['state'],
    actions: extraProps?.actions as OrderItemsExtraPropType['actions'],
  } as OrderItemsExtraPropType;
}

const OrderDetailItemsColumns: ExtendedDataGridHeadCell[] = [
  {
    id: 'image',
    label: 'Image',
    value: 'imageUrl',
    valueNode: (props: ValueComponentProps) => {
      const { row } = props;
      const { actions } = getStateAndActions(props);
      return (
        <FooterValueNodeWrapper className='h-full' {...props}>
          <OrderRequestItemImageView
            orderRequestItem={row}
            onImageClick={actions?.imagePreviewActions?.onShow}
          />
        </FooterValueNodeWrapper>
      );
    },
    width: 100,
  },
  {
    id: 'ItemDescription',
    labelNode: () => (
      <FormLabel isRequired className="MuiDataGrid-columnHeaderTitle">
        SKU
      </FormLabel>
    ),
    value: 'ItemDescription',
    valueNode: (props) => {
      const { row } = props;
      const { state, actions } = getStateAndActions(props);
      return (
        <FooterValueNodeWrapper className='h-full flex items-center' {...props}>
          <OrderRequestItemDescription
            actions={actions}
            orderRequestItem={row}
            state={state}
            valueKey={'sku'}
          />
        </FooterValueNodeWrapper>
      );
    },
    width: 250,
  },
  {
    id: 'title',
    label: 'Title',
    value: 'title',
    valueNode: (props) => {
      const { row } = props;
      return (
        <FooterValueNodeWrapper {...props}>
          <OrderRequestItemTitleView orderRequestItem={row} />
        </FooterValueNodeWrapper>
      );
    },
    width: 150,
  },
  {
    id: 'UPCOrASIN',
    label: 'UPC or ASIN',
    value: 'UPCOrASIN',
    valueNode: (props) => {
      const { row } = props;
      const { state, actions } = getStateAndActions(props);
      return (
        <FooterValueNodeWrapper {...props}>
          <OrderRequestItemDescription
            actions={actions}
            orderRequestItem={row}
            state={state}
            valueKey={'upcCode'}
          />
        </FooterValueNodeWrapper>
      );
    },
    width: 130,
  },
  {
    id: 'website',
    label: 'Website',
    value: 'website',
    valueNode: (props) => {
      const { row } = props;
      const { state, actions } = getStateAndActions(props);
      return (
        <FooterValueNodeWrapper {...props}>
          <OrderRequestItemDescription
            actions={actions}
            orderRequestItem={row}
            state={state}
            valueKey={'website'}
          />
        </FooterValueNodeWrapper>
      );
    },
    width: 100,
  },
  {
    id: 'description',
    label: 'Description',
    value: 'description',
    valueNode: (props) => {
      const { row } = props;
      const { state, actions } = getStateAndActions(props);
      return (
        <FooterValueNodeWrapper {...props}>
          <OrderRequestItemDescription
            actions={actions}
            orderRequestItem={row}
            state={state}
            valueKey={'description'}
          />
        </FooterValueNodeWrapper>
      );
    },
    width: 120,
  },
  {
    id: 'projectName',
    label: 'Project',
    value: 'projectName',
    valueNode: (props) => {
      const { row } = props;
      const { state, actions } = getStateAndActions(props);
      return (
        <FooterValueNodeWrapper {...props}>
          <OrderRequestItemProjectView actions={actions} orderRequestItem={row} state={state} />
        </FooterValueNodeWrapper>
      );
    },
    rowAlign: 'left',
    width: 180,
  },
  {
    id: 'quantity',
    labelNode: () => (
      <FormLabel isRequired className="MuiDataGrid-columnHeaderTitle">
        Quantity
      </FormLabel>
    ),
    value: 'quantity',
    rowAlign: 'left',
    label: 'Quantity',
    valueNode: (props) => {
      const { row } = props;
      const { state, actions } = getStateAndActions(props);
      return (
        <FooterValueNodeWrapper {...props}>
          <OrderRequestItemQuantityView actions={actions} orderRequestItem={row} state={state} />
        </FooterValueNodeWrapper>
      );
    },
    width: 120,
  },
  {
    id: 'cost',
    label: 'Cost',
    value: 'cost',
    rowAlign: 'right',
    valueNode: (props) => {
      const { row } = props;
      return (
        <FooterValueNodeWrapper {...props}>
          <OrderRequestItemCostView orderRequestItem={row} />
        </FooterValueNodeWrapper>
      );
    },
    width: 100,
  },
  {
    id: 'status',
    label: 'Status',
    value: 'status',
    rowAlign: 'left',
    sortable: true,
    valueNode: (props) => {
      const { row } = props;
      const { state, actions } = getStateAndActions(props);
      const { orderDetail, orderView } = state;
      return (
        <FooterValueNodeWrapper {...props}>
          <OrderRequestItemStatus
            disabled={orderView !== ORDER_VIEW.DEFAULT}
            orderDetail={orderDetail}
            orderRequestItem={row}
          />
        </FooterValueNodeWrapper>
      );
    },
    width: 160,
  },
  {
    id: 'notes',
    label: 'Notes',
    value: 'notes',
    valueNode: (props) => {
      const { row } = props;
      const { state, actions } = getStateAndActions(props);
      return (
        <FooterValueNodeWrapper {...props}>
          <OrderRequestItemNotesView actions={actions} orderRequestItem={row} state={state} />
        </FooterValueNodeWrapper>
      );
    },
    rowAlign: 'left',
    minWidth: 80,
    width: 175,
  },
  {
    id: 'action',
    label: 'Action',
    value: 'action',
    valueNode: (props) => {
      const { row } = props;
      const { state, actions } = getStateAndActions(props);
      return <OrderRequestItemActionView orderRequestItem={row} state={state} actions={actions}  />;
    },
    width: 360, 
    minWidth: 400,
  },
];

export const getOrderDetailItemsColumns = (data: {
  isProjectFieldEnabled: boolean;
  isActionFieldEnabled: boolean;
}): DataGridHeadCell[] => {
  const { isActionFieldEnabled, isProjectFieldEnabled } = data;
  return OrderDetailItemsColumns.filter((item) => {
    switch (item.value) {
      case 'projectName':
        return isProjectFieldEnabled;
      case 'action':
        return isActionFieldEnabled;
      default:
        return true;
    }
  });
};
