import _ from 'lodash';
import { OrderRequestItemSchema, OrderRequestItemStatusEnum } from '../../../../../types/schema';
import moment from 'moment';

const isDateOlderThan60Days = (dateString: any) => {
  const inputDate = moment(dateString);
  const currentDate = moment();
  const differenceInDays = currentDate.diff(inputDate, 'days');
  return differenceInDays > 60;
};

export function hasDeleteActionForOrderItems(existingOrderRequestItems: OrderRequestItemSchema[], row: any) {
  /** Check whether all items are in closed status & the updatedAt in the statusHistory of closed is more than 60 days */
  const areAllItemsClosedForDeletion = existingOrderRequestItems.every((item) => {
    if (OrderRequestItemStatusEnum.Merged === item.status) {
      return true;
    }
    if (OrderRequestItemStatusEnum.Closed === item.status) {
      const closedStatusHistory = item.statusHistory.find(
        ({ status }) => status === OrderRequestItemStatusEnum.Closed,
      );
      if (!closedStatusHistory) {
        return false;
      }
      const hasItemGoneThroughDeliveryProcessBeforeGettingClosed = item.statusHistory.some((statusHistory) => {
        return [
          OrderRequestItemStatusEnum.Ordered,
          OrderRequestItemStatusEnum.Packed,
          OrderRequestItemStatusEnum.OutForDelivery,
          OrderRequestItemStatusEnum.ReturnPacked,
          OrderRequestItemStatusEnum.ReturnOutForDelivery,
          OrderRequestItemStatusEnum.Delivered,
          OrderRequestItemStatusEnum.Returned,
        ].includes(statusHistory.status);
      });
      return isDateOlderThan60Days(closedStatusHistory.createdAt) || !hasItemGoneThroughDeliveryProcessBeforeGettingClosed;
    }
    return false;
  });

  if (areAllItemsClosedForDeletion) {
    return true;
  }

  /* Check whether any item is already in process or has been processed. */
  const isAnyItemPicked = existingOrderRequestItems.some((item) =>
    [
      OrderRequestItemStatusEnum.Ordered,
      OrderRequestItemStatusEnum.Packed,
      OrderRequestItemStatusEnum.OutForDelivery,
      OrderRequestItemStatusEnum.ReturnPacked,
      OrderRequestItemStatusEnum.ReturnOutForDelivery,
    ].includes(item.status),
  );
  if (isAnyItemPicked) {
    return false;
  }
  /* Check whether all items are already delivered. */
  const isAnyOneItemProcessed = existingOrderRequestItems.some(
    (item) =>
      [
        OrderRequestItemStatusEnum.Delivered,
        OrderRequestItemStatusEnum.Returned,
      ].includes(item.status) ||
      item.statusHistory.some((statusHistory) =>
        [
          OrderRequestItemStatusEnum.Returned,
          OrderRequestItemStatusEnum.Delivered,
        ].includes(statusHistory.status),
      ),
  );
  const areAllTheProcessableItemsProcessed = existingOrderRequestItems.every((item) =>
    [
      OrderRequestItemStatusEnum.Delivered,
      OrderRequestItemStatusEnum.Returned,
      OrderRequestItemStatusEnum.Cancelled,
      OrderRequestItemStatusEnum.Merged,
      OrderRequestItemStatusEnum.Closed,
    ].includes(item.status),
  );
  if (isAnyOneItemProcessed || areAllTheProcessableItemsProcessed) {
    return false;
  }
  return true;
}
