import { useCallback, useMemo } from 'react';
import { Box, InputAdornment } from '@mui/material';
import { CustomIcons } from '@procurenetworks/procure-component-library';
import Receivables from 'app/i18n/Receivables';
import EntityManager from 'app/modules/components/EntityManager';
import { processSearch } from 'app/utils/processSearch';

import useScheduleShipmentState from '../../hook/useScheduleShipmentState';
import useSearchSuggestion from '../../hook/useShipmentReceiveSearchSuggetion';
import ScheduledReceiving from '../ScheduledReceiving';
import ScheduleReceiveForm from '../ScheduleForm/ReceiveForm';

const ScheduleReceive = (props: any) => {
  const { onNext, step1Data } = props;
  const { formState, setFormState, onAdd, rows, resetRows, search, onRemoveRow } = useScheduleShipmentState({
    step1Data,
  });
  const { searchSuggestions, setSearchValue, filterOptionData } = useSearchSuggestion({
    searchOptions: rows,
  });
  const tableFilterData = useMemo(() => {
    const searchValue = search.value;
    if (search.value) {
      const result = filterOptionData.map((item: any) => {
        const { result, searchBy } = processSearch(
          [
            'carrier',
            'title',
            'vendor',
            'trackingNumber',
            'orderRequestCode',
            'deliverTo',
            'itemId',
          ],
          searchValue,
          item,
        );

        return {
          label: searchBy,
          value: result,
          id: item.itemId,
        };
      });

      if (result.length) {
        const filterOption = rows.filter((filterItem: any) => {
          const findItem = result.find((find: any) => filterItem.id.toString() === find.id);
          if (findItem) {
            return {
              ...filterItem,
            };
          }
        });
        return filterOption;
      }
    }
    return rows;
  }, [rows, search.value, filterOptionData, resetRows]);

  const onNextClick = useCallback(() => {
    onNext(rows);
  }, [rows]);

  return (
    <>
      <Box className="flex flex-col flex-wrap gap-[24px]  md:flex-col  lg:w-full  lg:flex-row xl:w-full xl:flex-row">
        <Box
          className="
            m-[auto]
            w-[300px]
            pt-6
            md:m-[0px]
            md:min-w-[416px] 
            md:max-w-[416px]
            lg:m-[0px]
            lg:min-w-[416px]
            xl:m-[0px]
            xl:max-w-[416px]
          ">
          <EntityManager.Title
            documentTitle={Receivables.Schedule}
            title={Receivables.Schedule}
            titleWrapperClass="!pt-[0px]"
          />
          <ScheduleReceiveForm {...{ formState, setFormState, onAdd }} />
        </Box>
        <Box className="flex-1" sx={{ overflowX: "auto"}}>
          {rows.length > 0 && (
            <ScheduledReceiving
              filterNodes={
                <EntityManager.Search
                  {...search}
                  autoFocus
                  isDropdown
                  removedSearchQoutedValue
                  placeholder="Search"
                  startAdornment={
                    <InputAdornment className="mt-[4px] ml-[5px]" position="start">
                      <CustomIcons.SearchIcon />
                    </InputAdornment>
                  }
                  suggestionData={searchSuggestions || []}
                  onChangeSearch={setSearchValue}
                />
              }
              previewRows={tableFilterData}
              title={'Shipment to be Received'}
              onCancel={resetRows}
              onNext={onNextClick}
              onRemoveRow={onRemoveRow}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default ScheduleReceive;
