import { useCallback, useMemo, useRef } from 'react';
import { Grid } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import DeleteKitConfirmationModal from 'app/modules/assetsInventory/components/DeleteKitConfirmationModal';
import FindUPC from 'app/modules/components/FindUPC';
import KitSummary from 'app/modules/components/KitSummary';
import useKitSummary from 'app/modules/components/KitSummary/context/useKitSummary';
import { getAttachmentList } from 'app/modules/components/KitSummary/utils';

import { useAccessControl } from '../../../../components/AccessControl';
import { SnackbarService } from '../../../../components/Snackbar';
import Common from '../../../../i18n/Common';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  AssetKitItemSchema,
  ItemTypeEnum,
} from '../../../../types/schema';
import Box from '../../../../ui-components/Box';
import useCurrentUser from '../../../auth/hooks/useCurrentUser';
import DeleteConfirmationModal from '../../../components/EntityManager/DeleteConfirmationModal';
import { UseAssetFormReturnType } from '../../hook/useAssetForm';
import AssetAssetModelField from './components/AssetAssetModelField';
import AssetAttachmentField from './components/AssetAttachmentField';
import AssetBrandField from './components/AssetBrandField';
import AssetCategoryField from './components/AssetCategoryField';
import AssetDescriptionField from './components/AssetDescriptionField';
import AssetDestinationSIteLocationQtyFields from './components/AssetDestinationSIteLocationQtyFields';
import AssetDisplayInOrderField from './components/AssetDisplayInOrderField';
import AssetITAssetAccordion from './components/AssetITAssetAccordion';
import AssetManufacturerField from './components/AssetManufacturerField';
import AssetModelField from './components/AssetModelField';
import AssetPurchaseDateField from './components/AssetPurchaseDateField';
import AssetReminderField from './components/AssetReminderField';
import { UseAssetReminderStateReturnType } from './components/AssetReminderField/hook/useAssetReminderState';
import AssetReplacementDateField from './components/AssetReplacementDateField';
import AssetSerialNumberField from './components/AssetSerialNumberField';
import AssetSkuField from './components/AssetSkuField';
import AssetTitleField from './components/AssetTitleField';
import AssetTypeField from './components/AssetTypeField';
import AssetUnitCostOverrideField from './components/AssetUnitCostOverrideField';
import AssetUpcCodeField from './components/AssetUpcCodeField';
import AssetVendorField from './components/AssetVendorField';
import AssetWarrantyExpiryDateField from './components/AssetWarrantyExpiryDateField';
import NextCancelActionButtons from './components/NextCancelActionButtons';
import SaveCancelActionButtons from './components/SaveCancelActionButtons';
import ScrollToError from 'app/utils/ScrollToError';

interface Props {
  disabled: boolean;
  fetching?: boolean;
  loading?: boolean;
  formState: UseAssetFormReturnType['formState'] & UseAssetReminderStateReturnType;
  onSubmit: (values: any, kitItems: any) => void;
  onCancel: () => void;
  onDelete?: () => void;
}

const AssetForm = (props: Props) => {
  const {
    disabled: formDisabled,
    loading,
    fetching,
    formState,
    onSubmit,
    onCancel,
    onDelete,
  } = props;
  const { workspacePermissions } = useCurrentUser();
  const { kitState } = useKitSummary();
  const formRef = useRef<HTMLDivElement>(null);

  const {
    showFindUpcSection,
    onSaveProductData,
    handleSubmit,
    editable,
    makeEditable,
    onDeleteConfirmation,
    showDeleteConfirmation,
    attachmentInputProps,
    onCancelDelete,
    showKitSummary,
    onSaveKitData,
    state: assetReminderState,
    setState: setAssetReminderState,
    itemData,
    isItemTypeAssetKit,
  } = formState;

  const disabled = useMemo(() => {
    return !editable || formDisabled;
  }, [editable, formDisabled]);

  const canEdit = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Edit,
    AllowedPermissionsSubjectEnum.Asset,
  );
  const canDelete = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Delete,
    AllowedPermissionsSubjectEnum.Asset,
  );
  const canEditOrDelete = canEdit || canDelete;

  const onError = useCallback((errors: any) => { ScrollToError(formState.control._formState.errors, formRef) }, []);

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit, onError), [handleSubmit, onSubmit]);

  const onNext = useMemo(() => handleSubmit(onSaveKitData, onError), [handleSubmit, onSaveKitData]);

  const filesAttachments = useMemo(() => {
    return getAttachmentList(attachmentInputProps?.attachments);
  }, [attachmentInputProps?.attachments]);

  const files = useMemo(() => {
    const attachments = kitState?.rows?.flatMap((row) => row?.attachments);
    const _attachments = getAttachmentList(attachments);
    return [...filesAttachments, ..._attachments];
  }, [filesAttachments, kitState?.rows]);

  return (
    <div ref={formRef} className="mt-20">
      <Box className="flex flex-wrap gap-[24px] lg:w-full xl:w-full">
        <Box
          className="
            w-full
            pt-6 
            md:m-[0px]
            md:min-w-[416px]
            md:max-w-[416px]
            lg:m-[0px]
            lg:min-w-[416px] 
            lg:min-w-[416px]
            xl:m-[0px]
            xl:max-w-[416px]
            xl:max-w-[416px]
        ">
          <form className="w-full">
            {!editable && canEditOrDelete ? (
              <Box className="mb-20 flex justify-start gap-[16px]" data-testid="edit-asset-actions">
                {canEdit ? (
                  <Button
                    disabled={
                      formDisabled ||
                      Boolean(
                        itemData?.entityIdInSourceTenant &&
                        itemData?.type === ItemTypeEnum.AssetKit,
                      )
                    }
                    theme="info"
                    onClick={() => {
                      makeEditable();
                    }}
                    classes="min-w-[94px] !px-[10px] h-[44px]">
                    {Common.Actions.Edit}
                  </Button>
                ) : undefined}
                {canDelete ? (
                  <Button
                    disabled={formDisabled}
                    loading={!!fetching}
                    theme="danger"
                    onClick={onDeleteConfirmation}
                    classes="min-w-[94px] !px-[10px] h-[44px]">
                    {Common.Actions.Delete}
                  </Button>
                ) : undefined}
              </Box>
            ) : undefined}
            <AssetTypeField disabled={disabled || showKitSummary} formState={formState} />
            <AssetSkuField disabled={disabled} formState={formState} />
            <AssetAssetModelField disabled={disabled} formState={formState} />
            <AssetUpcCodeField disabled={disabled} formState={formState} />
            <AssetTitleField disabled={disabled} formState={formState} />
            <AssetModelField disabled={disabled} formState={formState} />
            <AssetSerialNumberField disabled={disabled} formState={formState} />
            <AssetCategoryField disabled={disabled} formState={formState} />
            <AssetDescriptionField disabled={disabled} formState={formState} />
            <AssetVendorField disabled={disabled} formState={formState} />
            <AssetBrandField disabled={disabled} formState={formState} />
            <AssetManufacturerField disabled={disabled} formState={formState} />
            <AssetITAssetAccordion disabled={disabled} formState={formState} />
            <AssetDestinationSIteLocationQtyFields
              disabled={disabled || showKitSummary}
              formState={formState}
            />
            <AssetUnitCostOverrideField disabled={disabled} formState={formState} />
            <AssetReminderField
              disabled={disabled}
              formState={{
                ...formState,
                state: assetReminderState,
                setState: setAssetReminderState,
              }}
            />
            <AssetPurchaseDateField disabled={disabled} formState={formState} />
            <AssetReplacementDateField disabled={disabled} formState={formState} />
            <AssetWarrantyExpiryDateField disabled={disabled} formState={formState} />
            <AssetDisplayInOrderField disabled={disabled} formState={formState} />
            <AssetAttachmentField disabled={disabled} formState={formState} />

            <SaveCancelActionButtons
              disabled={disabled}
              formState={formState}
              onCancel={onCancel}
              onSubmit={onFormSubmit}
            />
            <NextCancelActionButtons
              disabled={disabled || showKitSummary}
              formState={formState}
              onCancel={onCancel}
              onNext={onNext}
            />
          </form>
        </Box>

        {showKitSummary ? (
          <Box className="flex-1 min-w-[320px]" style={{ overflowX: "auto" }}>
            <KitSummary
              disabled={disabled}
              loading={loading}
              state={formState}
              types={[ItemTypeEnum.Asset]}
              onSubmit={onSubmit}
            />
          </Box>
        ) : null}

        {showFindUpcSection ? (
          <Box className="flex-1" style={{ overflowX: "auto" }}>
            <FindUPC onSaveProductData={onSaveProductData} />
          </Box>
        ) : undefined}
      </Box>

      {isItemTypeAssetKit && itemData ? (
        <DeleteKitConfirmationModal
          itemData={itemData as unknown as AssetKitItemSchema}
          open={showDeleteConfirmation}
          onCancel={onCancelDelete}
          onConfirm={() => {
            if (onDelete) {
              onCancelDelete();
              onDelete();
            }
          }}
        />
      ) : (
        <DeleteConfirmationModal
          open={showDeleteConfirmation}
          onCancel={onCancelDelete}
          onConfirm={() => {
            if (onDelete) {
              onCancelDelete();
              onDelete();
            }
          }}
        />
      )}
    </div>
  );
};

export default AssetForm;
