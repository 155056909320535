import { useCallback } from 'react';
import { Content } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { removeExtraSpacesAndNewlines, removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';

import routes from '../../../../consts/routes';
import Inventory from '../../../../i18n/Inventory';
import { ItemTypeEnum } from '../../../../types/schema';
import { useCreateRestockTransactionsMutation } from '../../../assetsInventory/restock/graphql/mutations/generated/createRestockTransactions';
import EntityManager from '../../../components/EntityManager';
import useInventoryForm from '../../components/InventoryForm/hook/useInventoryForm';
import { CreateInventoryFormInput } from '../../components/InventoryForm/types';
import InventoryFormView from '../../components/InventoryFormView';
import { useCreateInventoryItemMutation } from './graphql/mutations/generated/createInventoryItem';
import { useCreateInventoryKitItemMutation } from './graphql/mutations/generated/createInventoryKitItem';
import analytics from 'app/analytics';

const DEFAULT_VALUES: CreateInventoryFormInput = {
  type: ItemTypeEnum.Inventory,
  productCodeInput: '',
  upcCode: '',
  asinCode: '',
  gtinCode: '',
  noUpcCode: false,
  attachments: [],
  brand: '',
  categoryId: '',
  unitCost: '0.00',
  costOverride: '',
  description: '',
  mName: '',
  title: '',
  vendorId: '',
  sku: 'INV******',
  destinationSiteId: '',
  destinationLocationId: '',
  quantity: '0',
  findUpc: false,
  pickableThroughOrderRequest: true,
  manufacturerId: '',
};

const AddInventory = (props: RouteComponentProps) => {
  const { history } = props;

  const [{ fetching }, onCreateInventory] = useCreateInventoryItemMutation();
  const [{ fetching: creatingKit }, onCreateInventoryKit] = useCreateInventoryKitItemMutation();

  const [{ fetching: creatingRestockTransactions }, onCreateRestockTransactions] =
    useCreateRestockTransactionsMutation();

  const { state, createInventoryItemInput, createInventoryKitItemInput, createRestockTransaction } =
    useInventoryForm({
      defaultValues: { ...DEFAULT_VALUES },
    });

  const onCreateProduct = useCallback(
    async (input: any) => {
      const response = await onCreateInventory({
        input: {
          ...createInventoryItemInput(input),
        },
      });
      const entityId = response?.data?.createInventoryItem.inventoryItem?.id || undefined;
      if (response?.data?.createInventoryItem?.success && entityId) {
        analytics?.track('Created', { name: 'Inventory' });
        SnackbarService.show({
          message: Inventory.SuccessMessages.InventoryCreated,
        });
        // Creating restock Transactions based on Selected Location and Site
        const restockTransactions = await onCreateRestockTransactions({
          input: createRestockTransaction({
            ...input,
            entityId,
          }),
        });
        if (!restockTransactions.error) {
          history.replace(routes.Inventory());
        } else {
          console.error('[Create Restock Transaction Failed in Inventory]', response);
        }
        history.replace(routes.Inventory());
      } else {
        console.error('[Create Inventory] Failed', response);
      }
    },
    [
      createInventoryItemInput,
      createRestockTransaction,
      history,
      onCreateInventory,
      onCreateRestockTransactions,
    ],
  );

  const onCreateKit = useCallback(async (input: any, kitItems: any[]) => {
    // Creating Inventory Item
    const response = await onCreateInventoryKit({
      input: {
        ...createInventoryKitItemInput(input, kitItems),
      },
    });
    const entityId = response?.data?.createInventoryKitItem.inventoryKitItem?.id || undefined;
    if (response?.data?.createInventoryKitItem?.success && entityId) {
      analytics?.track('Created', { name: 'Inventory Kit' });
      SnackbarService.show({
        message: Inventory.SuccessMessages.InventoryKitCreated,
      });

      history.replace(routes.Inventory());
    } else {
      console.error('[Create Inventory Kit] Failed', response);
    }
  }, []);

  const onSubmit = useCallback(
    async (input: any, kitItems: any) => {
      const itemInput = {
        ...input,
        title: removeMultipleSpaces(input.title),
        description: removeExtraSpacesAndNewlines(input.description),
        formattedDescription: input.description,
      };
      // Creating Inventory Item
      if (itemInput.type === ItemTypeEnum.Inventory) {
        onCreateProduct(itemInput);
      } else {
        onCreateKit(itemInput, kitItems);
      }
    },
    [history, onCreateInventory],
  );

  const onCancel = useCallback(() => {
    history.push(routes.Inventory());
  }, [history]);

  return (
    <EntityManager className="rounded-bl-[12px] rounded-br-[12px] pt-0">
      <EntityManager.Title
        documentTitle={Inventory.NewInventory}
        title={Inventory.NewInventory}
        hasTopDivider
      />

      <InventoryFormView
        disabled={fetching || creatingKit || creatingRestockTransactions}
        loading={fetching || creatingKit || creatingRestockTransactions}
        state={state}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </EntityManager>
  );
};

export default withRouter(AddInventory);
