import { DataGridHeadCell, Typography } from '@procurenetworks/procure-component-library';
import EntityManager from '../../../components/EntityManager';
import { UseMultiSelectEntityManagerReturnType } from '../../../components/EntityManager/useMultiSelectEntityManager';
import { OrderRequestItemSchema } from '../../../../types/schema';
import './style.css';
import { ReactElement, useMemo } from 'react';
import { footerRowItem } from '../../views/OrderDetail/hook/useOrderRequestItems/utils';
import { Box } from '@mui/material';

interface Props {
  rows: any[];
  extraProps?: any;
  title: string;
  headers: DataGridHeadCell[];
  table: UseMultiSelectEntityManagerReturnType['table'];
  isRowSelectable?: (row: OrderRequestItemSchema) => boolean;
  onSortModelChange?: any;
  filterNode?:ReactElement
}

const OrderDetailTable = (props: Props) => {
  const { rows, headers, extraProps, title, table, isRowSelectable, onSortModelChange,filterNode } = props;

  const footerRowItems = useMemo(() => [footerRowItem(rows)], [rows]);

  return (
    <>
      <div className="mt-[20px] px-[24px]">
        <Typography variant="title">{title}</Typography>
      </div>
      
      <EntityManager.MultiSelectTable
        actions={table.actions}
        columns={headers}
        data={rows}
        extraProps={extraProps}
        pagination={true}
        persistKey="order_detail_items"
        setState={table.setState}
        state={table.state}
        total={rows.length}
        footerRows={footerRowItems}
        rowHeight={80}
        hideAllSelectionCheckbox
        isRowSelectable={isRowSelectable}
        persistSelectionData
        onSortModelChangeFunc={onSortModelChange}
        paginationWrapperClass='p-[24px] flex-wrap md:gap-[24px]'
        filterNodes={filterNode}
      />
      
    </>
  );
};

export default OrderDetailTable;
