const AssetModel = {
  AssetModel: 'Asset Model',
  AssetModels: 'Asset Models',
  NewAssetModel: 'New Asset Model',
  EditAssetModel: 'Edit Asset Model',
  FormLabels: {
    Model: 'Model',
    ModelNumber: 'Model Number',
    UpcCode: 'Enter UPC, ASIN or keyword to find item',
    FindUpc: 'Find Item',
    NoUpcCode: 'No product code',
    UpcCodeLabel: "UPC Code",
    AsinCodeLabel: "ASIN Code",
    Title: 'Title',
    Category: 'Category',
    Description: 'Description',
    Vendor: 'Vendor',
    Brand: 'Brand',
    Manufacturer: 'Manufacturer',
    UnitCost: 'Cost',
    CostOverride: 'Cost Override %',
    DisplayInOrder: 'Display In Order Request',
    Attachments: 'Attachments',
  },
  FormPlaceholders: {
    UpcCode: 'Search UPC, ASIN',
    CategorySelect: 'Category',
    VendorSelect: 'Vendor',
    ManufacturerSelect: 'Manufacturer',
  },
  FormValidationMessages: {
    CategoryRequired: 'Please select a Category.',
    TitleRequired: 'Please enter a Title up to 32 characters.',
    ModelRequired: 'Please enter a Model.',
    InvalidCostOverride: 'Please enter a valid Cost Override percentage (xxx.xx).',
  },
  SuccessMessages: {
    AssetModelCreated: 'This asset model has been successfully created.',
    AssetModelUpdated: 'This asset model has been successfully updated.',
    AssetModelItemsDeleted: (items: string[]) =>
      items.length > 1
        ? 'These asset model items have been successfully deleted.'
        : 'This asset model has been successfully deleted.',
  },
};

export default AssetModel;
