import { useCallback, useMemo, useRef } from 'react';
import { Box, Grid } from '@mui/material';
import DeleteKitConfirmationModal from 'app/modules/assetsInventory/components/DeleteKitConfirmationModal';
import FindUPC from 'app/modules/components/FindUPC';
import KitSummary from 'app/modules/components/KitSummary';
import useKitSummary from 'app/modules/components/KitSummary/context/useKitSummary';
import { getAttachmentList } from 'app/modules/components/KitSummary/utils';
import { InventoryKitItemSchema, ItemTypeEnum } from 'app/types/schema';

import { SnackbarService } from '../../../../components/Snackbar';
import DeleteConfirmationModal from '../../../components/EntityManager/DeleteConfirmationModal';
import EditDeleteActionButtons from './components/EditDeleteActionButtons';
import InventoryAttachmentField from './components/InventoryAttachmentField';
import InventoryBrandField from './components/InventoryBrandField';
import InventoryCategoryField from './components/InventoryCategoryField';
import InventoryDescriptionField from './components/InventoryDescriptionField';
import InventoryDestinationSIteLocationQtyFields from './components/InventoryDestinationSIteLocationQtyFields';
import InventoryDisplayInOrderField from './components/InventoryDisplayInOrderField';
import InventoryManufacturerField from './components/InventoryManufacturerField';
import InventoryModelField from './components/InventoryModelField';
import InventorySkuField from './components/InventorySkuField';
import InventoryTitleField from './components/InventoryTitleField';
import InventoryTypeField from './components/InventoryTypeField';
import InventoryUnitCostOverrideField from './components/InventoryUnitCostOverrideField';
import InventoryUpcCodeField from './components/InventoryUpcCodeField';
import InventoryVendorField from './components/InventoryVendorField';
import NextCancelActionButtons from './components/NextCancelActionButtons';
import SaveCancelActionButtons from './components/SaveCancelActionButtons';
import { UseInventoryFormReturnType } from './hook/useInventoryForm';
import ScrollToError from 'app/utils/ScrollToError';

interface Props {
  state: UseInventoryFormReturnType['state'];
  disabled: boolean;
  loading?: boolean;
  isEditMode?: boolean;
  onSubmit: (values: any, kitItems: any) => void;
  onCancel?: () => void;
  onDelete?: () => void;
}

const InventoryForm = (props: Props) => {
  const { state, disabled: formDisabled, loading, onSubmit, onCancel, onDelete } = props;
  const {
    showFindUpcSection,
    noUpcCode,
    onSaveProductData,
    handleSubmit,
    showDeleteConfirmation,
    onSaveKitData,
    showKitSummary,
    onCancelDelete,
    editable,
    attachmentInputProps,
    isItemTypeKit,
    itemData,
  } = state;
  const { kitState } = useKitSummary();
  const formRef = useRef<HTMLDivElement>(null);

  const disabled = useMemo(() => {
    return !editable || formDisabled;
  }, [editable, formDisabled]);

  const onError = useCallback((errors: any) => { ScrollToError(state.control._formState.errors, formRef) }, []);

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit, onError), [handleSubmit, onSubmit]);

  const onNext = useMemo(() => handleSubmit(onSaveKitData, onError), [handleSubmit, onSaveKitData]);

  const filesAttachments = useMemo(() => {
    return getAttachmentList(attachmentInputProps?.attachments);
  }, [attachmentInputProps?.attachments]);

  const files = useMemo(() => {
    const attachments = kitState?.rows?.flatMap((row) => row?.attachments);
    const _attachments = getAttachmentList(attachments);
    return [...filesAttachments, ..._attachments];
  }, [filesAttachments, kitState?.rows]);

  return (
    <div ref={formRef}>
      <Box className="flex flex-wrap gap-[24px] lg:w-full xl:w-full">
        <Box
          className="
            w-full
            pt-6 
            md:m-[0px]
            md:min-w-[416px]
            md:max-w-[416px]
            lg:m-[0px]
            lg:min-w-[416px]
            xl:m-[0px]
            xl:max-w-[416px]
        ">
          <form className="mt-20" onSubmit={onFormSubmit}>
            <EditDeleteActionButtons disabled={formDisabled} formState={state} />
            <InventoryTypeField disabled={disabled || showKitSummary} formState={state} />
            <InventorySkuField formState={state} />
            <InventoryUpcCodeField disabled={disabled} formState={state} />
            <InventoryTitleField disabled={disabled} formState={state} />
            <InventoryModelField disabled={disabled} formState={state} />
            <InventoryCategoryField disabled={disabled} formState={state} />
            <InventoryDescriptionField disabled={disabled} formState={state} />
            <InventoryVendorField disabled={disabled} formState={state} />
            <InventoryBrandField disabled={disabled} formState={state} />
            <InventoryManufacturerField disabled={disabled} formState={state} />
            <InventoryDestinationSIteLocationQtyFields
              disabled={disabled || showKitSummary}
              formState={state}
            />
            <InventoryUnitCostOverrideField disabled={disabled} formState={state} />
            <InventoryDisplayInOrderField disabled={disabled} formState={state} />
            <InventoryAttachmentField disabled={disabled} formState={state} />
            <SaveCancelActionButtons
              disabled={disabled}
              formState={state}
              onCancel={onCancel}
              onSubmit={onFormSubmit}
            />
            <NextCancelActionButtons
              disabled={disabled || showKitSummary}
              formState={state}
              onCancel={onCancel}
              onNext={onNext}
            />
          </form>
        </Box>

        {showKitSummary ? (
          <Box className="flex-1 min-w-[320px]" style={{ overflowX: "auto" }}>
            <KitSummary
              disabled={disabled}
              loading={loading}
              state={state}
              types={[ItemTypeEnum.Inventory]}
              onSubmit={onSubmit}
            />
          </Box>
        ) : undefined}
        {!noUpcCode && showFindUpcSection ? (
          <Box className="mt-[24px] flex-1" style={{ overflowX: "auto" }}>
            <FindUPC onSaveProductData={onSaveProductData} />
          </Box>
        ) : undefined}
      </Box>
      {isItemTypeKit && itemData ? (
        <DeleteKitConfirmationModal
          itemData={itemData as unknown as InventoryKitItemSchema}
          open={showDeleteConfirmation}
          onCancel={onCancelDelete}
          onConfirm={() => {
            if (onDelete) {
              onCancelDelete();
              onDelete();
            }
          }}
        />
      ) : (
        <DeleteConfirmationModal
          open={showDeleteConfirmation}
          onCancel={onCancelDelete}
          onConfirm={() => {
            if (onDelete) {
              onCancelDelete();
              onDelete();
            }
          }}
        />
      )}
    </div>
  );
};

export default InventoryForm;
