import moment from 'moment-timezone';
import { DateFormatEnum, formatDate } from 'app/utils/date';

import {
  AssetItemReminderTypeEnum,
  CreateAssetItemInput,
  CreateAssetItemReminderInput,
  CreateAssetKitItemInput,
  CreateRestockTransactionsInput,
  ExternalProductCodeInput,
  ItemExternalProductCodeTypeEnum,
  ItemTypeEnum,
  RestockTransactionSubTypeEnum,
  TransactionEntityTypeEnum,
  UpdateAssetItemInput,
  UpdateAssetItemReminderInput,
  UpdateAssetKitItemInput,
} from '../../../types/schema';
import { AssetReminderInput } from '../components/AssetForm/components/AssetReminderField/components/AssetReminderForm/types';
import { removeExtraSpacesAndNewlines } from 'app/utils/removeMultipleSpaces';

export const createExternalProductCodesData = (values: any) => {
  const { noUpcCode, type, upcCode, asinCode, gtinCode } = values;

  return !noUpcCode && (type === ItemTypeEnum.Asset || type === ItemTypeEnum.Inventory)
    ? [
        upcCode && { type: ItemExternalProductCodeTypeEnum.Upc, code: upcCode },
        asinCode && { type: ItemExternalProductCodeTypeEnum.Asin, code: asinCode },
        gtinCode && { type: ItemExternalProductCodeTypeEnum.Gtin, code: gtinCode },
      ].filter(Boolean) as ExternalProductCodeInput[]
    : [];
};

export const createAssetItemInput = (values: any): CreateAssetItemInput => {
  const {
    type,
    mName,
    modelNumber,
    brand,
    categoryId,
    title,
    serialNumber,
    description,
    vendorId,
    unitCost,
    // reminder, ( We will add it letter on )
    costOverride,
    purchaseDate,
    warrantyExpiryDate,
    attachments,
    replacementDate,
    protectedAttachments,
    pickableThroughOrderRequest,
    parentId,
    manufacturerId,
    formattedDescription,
  } = values;

  const isItemTypeAsset = type === ItemTypeEnum.Asset;

  return {
    title,
    description,
    formattedDescription,
    categoryId,
    attachments,
    unitCost: unitCost ? Number(unitCost) : 0,
    costOverride: costOverride ? Number(costOverride) : 0,
    externalProductCodes: createExternalProductCodesData(values),
    brand: isItemTypeAsset && brand ? brand : undefined,
    vendorId: isItemTypeAsset && vendorId ? vendorId : undefined,
    mName: isItemTypeAsset && mName ? mName.trim() : undefined,
    modelNumber: isItemTypeAsset && modelNumber ? modelNumber : undefined,
    purchaseDate:
      isItemTypeAsset && purchaseDate
        ? getCurrentUTCTime(formatDate(purchaseDate, DateFormatEnum.UTC))
        : undefined,
    serialNumber: isItemTypeAsset && serialNumber ? serialNumber : undefined,
    warrantyExpiryDate:
      isItemTypeAsset && warrantyExpiryDate
        ? getCurrentUTCTime(formatDate(warrantyExpiryDate, DateFormatEnum.UTC))
        : undefined,
    replacementDate:
      isItemTypeAsset && replacementDate
        ? getCurrentUTCTime(formatDate(replacementDate, DateFormatEnum.UTC))
        : undefined,
    pickableThroughOrderRequest,
    type,
    protectedAttachments: protectedAttachments || [],
    parentId: parentId || undefined,
    manufacturerId: manufacturerId || undefined,
  };
};

export const createAssetKitItemInput = (values: any, kitItems: any[]): CreateAssetKitItemInput => {
  const {
    costOverride,
    unitCost,
    type,
    description,
    categoryId,
    title,
    attachments,
    destinationSiteId,
    destinationLocationId,
    quantity,
    manufacturerId,
    pickableThroughOrderRequest,
    formattedDescription,
  } = values || {};

  const _kitItems =
    kitItems?.map((row: any) => {
      return { itemId: row.id, count: row.quantity, locationId: row.locationId };
    }) || [];

  return {
    title,
    description,
    formattedDescription,
    categoryId,
    attachments,
    unitCost: unitCost ? Number(unitCost) : 0,
    costOverride: costOverride ? Number(costOverride) : 0,
    type,
    siteId: destinationSiteId,
    locationId: destinationLocationId,
    quantity: quantity ? Number(quantity) : 0,
    manufacturerId,
    kitConfigs: _kitItems,
    pickableThroughOrderRequest,
  };
};

export const createUpdateAssetItemInput = (values: any): UpdateAssetItemInput => {
  const {
    mName,
    modelNumber,
    brand,
    categoryId,
    title,
    serialNumber,
    description,
    formattedDescription,
    vendorId,
    unitCost,
    // reminder, ( We will add it letter on )
    costOverride,
    purchaseDate,
    replacementDate,
    warrantyExpiryDate,
    attachments,
    id,
    protectedAttachments,
    pickableThroughOrderRequest,
    parentId,
    manufacturerId,
  } = values;

  return {
    itemId: id || '',
    title,
    attachments,
    unitCost: unitCost ? Number(unitCost) : 0,
    costOverride: costOverride ? Number(costOverride) : 0,
    externalProductCodes: createExternalProductCodesData(values),
    mName: mName.trim(),
    modelNumber,
    brand,
    categoryId,
    purchaseDate: purchaseDate
      ? getCurrentUTCTime(formatDate(purchaseDate, DateFormatEnum.UTC))
      : '',
    warrantyExpiryDate: warrantyExpiryDate
      ? getCurrentUTCTime(formatDate(warrantyExpiryDate, DateFormatEnum.UTC))
      : '',
    replacementDate: replacementDate
      ? getCurrentUTCTime(formatDate(replacementDate, DateFormatEnum.UTC))
      : '',
    pickableThroughOrderRequest,
    description,
    formattedDescription,
    serialNumber,
    vendorId: vendorId || '',
    protectedAttachments: protectedAttachments || [],
    parentId: parentId || '',
    manufacturerId: manufacturerId || '',
  };
};

export const createUpdateAssetKitItemInput = (values: any): UpdateAssetKitItemInput => {
  const {
    costOverride,
    description,
    formattedDescription,
    categoryId,
    title,
    attachments,
    quantity,
    id,
    pickableThroughOrderRequest,
  } = values;

  return {
    assetKitItemId: id || '',
    title,
    attachments: {
      set: attachments,
    },
    costOverride: costOverride ? Number(costOverride) : 0,
    categoryId,
    quantity: quantity ? Number(quantity) : 0,
    description,
    pickableThroughOrderRequest,
    formattedDescription,
  };
};

export const createRestockTransactionInput = (
  values: any,
  subType: RestockTransactionSubTypeEnum,
): CreateRestockTransactionsInput => {
  const { entityId, quantity, destinationSiteId, destinationLocationId } = values;
  return {
    entities: [
      {
        entityType: TransactionEntityTypeEnum.Asset,
        entityId: entityId,
        destinationSiteId,
        destinationLocationId,
        quantity: Number(quantity),
      },
    ],
    subType: subType,
  };
};

export const createAddAssetItemReminderInput = (
  values: AssetReminderInput,
  assetItemId: string,
): CreateAssetItemReminderInput => {
  const {
    cron,
    lastReminderAt,
    note,
    notifyUserIds,
    recurring,
    startReminderAt,
    isMaintenance,
    recurringInterval,
  } = values;
  return {
    assetItemId,
    cron,
    lastReminderAt: lastReminderAt ? moment(lastReminderAt).format() : null,
    note: removeExtraSpacesAndNewlines(note),
    formattedNote: note,
    notifyUserIds,
    recurring: recurring ?? true,
    startReminderAt: moment(startReminderAt).format(),
    recurringInterval,
    type: isMaintenance
      ? AssetItemReminderTypeEnum.Maintenance
      : AssetItemReminderTypeEnum.Reminder,
  };
};

export const createUpdateAssetItemReminderInput = (values: any): UpdateAssetItemReminderInput => {
  const { notifyUserIds, note, id, cron, startReminderAt, recurringInterval, isMaintenance } =
    values;
  return {
    notifyUserIds,
    note: removeExtraSpacesAndNewlines(note),
    formattedNote: note,
    reminderId: id,
    type: isMaintenance
      ? AssetItemReminderTypeEnum.Maintenance
      : AssetItemReminderTypeEnum.Reminder,
    cron,
    startReminderAt,
    recurringInterval,
  };
};

export const getCurrentUTCTime = (date: string) => {
  return new Date(new Date(date).setHours(12, 30)).toISOString();
};

export const getCurrentDateInGMTMinus7 = () => {
  return moment().tz('Etc/GMT+7').format('YYYY-MM-DD');
};
