import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ItemInStockSelectQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.ItemFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  page?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type ItemInStockSelectQuery = { __typename?: 'Query', items: { __typename?: 'ItemConnection', edges: Array<{ __typename?: 'ItemEdge', node: { __typename?: 'AssetItemSchema', id: string, sku: string, title: string, type: Types.ItemTypeEnum, attachments?: Array<{ __typename?: 'MediaSchema', url?: string | null }> | null } | { __typename?: 'AssetKitItemSchema', id: string, sku: string, title: string, type: Types.ItemTypeEnum, locationId?: string | null, siteId: string, attachments?: Array<{ __typename?: 'MediaSchema', url?: string | null }> | null } | { __typename?: 'AssetModelItemSchema', id: string, sku: string, title: string, type: Types.ItemTypeEnum, attachments?: Array<{ __typename?: 'MediaSchema', url?: string | null }> | null } | { __typename?: 'InventoryItemSchema', id: string, sku: string, title: string, type: Types.ItemTypeEnum, attachments?: Array<{ __typename?: 'MediaSchema', url?: string | null }> | null } | { __typename?: 'InventoryKitItemSchema', id: string, sku: string, title: string, type: Types.ItemTypeEnum, locationId?: string | null, siteId: string, attachments?: Array<{ __typename?: 'MediaSchema', url?: string | null }> | null } }> } };


export const ItemInStockSelectDocument = gql`
    query itemInStockSelect($filters: ItemFilters, $sorts: [SortProps!], $limit: Float, $page: Float) {
  items(filters: $filters, sorts: $sorts, limit: $limit, page: $page) {
    edges {
      node {
        ... on AssetItemSchema {
          id
          sku
          attachments {
            url
          }
          title
          type
        }
        ... on AssetKitItemSchema {
          id
          sku
          attachments {
            url
          }
          title
          type
          locationId
          siteId
        }
        ... on InventoryItemSchema {
          id
          sku
          attachments {
            url
          }
          title
          type
        }
        ... on InventoryKitItemSchema {
          id
          sku
          attachments {
            url
          }
          title
          type
          locationId
          siteId
        }
        ... on AssetModelItemSchema {
          id
          sku
          attachments {
            url
          }
          title
          type
        }
      }
    }
  }
}
    `;

export function useItemInStockSelectQuery(options?: Omit<Urql.UseQueryArgs<ItemInStockSelectQueryVariables>, 'query'>) {
  return Urql.useQuery<ItemInStockSelectQuery>({ query: ItemInStockSelectDocument, ...options });
};