import { useMemo } from 'react';

import { CreateMediaInput } from '../../../types/schema';
import { getSupportedImageAttachments } from '../../../utils/attachments';

interface Props {
  attachments?: CreateMediaInput[];
  url?: string;
  onImageClick?: (imageUrl: string) => void;
}

const TableImageView = (props: Props) => {
  const { attachments, onImageClick, url: imageUrl } = props;

  const attachmentUrl = useMemo(() => {
    if (attachments) {
      const imageAttachments = getSupportedImageAttachments(attachments);
      return imageAttachments.length ? imageAttachments[0].url : undefined;
    }
    return undefined;
  }, [attachments]);

  const previewUrl = imageUrl || attachmentUrl;
  const isClickable = useMemo(() => Boolean(onImageClick), [onImageClick]);
  const classes = `h-[50px] w-[50px] object-contain ${isClickable ? 'cursor-pointer' : ''}`;

  if (!previewUrl) {
    return null;
  }

  return (
    <div className='h-full flex items-center'>
      <img
        alt=""
        className={classes}
        src={previewUrl}
        onClick={(event) => {
          event.preventDefault();
          onImageClick?.(previewUrl);
        }}
      />
    </div>
  );
};

export default TableImageView;
