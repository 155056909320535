import React, { useMemo } from 'react';
import { adaptNodeEdgeToNode } from 'app/components/AsyncMultiSelect/utils';
import useSearchSuggestion from 'app/modules/assetModels/hook/useSearchSuggestion';
import EntityManager from 'app/modules/components/EntityManager';

import { ItemTypeEnum } from '../../../../../../types/schema';
import Stack from '../../../../../../ui-components/Stack';
import useMultiSelectEntityManager from '../../../../../components/EntityManager/useMultiSelectEntityManager';
import ImageViewDialog from '../../../../../components/ImageViewDialog';
import useImageViewDialog from '../../../../../components/ImageViewDialog/hook/useImageViewDialog';
import { useAssetModelTableQuery } from '../../graphql/query/generated/assetModelTable';
import { getAssetModelTableColumns } from './utils';
import { Box, InputAdornment } from '@mui/material';
import { CustomIcons } from '@procurenetworks/procure-component-library';
import useOffsetPagination from '../../../../../../hooks/useOffsetPagination';

interface Props {
  onEdit: (id: string[]) => void;
  onDelete: (id: string[]) => void;
  fetching?: boolean;
}

const AssetModelTable = (props: Props) => {
  const { onEdit, onDelete, fetching } = props;
  const { state: imageViewState, actions: imageViewActions } = useImageViewDialog();

  const { table, search } = useMultiSelectEntityManager({
    selection: true,
    onEdit,
    onDelete,
  });

  const {
    onNextPage,
    onPrevPage,
    onReset,
    fetching: isFetchingAssetModel,
    data,
  } = useOffsetPagination(
    useAssetModelTableQuery,
    {
      filters: {
        search: search.debouncedSearchText,
        types: [ItemTypeEnum.AssetModel],
      },
      sorts: table.state.sorts,
    },
    {
      edgeKey: 'assetModelItems',
      pageSize: table.state.numberOfRowsPerPage,
    },
  );

  const rows = useMemo(() => {
    return data?.assetModelItems?.edges?.map(adaptNodeEdgeToNode) || [];
  }, [data?.assetModelItems?.edges]);

  const { searchSuggestions, setSearchValue } = useSearchSuggestion();

  return (
    <Box 
      sx={{boxShadow:'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',}}
      className='pb-[24px] rounded-[12px] mt-[24px] border border-grey-300'
    >
      <EntityManager.MultiSelectTable
        ignoreRelayPagination
        pagination
        persistSelectionData
        actions={table.actions}
        columns={getAssetModelTableColumns()}
        data={rows}
        extraProps={{
          imageViewActions,
        }}
        loading={isFetchingAssetModel || fetching}
        persistKey="asset_model_table"
        setState={table.setState}
        state={table.state}
        total={data?.assetModelItems?.totalCount || 0}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        onReset={onReset}
        filterNodes={
          <Box className="flex gap-[16px] !w-[100%] md:flex-wrap md:gap-[24px]">
        <EntityManager.Search
          isDropdown
          suggestionData={searchSuggestions || []}
          onChangeSearch={setSearchValue}
          {...search}
          autoFocus
          placeholder='Search'
          startAdornment={
            <InputAdornment position="start" className='mt-[4px] ml-[5px]'>
            <CustomIcons.SearchIcon/>
           </InputAdornment>
          }
        />
      </Box>
        }
        paginationWrapperClass='p-[24px] flex-wrap md:gap-[24px]'
      />

      <ImageViewDialog
        id="asset-model-image-dialog"
        imageUrl={imageViewState.imageUrl}
        open={imageViewState.open}
        onClose={imageViewActions.onClose}
      />
    </Box>
  );
};

export default AssetModelTable;
