import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type NewAuditItemLocationsSearchSuggestionQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.ItemFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  page?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type NewAuditItemLocationsSearchSuggestionQuery = { __typename?: 'Query', items: { __typename?: 'ItemConnection', edges: Array<{ __typename?: 'ItemEdge', node: { __typename?: 'AssetItemSchema', title: string, sku: string, id: string } | { __typename?: 'AssetKitItemSchema', title: string, sku: string, id: string } | { __typename?: 'AssetModelItemSchema' } | { __typename?: 'InventoryItemSchema', title: string, sku: string, id: string } | { __typename?: 'InventoryKitItemSchema', title: string, sku: string, id: string } }> } };


export const NewAuditItemLocationsSearchSuggestionDocument = gql`
    query newAuditItemLocationsSearchSuggestion($filters: ItemFilters, $sorts: [SortProps!], $limit: Float, $page: Float) {
  items(filters: $filters, sorts: $sorts, limit: $limit, page: $page) {
    edges {
      node {
        ... on AssetItemSchema {
          title
          sku
          id
        }
        ... on AssetKitItemSchema {
          title
          sku
          id
        }
        ... on InventoryItemSchema {
          title
          sku
          id
        }
        ... on InventoryKitItemSchema {
          title
          sku
          id
        }
      }
    }
  }
}
    `;

export function useNewAuditItemLocationsSearchSuggestionQuery(options?: Omit<Urql.UseQueryArgs<NewAuditItemLocationsSearchSuggestionQueryVariables>, 'query'>) {
  return Urql.useQuery<NewAuditItemLocationsSearchSuggestionQuery>({ query: NewAuditItemLocationsSearchSuggestionDocument, ...options });
};