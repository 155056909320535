import React from 'react';
import { CustomIcons, TextField } from '@procurenetworks/procure-component-library';
import Orders from 'app/i18n/Orders';
import FormLabel from 'app/components/ProcureForm/FormLabel';

interface Props {
  inputParams: any;
  getInputReference?: (el: HTMLElement) => void;
  onSearchIconClick?: () => void;
  autoFocus?: boolean;
  className?: string;
  size?: 'small' | 'medium';
  label?: string;
  placeholder?: string;
  onFocus?: () => void;
  onChange?: (
    event: React.ChangeEventHandler<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>,
  ) => void;
  formLabel?:string;
  isRequired?:boolean
  name?: string;
}

const OrderItemInStockInput = (props: Props) => {
  const {
    getInputReference,
    inputParams,
    onSearchIconClick,
    autoFocus,
    className,
    size,
    label,
    placeholder,
    onChange,
    onFocus,
    formLabel,
    isRequired,
    name
  } = props;

  return (<>
    {formLabel ? (
      <FormLabel className="text-[14px] font-medium text-grey-800" isRequired={isRequired}>
        {formLabel}
      </FormLabel>
    ) : null}
    <div
      className={`order-item-in-stock-input flex mt-6 gap-x-[8px] ${className || ''}`}
      {...inputParams}>
      <div className="grow" {...inputParams}>
        <TextField
          name={name}
          {...inputParams}
          className='flex'
          autoFocus={autoFocus}
          getInputReference={getInputReference}
          label={label}
          placeholder={placeholder}
          size={size || 'medium'}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={(event: any) => {
            event.stopPropagation();
          }}
          InputLabelProps={{
            shrink: true,
          }}
          
        />
      </div>

      {onSearchIconClick ? (
        <div className="flex-none rounded-lg border-[1px] border-solid border-grey-500">
          <div
            className={`item-in-stock-search-wrapper border-none! rounded-lg flex h-[100%] w-[100%] items-center justify-center  pl-[17px] pr-[17px]`}
            aria-disabled={inputParams.disabled}
            onClick={onSearchIconClick}>
            <CustomIcons.SearchIcon/>
          </div>
        </div>
      ) : undefined}
    </div>
    </>);
};

export default OrderItemInStockInput;
