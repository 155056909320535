import { useMemo } from 'react';
import FormTextInput from 'app/components/Form/FormTextInput';
import Receivables from 'app/i18n/Receivables';

const ReceiveQuantity = ({ row, state }: any) => {
  const { entities, setEntities } = state.extraProps || {};

  const value = entities[row.id]?.quantityReceived;
  const siteIdValue = entities[row.id]?.siteId;
  const locationIdValue = entities[row.id]?.locationId;
  const notes = entities[row.id]?.notes;
  const hasError = useMemo(() => {
    return (siteIdValue || locationIdValue || notes) && !value;
  }, [value, siteIdValue, locationIdValue, notes]);

  const onQuantityChange = (quantity: string) => {
    const qty = parseInt(quantity.replace(/[^\d]/gi, ''));
    if (quantity === '' || (!isNaN(qty) && qty > 0 && qty <= row.quantityPending)) {
      setEntities({
        ...entities,
        [row.id]: {
          quantityReceived: quantity === '' ? '' : qty,
          itemId: row.itemId || row.id,
          receivableId: row.id,
          siteId: siteIdValue || '',
          locationId: locationIdValue || '',
          notes: notes || '',
        },
      });
    }
  };

  // Preventing user to add any alphabets
  const handleKeyDown = (e: KeyboardEvent) => {
    if (
      !(
        /[0-9]{1}/.test(e.key) || ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(e.key)
      )
    ) {
      e.preventDefault();
    }
  };

  const error = hasError
    ? { message: Receivables.FormValidationMessages.QuantityInvalid }
    : undefined;

  return (
    <FormTextInput
      className="w-full h-full flex items-center justify-center"
      error={error}
      inputControlClassName="!max-h-[36px] !bg-[white] max-w-[180px]"
      errorClassName='leading-[initial]'
      size={'small'}
      textAlign="right"
      type="number"
      value={value ?? ''}
      onChange={onQuantityChange}
      onKeyDown={handleKeyDown}
    />
  );
};
export default ReceiveQuantity;
