import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InventorySearchSuggestionQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.ItemFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  page?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type InventorySearchSuggestionQuery = { __typename?: 'Query', items: { __typename?: 'ItemConnection', edges: Array<{ __typename?: 'ItemEdge', node: { __typename?: 'AssetItemSchema' } | { __typename?: 'AssetKitItemSchema' } | { __typename?: 'AssetModelItemSchema' } | { __typename?: 'InventoryItemSchema', id: string, title: string, description?: string | null, sku: string } | { __typename?: 'InventoryKitItemSchema', id: string, title: string, description?: string | null, sku: string } }> } };


export const InventorySearchSuggestionDocument = gql`
    query inventorySearchSuggestion($filters: ItemFilters, $sorts: [SortProps!], $limit: Float, $page: Float) {
  items(filters: $filters, sorts: $sorts, limit: $limit, page: $page) {
    edges {
      node {
        ... on InventoryItemSchema {
          id
          title
          description
          sku
        }
        ... on InventoryKitItemSchema {
          id
          title
          description
          sku
        }
      }
    }
  }
}
    `;

export function useInventorySearchSuggestionQuery(options?: Omit<Urql.UseQueryArgs<InventorySearchSuggestionQueryVariables>, 'query'>) {
  return Urql.useQuery<InventorySearchSuggestionQuery>({ query: InventorySearchSuggestionDocument, ...options });
};