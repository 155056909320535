import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseEntityManagerReturnType } from 'app/modules/components/EntityManager/useEntityManager';

import useFindUPC from '../context/useFindUPC';
import { useFindUpcQuery } from '../graphql/query/generated/comparisonShopProductsForFindUPC';
import { ComparisonShopProductMerchantEnum, ItemExternalProductCodeTypeEnum } from 'app/types/schema';

interface State {
  products: SearchProduct[];
  loading: boolean;
  totalCount: number;
  selectedRowId?: string;
  productDetailModal: {
    open: boolean;
    selectedProduct?: SearchProduct;
  };
}

interface SearchProduct {
  id: string;
  name: string;
  modelName: string;
  manufacturer: string;
  gtin: string;
  asin: string;
  upc: string;
  productCode: string;
  productCodeType: string;
  description: string;
  images: string[];
}

const parseProductNodes = (data: any): SearchProduct[] =>
  data?.comparisonShopProductsForFindUPC?.map((product: any, index: number) => {
    const asin =
      product.merchant === ComparisonShopProductMerchantEnum.Amazon
        ? product.merchantSKU
        : product.productCodeType === ItemExternalProductCodeTypeEnum.Asin
        ? product.productCode
        : undefined;
    
    return {
      id: `${product.upc}-${index}`,
      name: product.title,
      modelName: product.model || '',
      manufacturer: product.manufacturer || '',
      productCode: product.productCode || '',
      productCodeType: product.productCodeType || '',
      description: product.shortDescription || product.longDescription || '',
      images: product.images?.thumbnails || [],
      asin: product.asin || asin || '',
      upc: product.upc,
      gtin: product.gtin || '',
    };
  }) || [];

const useFindUpcState = () => {
  const [state, _setState] = useState<State>({
    loading: false,
    products: [],
    totalCount: 0,
    selectedRowId: undefined,
    productDetailModal: {
      open: false,
      selectedProduct: undefined,
    },
  });

  const { search, table } = useFindUPC() as UseEntityManagerReturnType;

  const variables = {
    search: search.debouncedSearchText || '',
    asins: [search.debouncedSearchText || ''],
    isbns: [search.debouncedSearchText || ''],
    upcs: [search.debouncedSearchText || ''],
  };

  const [{ data, fetching, error }] = useFindUpcQuery({
    variables,
    pause: !search.debouncedSearchText,
  });

  useEffect(() => {
    if (fetching) {
      setState({ loading: true });
    } else if (error) {
      setState({ loading: false });
    } else {
      const parsedData = parseProductNodes(data);
      setState({
        products: parsedData,
        loading: false,
        totalCount: data?.comparisonShopProductsForFindUPC?.length || 0,
      });
    }
  }, [data, fetching, error]);

  const setState = useCallback((nextState: Partial<State>) => {
    _setState((prevState) => ({ ...prevState, ...nextState }));
  }, []);

  const currentPageProducts = useMemo(() => {
    return (state.products || []).slice(
      table.state.activePage * table.state.numberOfRowsPerPage,
      (table.state.activePage + 1) * table.state.numberOfRowsPerPage
    );
  }, [state.products, table.state.activePage, table.state.numberOfRowsPerPage]);

  const onProductCodeClick = useCallback(
    (productData: SearchProduct) => {
      setState({
        selectedRowId: productData.id,
        productDetailModal: {
          open: true,
          selectedProduct: productData,
        },
      });
    },
    [setState]
  );

  return {
    state: { ...state, products: currentPageProducts },
    loading: fetching || state.loading,
    setState,
    search,
    table,
    onProductCodeClick,
  };
};

export type UseFindUpcStateReturnType = ReturnType<typeof useFindUpcState>;
export default useFindUpcState;