import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SkuSelectQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.ItemFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  page?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type SkuSelectQuery = { __typename?: 'Query', items: { __typename?: 'ItemConnection', totalCount: number, edges: Array<{ __typename?: 'ItemEdge', node: { __typename?: 'AssetItemSchema', id: string, sku: string } | { __typename?: 'AssetKitItemSchema', id: string, sku: string } | { __typename?: 'AssetModelItemSchema' } | { __typename?: 'InventoryItemSchema', id: string, sku: string } | { __typename?: 'InventoryKitItemSchema', id: string, sku: string } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const SkuSelectDocument = gql`
    query skuSelect($filters: ItemFilters, $sorts: [SortProps!], $limit: Float, $page: Float) {
  items(filters: $filters, sorts: $sorts, limit: $limit, page: $page) {
    edges {
      node {
        ... on AssetItemSchema {
          id
          sku
        }
        ... on AssetKitItemSchema {
          id
          sku
        }
        ... on InventoryItemSchema {
          id
          sku
        }
        ... on InventoryKitItemSchema {
          id
          sku
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    totalCount
  }
}
    `;

export function useSkuSelectQuery(options?: Omit<Urql.UseQueryArgs<SkuSelectQueryVariables>, 'query'>) {
  return Urql.useQuery<SkuSelectQuery>({ query: SkuSelectDocument, ...options });
};