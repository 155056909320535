import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { AssetModelDataFragmentDoc } from '../../../../views/EditAssetModel/graphql/fragments/generated/assetModelData';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FormAssetModelSelectQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.AssetModelItemFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  page?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type FormAssetModelSelectQuery = { __typename?: 'Query', assetModelItems: { __typename?: 'AssetModelItemConnection', totalCount: number, edges: Array<{ __typename?: 'AssetModelItemEdge', node: { __typename?: 'AssetModelItemSchema', id: string, sku: string, title: string, mName: string, modelNumber?: string | null, description?: string | null, formattedDescription?: string | null, brand?: string | null, manufacturerId?: string | null, unitCost?: number | null, costOverride?: number | null, pickableThroughOrderRequest?: boolean | null, externalProductCodes?: Array<{ __typename?: 'ItemExternalProductCodeSchema', type: Types.ItemExternalProductCodeTypeEnum, code: string }> | null, category?: { __typename?: 'CategorySchema', id: string, status: Types.CategoryStatusEnum } | null, vendor?: { __typename?: 'CompanySchema', id: string, status: Types.CompanyStatusEnum } | null, attachments?: Array<{ __typename?: 'MediaSchema', size?: number | null, url?: string | null, mimeType?: string | null, kind: Types.MediaKindEnum, resolutions: Array<{ __typename?: 'ResolutionType', type: Types.ResolutionEnum, url: string }> }> | null } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const FormAssetModelSelectDocument = gql`
    query formAssetModelSelect($filters: AssetModelItemFilters, $sorts: [SortProps!], $limit: Float, $page: Float) {
  assetModelItems(filters: $filters, sorts: $sorts, limit: $limit, page: $page) {
    edges {
      node {
        ...AssetModelData
      }
    }
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    totalCount
  }
}
    ${AssetModelDataFragmentDoc}`;

export function useFormAssetModelSelectQuery(options?: Omit<Urql.UseQueryArgs<FormAssetModelSelectQueryVariables>, 'query'>) {
  return Urql.useQuery<FormAssetModelSelectQuery>({ query: FormAssetModelSelectDocument, ...options });
};