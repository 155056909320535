import { AssetModelItemSchema, ItemExternalProductCodeTypeEnum, ItemTypeEnum } from '../../../../../../types/schema';
import { format } from '../../../../../../utils/cost';
import { isCategoryActive } from '../../../../../categories/utils/utils';
import { isCompanyActive } from '../../../../../contacts/utils/utils';
import { AssetModelItemFormInput } from '../../../../components/AssetModelForm/type';

export const prepareFormDataFromAssetModel = (
  item: AssetModelItemSchema,
): AssetModelItemFormInput => {
  const {
    externalProductCodes,
    title,
    mName,
    modelNumber,
    category,
    vendor,
    description,
    formattedDescription,
    brand,
    manufacturerId,
    unitCost,
    costOverride,
    pickableThroughOrderRequest,
    attachments,
    id,
    sku,
  } = item;
  const externalUpcCodeData = externalProductCodes?.[0] || undefined;
  const upcCode = externalProductCodes?.find(item => item.type === ItemExternalProductCodeTypeEnum.Upc)?.code;
  const asinCode = externalProductCodes?.find(item => item.type === ItemExternalProductCodeTypeEnum.Asin)?.code;

  return {
    id,
    sku,
    noUpcCode: Boolean(!externalUpcCodeData?.code),
    upcCode: upcCode || '',
    asinCode: asinCode || '',
    productCodeInput: '',
    gtinCode: '',
    title,
    mName,
    modelNumber: modelNumber || '',
    categoryId: category?.id && isCategoryActive(category?.status) ? category.id : '',
    description: formattedDescription || description || '',
    vendorId: vendor?.id && isCompanyActive(vendor?.status) ? vendor?.id : '',
    brand: brand || '',
    manufacturerId: manufacturerId || '',
    unitCost: unitCost === null || unitCost === undefined ? '0.00' : format(unitCost),
    costOverride: costOverride === null || costOverride === undefined ? '0' : costOverride,
    pickableThroughOrderRequest: pickableThroughOrderRequest || false,
    attachments: attachments || [],
    type: ItemTypeEnum.AssetModel,
  };
};
