import { Box as MuiBox } from '@mui/material';
import clsx from 'clsx';
import FormTextArea from 'app/components/Form/FormTextArea';
import FormTextInput from 'app/components/Form/FormTextInput';
import Category from 'app/i18n/Category';
import Inventory from 'app/i18n/Inventory';
import Reports from 'app/i18n/Reports';
import FormCategorySelect from 'app/modules/categories/components/FormCategorySelect';
import { useStyle } from 'app/modules/inventory/components/useStyle';
import { getLocationName } from 'app/modules/locations/utils/location';
import ReportSKULink from 'app/modules/reports/components/ReportSKULink';
import SitesTableFilter from 'app/modules/sites/components/SitesTableFilter';
import { DataGridHead } from 'app/types/dataGrid';
import { LocationTypeEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import { FilterState } from './types';

const stopPropagation: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
  event.stopPropagation();
};

const CategoryFilterLabelNode = (props: any) => {
  const { state } = props;
  const { filterState, setFilterState } =
    (state.extraProps as {
      siteId: string;
      filterState: FilterState;
      setFilterState: (nextFilterState: Partial<FilterState>) => void;
    }) || {};

  const classes = useStyle();
  return (
    <div className="table-filter">
      <FormCategorySelect
        className={clsx(classes.root)}
        placeholder={Inventory.FormPlaceholders.Category}
        selectInputClass="flex"
        size="small"
        value={filterState.categoryId}
        onChange={(value) => {
          setFilterState({
            categoryId: value,
          });
        }}
      />
    </div>
  );
};

export const getNewAuditTableColumns = (): DataGridHead[] => [
  {
    id: 'sku',
    label: Reports.SKU,
    value: 'sku',
    valueNode: ({ row }) => <ReportSKULink item={row.item} />,
  },
  {
    id: 'title',
    label: Reports.Title,
    value: 'title',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.item?.title}>
        {row.item?.title}
      </span>
    ),
  },
  {
    id: 'category',
    label: Category.Category,
    labelNode: CategoryFilterLabelNode,
    value: 'category',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.item?.category?.name}>
        {row.item?.category?.name}
      </span>
    ),
  },
  {
    id: 'site',
    label: Reports.Site,
    value: 'site',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.site?.name}>
        {row.site?.name}
      </span>
    ),
    sortable: true,
    sortField: 'expandedSortFields.siteName',
  },
  {
    id: 'location',
    label: Reports.Location,
    labelNode: (props) => {
      const { state } = props;
      const { siteId, filterState, setFilterState } =
        (state.extraProps as {
          siteId: string;
          filterState: FilterState;
          setFilterState: (nextFilterState: Partial<FilterState>) => void;
        }) || {};
      return (
        <div className="w-[90%]">
          <SitesTableFilter
            name="location"
            placeholder="Location"
            queryVariables={{
              types: [LocationTypeEnum.Location],
              siteIds: siteId ? [siteId] : [],
            }}
            value={filterState.locationIds}
            onChange={(nextLocationIds: string[]) => {
              setFilterState({
                locationIds: nextLocationIds,
              });
            }}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          />
        </div>
      );
    },
    value: 'location',
    valueNode: ({ row }) => (
      <span className="truncate" title={getLocationName(row.site?.id, row.location)}>
        {getLocationName(row.site?.id, row.location)}
      </span>
    ),
    sortable: true,
    sortField: 'expandedSortFields.locationName',
    width: 220,
  },
  {
    id: 'totalQuantity',
    label: Reports.Quantity,
    value: 'totalQuantity',
    rowAlign: 'right',
  },
  {
    id: 'actual',
    label: 'Actual',
    value: 'actual',
    valueNode: ({ row, state }) => {
      const { entities, setEntities } = state.extraProps || {};
      const value = entities[row.id]?.quantity;

      const isSerialNumberExist = Boolean(row?.item?.serialNumber);

      const onQuantityChange = (quantity: string) => {
        const qty = Number(quantity);
        if (qty > -1 && quantity.length <= 9 && (isSerialNumberExist ? qty <= 1 : true)) {
          setEntities({
            ...entities,
            [row.id]: {
              ...entities[row.id],
              quantity: quantity === '' ? '' : qty,
              entityType: row.item.type,
              entityId: row.item.id,
              destinationSiteId: row.site.id,
              destinationLocationId: row.location.id,
            },
          });
        }
      };

      return (
        <FormTextInput
          className="h-full"
          containerClassName='h-full !mt-0'
          textAlign="right"
          type="number"
          value={value ?? ''}
          onChange={onQuantityChange}
        />
      );
    },
  },
  {
    id: 'variance',
    label: 'Variance',
    value: 'variance',
    rowAlign: 'right',
    valueNode: ({ row, state }) => {
      const inputQuantity = state?.extraProps?.entities[row.id]?.quantity;
      const isEdited = typeof inputQuantity === 'number';

      if (!isEdited) {
        return null;
      }

      const variance = inputQuantity - row.totalQuantity;
      const isNegative = variance < 0;

      return <Box className={isNegative ? 'red' : 'green'}>{Math.abs(variance)}</Box>;
    },
  },
  {
    id: 'notes',
    label: 'Notes',
    value: 'notes',
    valueNode: ({ row, state }) => {
      const { entities, setEntities } = state.extraProps || {};
      const value = entities[row.id]?.meta?.note;

      const onChange = (note: string) => {
        setEntities({
          ...entities,
          [row.id]: {
            ...entities[row.id],
            meta: { note },
            entityId: row.item.id,
            entityType: row.item.type,
            destinationSiteId: row.site.id,
            destinationLocationId: row.location.id,
          },
        });
      };

      return (
        <MuiBox className="flex h-[80px] w-full items-center justify-center overflow-x-hidden overflow-y-scroll py-[10px]">
          <Box onKeyDown={stopPropagation}>
            <FormTextArea
              characterLimit={30}
              className="-mt-6 w-[155px]"
              defaultTextAreaHeight="30px"
              rows={2}
              value={value ?? ''}
              onChange={onChange}
            />
          </Box>
        </MuiBox>
      );
    },
    width: 250,
  },
];
