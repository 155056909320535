import SiteSelector from 'app/modules/receiving/components/SiteSelector';

const ReceiveSiteValueNode = ({ row, state }: any) => {
  const { entities, setEntities, onSiteControlScroll } = state.extraProps || {};

  const value = entities[row.id]?.siteId;
  const quantityValue = entities[row.id]?.quantityReceived;
  const locationIdValue = entities[row.id]?.locationId;
  const notes = entities[row.id]?.notes;

  const handleChange = (value: any) => {
    setEntities({
      ...entities,
      [row.id]: {
        receivableId: row.id,
        itemId: row.itemId || row.id,
        siteId: value || '',
        quantityReceived: quantityValue || '',
        locationId: locationIdValue || '',
        notes: notes || '',
      },
    });
  };

  return (
    <>
      <SiteSelector className="w-full h-full flex items-center justify-center" disabled={!quantityValue} size="small" value={value} onChange={handleChange} />
    </>
  );
};

export default ReceiveSiteValueNode;
