import React, { useEffect, useState } from 'react';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import { Content } from '@procurenetworks/procure-component-library';
import Loader from 'app/components/Loader';
import Network from 'app/i18n/Network';

import { AllowedPermissionsSubjectEnum } from '../../../../types/schema';
import EntityManager from '../../../components/EntityManager';
import { useDistinctNetworkAssetsQuery } from '../../graphql/queries/generated/networkAssetDistinctValues';
import { useGetAllNetworkAssetWarrantyQuery } from '../../graphql/queries/generated/networkAssetWarrantyType';
import { warrantyDetails } from '../../types';
import { arraySort, defaultGridColumn } from '../../utils';
import BarGraph from './components/BarGraph';
import ColumnGraph from './components/ColumnGraph';
import DetailsModal from './components/DetailsModal';
import DonutGraph from './components/DonutGraph';
import ManageInsightsModal from './components/ManageInsightsModal';
import { detailModelType } from './types';

const defaultValue = {
  details: [],
  title: '',
  subHeading: '',
  visible: true,
};

const defaultValueWarranty = {
  color: ['var(--red800)', 'var(--orange900)', 'var(--green900)', 'var(--blue800)'],
  labels: [
    { value: 0, label: 'Warranty Expired' },
    { value: 0, label: 'Warranty Expiring Soon' },
    { value: 0, label: 'Under Warranty' },
    { value: 0, label: 'No Warranty' },
  ],
};

const NetworkAssetInsights = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenManageInsight, setIsOpenManageInsight] = useState(false);
  const [detailData, setDetailData] = useState<detailModelType>(defaultValue);

  const [dataComboDetail, setDataComboDetail] = useState<detailModelType[]>([defaultValue]);

  const [{ fetching, data }] = useDistinctNetworkAssetsQuery({
    variables: {
      filters: {
        values: ['cpuManufacturer', 'operatingSystem', 'cpuBrand', 'motherboardModel'],
      },
    },
  });
  const [{ data: networkAssetWarrantyTypeData }] = useGetAllNetworkAssetWarrantyQuery();
  const [warrantyState, setWarrantyState] = useState<warrantyDetails>(defaultValueWarranty);
  const [totalAsset, settotalAsset] = useState(0);

  useEffect(() => {
    const networkAssetWarranty =
      networkAssetWarrantyTypeData?.getAllNetworkAssetWarranty.totalAssetsWarranty;
    const warranties = networkAssetWarranty?.length
      ? networkAssetWarranty.map((warranty) => {
          return {
            value: warranty.totalQuantity,
            label: warranty.type,
            warranty: {
              color: ['var(--red900)', 'var(--orange900)', 'var(--green900)', 'var(--blue900)'],
              labels: [
                { value: warranty.expired.length || 0, label: 'Warranty Expired' },
                { value: warranty.expiringSoon.length || 0, label: 'Warranty Expiring Soon' },
                { value: warranty.inWarranty.length || 0, label: 'Under Warranty' },
                { value: warranty.noWarranty.length || 0, label: 'No Warranty' },
              ],
            },
          };
        })
      : [];

    const expiredCount = warranties.reduce(
      (acc, curr) => acc + (curr.warranty.labels[0].value || 0),
      0,
    );
    const expiringSoonCount = warranties.reduce(
      (acc, curr) => acc + (curr.warranty.labels[1].value || 0),
      0,
    );
    const underWarrantyCount = warranties.reduce(
      (acc, curr) => acc + (curr.warranty.labels[2].value || 0),
      0,
    );
    const noWarrantyCount = warranties.reduce(
      (acc, curr) => acc + (curr.warranty.labels[3].value || 0),
      0,
    );

    const totalWarranty = {
      color: ['var(--red800)', 'var(--orange900)', 'var(--green900)', 'var(--blue800)'],
      labels: [
        { value: expiredCount, label: 'Warranty Expired' },
        { value: expiringSoonCount, label: 'Warranty Expiring Soon' },
        { value: underWarrantyCount, label: 'Under Warranty' },
        { value: noWarrantyCount, label: 'No Warranty' },
      ],
    };

    setWarrantyState(totalWarranty);

    const totalQuantity = warranties.reduce((acc, curr) => acc + curr.value, 0);
    settotalAsset(totalQuantity);
  }, [networkAssetWarrantyTypeData]);

  useEffect(() => {
    const operatingSystem = data?.distinctNetworkAssets.distinctValues.operatingSystem;
    const operatingSystemSorted = operatingSystem?.length ? arraySort(operatingSystem) : [];
    const operatingSystemData =
      operatingSystemSorted?.length &&
      operatingSystemSorted.map((os) => {
        const value = os.value;
        return {
          value: os.count,
          label: value === null ? 'Other' : value || 'Not Available',
        };
      });

    const motherboard = data?.distinctNetworkAssets.distinctValues.motherboardModel;
    const motherboardSorted = motherboard?.length ? arraySort(motherboard) : [];
    const motherboardData =
      motherboardSorted?.length &&
      motherboardSorted.map((item) => {
        const value = item.value;
        return {
          value: item.count,
          label:
            value === null
              ? 'Other'
              : !!value && value.includes(';')
              ? value.split(';')[0]
              : value || 'Not Available',
        };
      });

    const cpuManufacturer = data?.distinctNetworkAssets.distinctValues.cpuManufacturer;
    const cpuManufacturerSorted = cpuManufacturer?.length ? arraySort(cpuManufacturer) : [];
    const cpuManufacturerData =
      cpuManufacturerSorted?.length &&
      cpuManufacturerSorted.map((item) => {
        const value = item.value;
        return {
          value: item.count,
          label: value === null ? 'Other' : value || 'Not Available',
        };
      });

    const cpuBrand = data?.distinctNetworkAssets.distinctValues.cpuBrand;
    const cpuBrandSorted = cpuBrand?.length ? arraySort(cpuBrand) : [];
    const cpuBrandData =
      cpuBrandSorted?.length &&
      cpuBrandSorted.map((item) => {
        const value = item.value;
        return {
          value: item.count,
          label: value === null ? 'Other' : value || 'Not Available',
        };
      });

    const response = [
      {
        details: operatingSystemData || [],
        title: 'Operating System',
        subHeading: 'operatingSystem',
        visible: true,
      },
      {
        details: motherboardData || [],
        title: 'Motherboard',
        subHeading: 'motherboardModel',
        visible: true,
      },
      {
        details: cpuManufacturerData || [],
        title: 'CPU Manufacturer',
        subHeading: 'cpuManufacturer',
        visible: true,
      },
      {
        details: cpuBrandData || [],
        title: 'CPU Brand',
        subHeading: 'cpuBrand',
        visible: true,
      },
      // {
      //   details: totalAsset ? [{ label: 'No Location', value: totalAsset }] : [],
      //   title: 'Location',
      //   subHeading: '',
      //   visible: true,
      // },
    ];
    setDataComboDetail(response);
  }, [data, totalAsset]);

  const onCancel = () => {
    setIsOpen(false);
  };

  const visible = dataComboDetail.find((value) => value.visible);

  return (
    <EntityManager className="pt-0" subject={AllowedPermissionsSubjectEnum.Asset}>
      <EntityManager.Title
        hasTopDivider
        documentTitle={Network.NetworkAssetInsights}
        title={Network.NetworkAssetInsights}
      />
      <Content>
        {fetching ? (
          <Loader />
        ) : (
          <>
            <Box className="border-grey300 rounded-[12px]" sx={{ flexGrow: 1, marginTop: '24px' }}>
              <Grid container columns={defaultGridColumn} spacing={{ xs: 2, sm: 2, lg: 2 }}>
                <Grid
                  item
                  className="border-grey300 rounded-[12px] border"
                  lg={4}
                  md={8}
                  sm={8}
                  sx={{
                    boxShadow:
                      '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10) !important',
                  }}
                  xl={3}
                  xs={12}>
                  <DonutGraph
                    chartData={[{ value: totalAsset, label: 'Active' }]}
                    subheader=""
                    title={Network.AssetSummary}
                  />
                </Grid>
                <Grid item className="!p-0" lg={8} md={12} sm={12} xl={9} xs={12}>
                  <BarGraph comboData={dataComboDetail} subheader="" title="Details" />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ flexGrow: 1, marginTop: '24px' }}>
              {visible ? (
                <Typography className="mt-[24px] text-[18px] font-semibold text-grey-900">
                  Asset Insights
                </Typography>
              ) : null}
              <Divider className="mt-[16px] mb-[24px]" />
              <Grid container columns={defaultGridColumn} spacing={1}>
                {dataComboDetail.map((data) => {
                  return data.visible ? (
                    <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                      <DonutGraph
                        detailsButton
                        showLegend
                        border="border-[1px] border-grey-300 rounded-[12px]"
                        boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)"
                        chartData={data.details?.length ? data.details : []}
                        subheader={data.subHeading}
                        title={data.title}
                        onClickViewDetails={() => {
                          setDetailData(data);
                          setIsOpen(true);
                        }}
                      />
                    </Grid>
                  ) : null;
                })}
              </Grid>
            </Box>
            <Box sx={{ flexGrow: 1, marginTop: '24px' }}>
              <Typography className="mt-[24px] text-[18px] font-semibold text-grey-900">
                Warranty Insights
              </Typography>
              <Divider className="mt-[16px] mb-[24px]" />
              <Grid container columns={defaultGridColumn} spacing={1}>
                <Grid item lg={9} md={6} sm={6} xl={9} xs={12}>
                  <ColumnGraph
                    showLegend
                    chartData={warrantyState.labels || []}
                    detailsButton={false}
                    subheader={''}
                    title={'Warranty'}
                    onClickViewDetails={() => {
                      setDetailData({
                        details: warrantyState.labels || [],
                        title: 'Warranty',
                        subHeading: '',
                        visible: true,
                      });
                      setIsOpen(true);
                    }}
                  />
                </Grid>
                <Grid item lg={3} md={6} sm={6} xl={3} xs={12}>
                  <Card
                    className="border-grey300 rounded-[12px] border"
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    sx={{
                      boxShadow:
                        '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10) !important',
                    }}
                    onClick={() => setIsOpenManageInsight(true)}>
                    <div style={{ alignItems: 'center', textAlign: 'center' }}>
                      <Typography className="text-[18px] font-semibold text-grey-900">
                        Manage Insights
                      </Typography>
                      <Typography className="mt-[8px] text-[14px] text-blue-900 underline">
                        Click to manage insights
                      </Typography>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Content>
      <DetailsModal detailData={detailData} isOpen={isOpen} onCancel={onCancel} />
      <ManageInsightsModal
        dataComboDetail={dataComboDetail}
        isOpen={isOpenManageInsight}
        setDataComboDetail={setDataComboDetail}
        onCancel={() => setIsOpenManageInsight(false)}
      />
    </EntityManager>
  );
};

export default NetworkAssetInsights;
