import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import usePersistAttachmentsHelper from 'app/modules/components/FindUPC/components/ProductDetailFormModal/hook/usePersistUpcProductData';

import useFormFileUploader from '../../../../components/ProcureForm/FormFileUploader/hook/useFormFileUploader';
import {
  AssetItemSchema,
  CreateMediaInput,
  ItemTypeEnum,
  LocationSchema,
  MediaSchema,
} from '../../../../types/schema';
import { Option as AssetModelOption } from '../../../assetModels/components/FormAssetModelSelect';
import { FindUpcContextType } from '../../../components/FindUPC/context/FindUPCProvider';
import useFindUPC from '../../../components/FindUPC/context/useFindUPC';
import {
  createAddAssetItemReminderInput,
  createAssetItemInput,
  createAssetKitItemInput,
  createRestockTransactionInput,
  createUpdateAssetItemInput,
  createUpdateAssetKitItemInput,
} from '../../utils/dto';
import {
  parseAssetModelDataToAssetFormValues,
  parseProduceDataToAssetFormValues,
  prepareDefaultAssetItemAttachments,
} from './utils';

interface Props {
  defaultValues: any;
  defaultEditable?: boolean;
  isEditMode?: boolean;
  assetItem?: AssetItemSchema;
  isRemindersViewMode?: boolean;
}

const useAssetForm = (props: Props) => {
  const {
    defaultValues,
    defaultEditable = true,
    isEditMode,
    assetItem,
    isRemindersViewMode,
  } = props;

  const { totalQuantity } = assetItem || {};

  const [editable, setEditable] = useState<boolean>(defaultEditable);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [showKitSummary, setShowKitSummary] = useState(false);
  const [site, setSite] = useState<Partial<LocationSchema> | null>(null);
  const [fetchedProductCodes, setFetchedProductCodes] = useState({
    upc: false,
    asin: false,
    gtin: false,
    fetched: false,
  });

  useEffect(() => {
    setShowKitSummary((isEditMode && assetItem?.type === ItemTypeEnum.AssetKit) as boolean);
  }, [assetItem?.type, isEditMode]);

  const { control, watch, setValue, reset, getValues, handleSubmit, resetField, trigger } = useForm(
    {
      defaultValues: {
        ...defaultValues,
      },
    },
  );

  const itemType = watch('type');
  const productCodeInput = watch('productCodeInput');
  const upcCode = watch('upcCode');
  const asinCode = watch('asinCode');
  const noUpcCode = watch('noUpcCode');
  const destinationSiteId = watch('destinationSiteId');
  const destinationLocationId = watch('destinationLocationId');
  const showFindUpcSection = watch('findUpc');
  const serialNumber = watch('serialNumber');
  const purchaseDate = watch('purchaseDate');
  const warrantyExpiryDate = watch('warrantyExpiryDate');
  const replacementDate = watch('replacementDate');
  const attachments = watch('attachments');
  const quantity = watch('quantity');

  const formattedCost = useCallback(
    (cost: number | string) => (cost ? Number(cost)?.toFixed(2) : 0),
    [],
  );

  const updateUnitCost = useCallback(
    (cost: number) => {
      setValue('unitCost', formattedCost(cost));
    },
    [setValue],
  );

  const isItemTypeAsset = useMemo(() => {
    return itemType === ItemTypeEnum.Asset;
  }, [itemType]);
  const isItemTypeAssetKit = useMemo(() => {
    return itemType === ItemTypeEnum.AssetKit;
  }, [itemType]);

  const { search: findUpcCodeSearch } = useFindUPC() as FindUpcContextType;

  // Attachment
  const defaultAttachments = useMemo(
    () => prepareDefaultAssetItemAttachments(defaultValues?.attachments),
    [defaultValues?.attachments],
  );
  const {
    inputProps: attachmentInputProps,
    setAttachments: setAssetAttachment,
    clearAll,
    addFileFromUrl: addAssetAttachmentFromUrl,
  } = useFormFileUploader('attachments', defaultAttachments);
  const {
    parseNoUpcAssetAttachments,
    parseAssetModelAttachmentToAssetAttachments,
    parseProduceDataImagesToAssetAttachments,
  } = usePersistAttachmentsHelper(attachmentInputProps);

  const resetFormData = useCallback(() => {
    reset({
      ...props.defaultValues,
    });
    clearAll();
    setAssetAttachment(defaultAttachments);
  }, [reset, props.defaultValues, setAssetAttachment, defaultAttachments]);

  useEffect(() => {
    if (!editable) {
      resetFormData();
    }
  }, [editable, setAssetAttachment, defaultAttachments]);

  useEffect(() => {
    const _defaultValues = { ...defaultValues };
    delete _defaultValues?.type;
    reset({
      ...getValues(),
      ..._defaultValues,
    });
    clearAll();
    setAssetAttachment(defaultAttachments);
    if (!assetItem?.id) {
      setShowKitSummary(false);
    }
  }, [assetItem?.id, defaultAttachments, itemType]);

  // Protected Attachment
  const defaultProtectedAttachments = useMemo(() => {
    return (
      defaultValues?.protectedAttachments?.map((attachment: CreateMediaInput, index: number) => {
        return {
          id: `protected-attachment-${index}`,
          ...attachment,
          resolutions: attachment?.resolutions || [],
        };
      }) || []
    );
  }, [defaultValues?.protectedAttachments]);

  const {
    inputProps: protectedAttachmentsInputProps,
    setAttachments: setProtectedAssetAttachment,
  } = useFormFileUploader('protectedAttachments', defaultProtectedAttachments);

  const onFindItemClick = useCallback(() => {
    setValue('findUpc', true);
    findUpcCodeSearch?.onChange(productCodeInput);
  }, [setValue, productCodeInput, findUpcCodeSearch?.onChange]);

  const onSaveProductData = useCallback(
    (values: any) => {
      const productImages = values?.images || [];
      setFetchedProductCodes({
        upc: !!values.upcCode,
        asin: !!values.asinCode,
        gtin: !!values.gtinCode,
        fetched: true
      });
      if (!values.upcCode && !values.asinCode) {
        setValue("noUpcCode", true);
      }
      parseProduceDataImagesToAssetAttachments(productImages);
      reset({
        ...getValues(),
        ...parseProduceDataToAssetFormValues(values),
      });
    },
    [
      reset,
      getValues,
      attachments,
      addAssetAttachmentFromUrl,
      parseProduceDataImagesToAssetAttachments,
      setAssetAttachment,
    ],
  );

  const onCancelEditable = useCallback(() => {
    setEditable(false);
    reset({
      ...defaultValues,
    });
    setAssetAttachment(defaultAttachments);
    setProtectedAssetAttachment(defaultProtectedAttachments);
  }, [
    setEditable,
    reset,
    setAssetAttachment,
    setProtectedAssetAttachment,
    defaultAttachments,
    defaultProtectedAttachments,
  ]);

  const makeEditable = useCallback(() => {
    setEditable(true);
    if (assetItem?.type === ItemTypeEnum.AssetKit) {
      setShowKitSummary(true);
    }
  }, [setEditable]);

  const cancelEditable = useCallback(() => {
    setEditable(false);
  }, [setEditable]);

  useEffect(() => {
    setValue('destinationLocationId', '');
  }, [destinationSiteId]);

  useEffect(() => {
    if (
      purchaseDate &&
      warrantyExpiryDate &&
      purchaseDate > warrantyExpiryDate &&
      warrantyExpiryDate !== defaultValues.warrantyExpiryDate
    ) {
      setValue('warrantyExpiryDate', null);
    }
  }, [purchaseDate, warrantyExpiryDate]);

  useEffect(() => {
    if (
      purchaseDate &&
      replacementDate &&
      purchaseDate > replacementDate &&
      replacementDate !== defaultValues.replacementDate
    ) {
      setValue('replacementDate', null);
    }
  }, [purchaseDate, replacementDate]);

  const onNoUpcCodeChange = useCallback(
    (noUpcCodeValue: boolean) => {
      if (noUpcCodeValue) {
        setValue('findUpc', false);
        parseNoUpcAssetAttachments();
      
        if (!defaultValues.upcCode && !defaultValues.asinCode) {
          resetField('upcCode', { defaultValue: '' });
          resetField('asinCode', { defaultValue: '' });
        }
      }
    },
    [setValue, resetField, setAssetAttachment, parseNoUpcAssetAttachments],
  );

  const onCancelDelete = useCallback(() => {
    setShowDeleteConfirmation(false);
  }, [setShowDeleteConfirmation]);

  const onDeleteConfirmation = useCallback(() => {
    setShowDeleteConfirmation(true);
  }, [setShowDeleteConfirmation]);

  const onCancelKitSummary = useCallback(() => {
    setShowKitSummary(false);
    resetField('unitCost');
  }, [setShowKitSummary]);

  const onSaveKitData = useCallback(() => {
    setShowKitSummary(true);
  }, [setShowKitSummary]);

  const onAssetModelChange = useCallback(
    (assetModelData?: AssetModelOption) => {
      const assetModelAttachments = assetModelData?.attachments || ([] as MediaSchema[]);
      parseAssetModelAttachmentToAssetAttachments(assetModelAttachments);
      reset({
        ...getValues(),
        ...parseAssetModelDataToAssetFormValues(assetModelData),
      });
    },
    [getValues, reset, parseAssetModelAttachmentToAssetAttachments, setAssetAttachment],
  );
  
  useEffect(() => {
    if(!upcCode) {
      setValue('findUpc', false);
    }
  }, [upcCode]);

  useEffect(() => {
    if(!productCodeInput) {
      setValue('findUpc', false);
    }
  }, [productCodeInput]);

  useEffect(() => {
    if(!productCodeInput) {
      setValue('findUpc', false);
    }
  }, [productCodeInput]);

  return {
    formState: {
      control,
      destinationSiteId,
      destinationLocationId,
      isItemTypeAsset,
      isItemTypeAssetKit,
      attachmentInputProps,
      protectedAttachmentsInputProps,
      noUpcCode,
      onFindItemClick,
      showFindUpcSection: !!showFindUpcSection && !noUpcCode && productCodeInput,
      fetchedProductCodes,
      upcCode,
      asinCode,
      onSaveProductData,
      handleSubmit,
      editable,
      isEditMode,
      isRemindersViewMode,
      itemId: assetItem?.id,
      id: defaultValues?.id,
      onCancelEditable,
      makeEditable,
      cancelEditable,
      showDeleteConfirmation,
      onCancelDelete,
      onDeleteConfirmation,
      serialNumber,
      purchaseDate,
      totalQuantity,
      quantity,
      showKitSummary,
      onCancelKitSummary,
      onSaveKitData,
      getValues,
      onNoUpcCodeChange,
      onAssetModelChange,
      updateUnitCost,
      trigger,
      resetFormData,
      itemData: assetItem,
      site,
      setSite,
    },
    createAssetItemInput,
    createAssetKitItemInput,
    createUpdateAssetItemInput,
    createRestockTransactionInput,
    createUpdateAssetKitItemInput,
    createAddAssetItemReminderInput,
    onCancelEditable,
  };
};

export type UseAssetFormReturnType = ReturnType<typeof useAssetForm>;

export default useAssetForm;
