import { useMemo, useState } from 'react';
import { Table } from '@procurenetworks/procure-component-library';
import Loader from 'app/components/Loader';
import Box from 'app/ui-components/Box';
import { Box as MUiBox } from '@mui/material';
import { OrderRequestReportFormValues } from '../OrderRequestReportForm/types';
import { getOrderItemsTableColumns, getOrderRequestReportTableColumns } from './columns';

import usePagination from 'app/hooks/usePagination';
import { useOrderReportsQuery } from '../../graphql/generated/orderRequestReport';
import { AllowedPermissionsSubjectEnum, SortOrderEnum } from 'app/types/schema';
import useEntityManager from 'app/modules/components/EntityManager/useEntityManager';
import EntityManager from '../../../../../components/EntityManager';
import { getCurrentTimeZoneValue } from '../../../../../../utils/timezone';

interface Props {
  values: OrderRequestReportFormValues;
}

const OrderRequestReportTable = (props: Props) => {
  const { values } = props;

  const columns = getOrderRequestReportTableColumns();

  const OrderItemsTable = (props: any) => {
    const { row } = props;
    const { orderRequestItems = [] } = row;
    const { table } = useEntityManager({
      selection: false,
      numberOfRowsPerPage: orderRequestItems.length,
    });

    return (
      <div className="ml-[60px] mb-[20px] max-w-[1100px] mt-[16px]">
        <EntityManager.Table
          actions={table.actions}
          columns={getOrderItemsTableColumns()}
          data={row}
          minWidth={950}
          setState={table.setState}
          state={table.state}
          total={props?.row?.orderRequestItems?.length}
          tableBorder='none'
          tableBorderRadius='12px'
        />
      </div>
    );
  };

  const { table } = useEntityManager({
    selection: false,
  });

  const { initialFetching, fetching, data, onNextPage, onPrevPage } = usePagination(
    useOrderReportsQuery,
    {
      filters: {
        orderRequestIds: values.orderRequestIds,
        billToSiteIds: values.billToSiteIds,
        categoryIds: values.categoryIds,
        createdByIds: values.createdByIds,
        departmentIds: values.departmentIds,
        destinationSiteIds: values.destinationSiteIds,
        fulfillingSiteIds: values.fulfillingSiteIds,
        itemIds: values.itemIds,
        leastItemStatuses: values.orderItemStatuses,
        projectIds: values.projectIds,
        dueDate:
          values.orderRequestDueDateStart && values.orderRequestDueDateEnd
            ? {
                absolute: {
                  endTime: values.orderRequestDueDateEnd,
                  startTime: values.orderRequestDueDateStart,
                },
                timezone: getCurrentTimeZoneValue(),
              }
            : undefined,
        createdAt:
          values.orderRequestDateStart && values.orderRequestDateEnd
            ? {
                absolute: {
                  endTime: values.orderRequestDateEnd,
                  startTime: values.orderRequestDateStart,
                },
                timezone: getCurrentTimeZoneValue(),
              }
            : undefined,
      },
      sorts: [{ sortField: 'createdAt', sortOrder: SortOrderEnum.Desc }],
    },
    {
      edgeKey: 'orderRequests',
      pageSize: table.state.numberOfRowsPerPage,
    },
  );

  const rows = useMemo(() => {
    return data?.orderRequests.edges?.map(({ node }) => node) || [];
  }, [data?.orderRequests.edges]);

  return (
    <Box className="mt-24 px-16">
      <EntityManager subject={AllowedPermissionsSubjectEnum.OrderRequestReport}>
        <MUiBox
          className="rounded-[12px] border border-grey-300"
          sx={{
            boxShadow:
              '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
          }}>
          <EntityManager.Table
            persistKey="order-request-report-table"
            pagination
            ignoreRelayPagination
            loading={initialFetching || fetching}
            actions={table.actions}
            columns={columns}
            getDetailPanelContent={(props: any) => (
              <OrderItemsTable row={props?.row?.orderRequestItems} />
            )}
            getDetailPanelHeight={() => 'auto'}
            data={rows || []}
            minWidth={950}
            setState={table.setState}
            state={table.state}
            total={data?.orderRequests.totalCount}
            onNextPage={onNextPage}
            onPrevPage={onPrevPage}
            paginationWrapperClass="p-[24px]"
          />
        </MUiBox>
      </EntityManager>
    </Box>
  );
};

export default OrderRequestReportTable;
