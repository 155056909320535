import { useCallback, useState } from 'react';
import { DataGridCellSortState } from '@procurenetworks/procure-component-library';
import useDebounce from 'app/hooks/useDebounce';
import { SortOrderEnum, SortProps } from 'app/types/schema';
import { getPageSettingsFromStorage } from './../../../../../app/utils/paginationSettingsUtil';

interface State {
  activePage: number;
  search: string;
  sortState: DataGridCellSortState;
  sorts: SortProps[];
  selectedRowId: string | null;
  showDeleteConfirmation: boolean;
  numberOfRowsPerPage: number;
  selection: boolean;
}

interface Props {
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  selection?: boolean;
  sortState?: DataGridCellSortState;
  numberOfRowsPerPage?: number;
}

const useEntityManager = (props: Props) => {
  const {
    onEdit: onEditProp,
    onDelete: onDeleteProp,
    sortState,
    selection,
    numberOfRowsPerPage: numberOfRowsPerPageProp,
  } = props;


  // Read rows per page from session storage if available.
  let rowsPerPage = getPageSettingsFromStorage(numberOfRowsPerPageProp || 10);

  const [state, _setState] = useState<State>({
    activePage: 0,
    numberOfRowsPerPage: rowsPerPage,
    search: '',
    sortState: sortState || { id: undefined, orderBy: undefined },
    sorts: sortState
      ? ([
        {
          sortField: sortState.id,
          sortOrder: sortState.orderBy === 'asc' ? SortOrderEnum.Asc : SortOrderEnum.Desc,
        },
      ] as SortProps[])
      : [],
    selectedRowId: null,
    showDeleteConfirmation: false,
    selection: selection !== undefined ? selection : true,
  });

  const debouncedSearchText = useDebounce(state.search);

  const setState = useCallback((nextState: Partial<State>) => {
    _setState((prevState) => ({ ...prevState, ...nextState }));
  }, []);

  const onSearchChange = useCallback(
    (nextSearch: string) => {
      setState({ search: nextSearch, activePage: 0 });
    },
    [setState],
  );

  const onShowDeleteConfirmation = useCallback(() => {
    setState({ showDeleteConfirmation: true });
  }, [setState]);

  const onCancelDelete = useCallback(() => {
    setState({ showDeleteConfirmation: false });
  }, [setState]);

  const onEdit = useCallback(() => {
    if (state.selectedRowId && onEditProp) {
      onEditProp(state.selectedRowId);
    }
  }, [onEditProp, state.selectedRowId]);

  const onConfirmDelete = useCallback(() => {
    if (state.selectedRowId) {
      if (onDeleteProp) {
        onDeleteProp(state.selectedRowId);
      }

      setState({ selectedRowId: null, showDeleteConfirmation: false });
    }
  }, [onDeleteProp, state.selectedRowId, setState]);

  return {
    search: {
      debouncedSearchText,
      value: state.search,
      onChange: onSearchChange,
    },
    table: {
      state,
      setState,
      actions:
        onEditProp || onDeleteProp
          ? {
            onEdit,
            onCancelDelete,
            onConfirmDelete,
            onShowDeleteConfirmation,
          }
          : undefined,
    },
  };
};

export type UseEntityManagerReturnType = ReturnType<typeof useEntityManager>;
export default useEntityManager;
