import { useCallback, useState } from 'react';
import { DataGridCellSortState } from '@procurenetworks/procure-component-library';
import { SortProps } from 'app/types/schema';
import { getPageSettingsFromStorage } from './../../../../../app/utils/paginationSettingsUtil';

interface State {
  activePage: number;
  numberOfRowsPerPage: number;
  sortState: DataGridCellSortState;
  sorts: SortProps[];
}

const useReportPreviewTable = () => {

  // Read rows per page from session storage if available.
  let rowsPerPage = getPageSettingsFromStorage(10);

  const [state, _setState] = useState<State>({
    activePage: 1,
    numberOfRowsPerPage: rowsPerPage,
    sortState: {id: undefined, orderBy: undefined},
    sorts: [],
  });

  const setState = useCallback((nextState: Partial<State>) => {
    _setState((prevState) => ({ ...prevState, ...nextState }));
  }, []);

  return { table: { state, setState } };
};

export type UseReportPreviewTableReturnType = ReturnType<typeof useReportPreviewTable>;
export default useReportPreviewTable;
