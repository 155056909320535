import { useCallback } from 'react';
import routes from 'app/consts/routes';
import AssetModel from 'app/i18n/AssetModel';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import AssetModelForm from 'app/modules/assetModels/components/AssetModelForm';
import EntityManager from 'app/modules/components/EntityManager';
import FindUPCProvider from 'app/modules/components/FindUPC/context/FindUPCProvider';
import { AllowedPermissionsSubjectEnum, ItemTypeEnum } from 'app/types/schema';

import { SnackbarService } from '../../../../components/Snackbar';
import { AssetModelItemFormInput } from '../../components/AssetModelForm/type';
import { createAssetModelInput } from '../../components/AssetModelForm/utils/dto';
import { useCreateAssetModelMutation } from './graphql/mutations/generated/createAssetModel';
import analytics from 'app/analytics';

const DEFAULT_VALUES: AssetModelItemFormInput = {
  type: ItemTypeEnum.Asset,
  productCodeInput: '',
  upcCode: '',
  asinCode: '',
  gtinCode: '',
  noUpcCode: false,
  mName: '',
  modelNumber: '',
  brand: '',
  categoryId: '',
  title: '',
  description: '',
  vendorId: '',
  unitCost: '0.00',
  costOverride: '',
  attachments: [],
  pickableThroughOrderRequest: true,
  manufacturerId: '',
};

function AddAssetModel(props: RouteComponentProps) {
  const { history } = props;

  const [{ fetching }, onCreateAssetModel] = useCreateAssetModelMutation();

  const onCancel = useCallback(() => {
    history.push(routes.AssetModels());
  }, [history]);

  const onSubmit = useCallback(
    (values: AssetModelItemFormInput) => {
      onCreateAssetModel({
        input: createAssetModelInput(values),
      }).then((response) => {
        analytics?.track('Created', { name: 'Asset Model' });
        const createAssetModelItemResponse = response?.data?.createAssetModelItem;
        if (
          createAssetModelItemResponse?.success &&
          createAssetModelItemResponse?.assetModelItem?.id
        ) {
          SnackbarService.show({
            message: AssetModel.SuccessMessages.AssetModelCreated,
          });
          history.push(routes.AssetModels());
        }
      });
    },
    [onCreateAssetModel, history],
  );

  return (
    <EntityManager subject={AllowedPermissionsSubjectEnum.AssetModel}>
      <EntityManager.Title
        documentTitle={AssetModel.NewAssetModel}
        title={AssetModel.NewAssetModel}
      />
      <FindUPCProvider>
        <AssetModelForm
          defaultValues={DEFAULT_VALUES}
          disabled={fetching}
          fetching={fetching}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </FindUPCProvider>
    </EntityManager>
  );
}

export default withRouter(AddAssetModel);
