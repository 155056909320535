import React, { useCallback } from 'react';
import { Grid, Box as MuiBox } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';

import routes from '../../../../consts/routes';
import Assets from '../../../../i18n/Assets';
import Common from '../../../../i18n/Common';
import Box from '../../../../ui-components/Box';
import EntityManager from '../../../components/EntityManager';
import CheckoutForm from './components/CheckoutForm';
import { useCreateCheckOutTransactionsMutation } from './graphql/mutations/generated/createCheckOutTransaction';
import useCheckOutState from './hook/useCheckOutState';
import { getCheckOutTableColumns } from './utils/columns';
import analytics from 'app/analytics';

const CheckOut = (Props: RouteComponentProps) => {
  const { history } = Props;
  const [{ fetching: creatingCheckOutTransaction }, onCreateCheckOutTransactions] =
    useCreateCheckOutTransactionsMutation();

  const {
    formState,
    rows,
    table,
    count,
    onFormSubmit,
    resetTableAndForm,
    transactions,
    onRemoveRow,
  } = useCheckOutState();

  const onCheckOutClick = useCallback(() => {
    onCreateCheckOutTransactions({
      input: {
        entities: transactions,
      },
    }).then((response) => {
      analytics?.track('Created', { name: 'Asset Checkout' });
      if (response?.data?.createCheckOutTransactions?.success) {
        SnackbarService.show({
          message: Assets.SuccessMessages.CheckOutTransactionCreated,
        });
        resetTableAndForm();
        const createdTransactions = response?.data?.createCheckOutTransactions.transactions;
        if (createdTransactions?.length) {
          history.push(
            routes.ConfirmationAssetEditTransactions(createdTransactions[0].parentTransactionId),
          );
        }
      } else {
        console.error('[Failed to CheckOut Transaction]', response);
      }
    });
  }, [transactions, onCreateCheckOutTransactions, resetTableAndForm, history]);

  return (
    <EntityManager className="rounded-bl-[12px] rounded-br-[12px] pt-0">
      <EntityManager.Title
        documentTitle={Assets.CheckOutDocument}
        title={Assets.CheckOutAsset}
        hasTopDivider
      />
      <Box className="flex flex-wrap gap-[24px] lg:w-full xl:w-full">
        <Box
          className="
            w-full
            pt-6 
            md:m-[0px]
            md:min-w-[416px]
            md:max-w-[416px]
            lg:m-[0px]
            lg:min-w-[416px] 
            lg:min-w-[416px]
            xl:m-[0px]
            xl:max-w-[416px]
            xl:max-w-[416px]
        ">
          <CheckoutForm formState={formState} onSubmit={onFormSubmit} />
        </Box>
        <Box className="mt-[24px] flex-1 w-full" style={{ overflowX: "auto" }}>
          <MuiBox
            className="rounded-[12px] border border-grey-300"
            sx={{
              boxShadow:
                '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
            }}>
            <EntityManager.Table
              pagination
              actions={table.actions}
              columns={getCheckOutTableColumns()}
              data={rows}
              extraProps={{
                onRemoveRow,
              }}
              loading={creatingCheckOutTransaction}
              persistKey="checkOut_table"
              setState={table.setState}
              state={table.state}
              total={count}
              paginationWrapperClass="p-[24px]"
            />

            <Box className="flex justify-end gap-[16px] p-[24px]">
              <Button
                disabled={transactions.length === 0 || creatingCheckOutTransaction}
                theme="success"
                onClick={onCheckOutClick}
                classes="min-w-[104px] h-[44px]">
                {Assets.Actions.CheckOut}
              </Button>
              <Button
                disabled={transactions.length === 0 || creatingCheckOutTransaction}
                onClick={resetTableAndForm}
                classes="min-w-[94px] h-[44px]">
                {Common.Actions.Cancel}
              </Button>
            </Box>
          </MuiBox>
        </Box>
      </Box>
    </EntityManager>
  );
};

export default withRouter(CheckOut);
