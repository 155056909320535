import { Accept } from 'react-dropzone';
import CSVIcon from 'app/assets/CSV.png';
import DocumentIcon from 'app/assets/Doc.png';
import ExcelIcon from 'app/assets/Excel.png';
import FileIcon from 'app/assets/File.png';
import PDFIcon from 'app/assets/Pdf.png';
import PPTIcon from 'app/assets/PPT.png';
import WordIcon from 'app/assets/Word.png';

import { MediaSchema } from '../types/schema';

export const getUrlExtension = (url: string) => {
  return url.split(/[#?]/)?.[0].split('.')?.pop()?.trim() || null;
};

export function getFileNameFromUrl(url: string) {
  return url.substring(url.lastIndexOf('/') + 1);
}

export const supportedImageFormats = [
  'apng',
  'avif',
  'gif',
  'jpg',
  'jpeg',
  'jfif',
  'pjpeg',
  'pjp',
  'png',
  'svg',
  'webp',
];

export const supportedBulkImportAttachmentFormats: Accept = {
  '.csv': [],
};

export const supportedFilePreviewFormats = ['pdf', 'doc', 'docx', 'csv', 'xls', 'xlsx', 'ppt'];
const imageSourceRegexes: RegExp[] = [
  /image_.*OrientalTrading/,
];

const supportedPdfFormats = new Set(['application/pdf', 'pdf']);
const supportedCsvFormats = new Set(['text/csv', 'csv']);
const supportedXlsFormats = new Set(['xls', 'xlsx', '.sheet']);
const supportedDocumentsFormats = new Set(['.document']);
const supportedMsWordFormats = new Set(['msword']);
const supportedPptFormats = new Set(['ppt', '.presentation']);

export const isSupportedCsvFormat = (url: string): boolean => {
  const extension = getUrlExtension(url)?.toLowerCase();
  return !!(extension && supportedCsvFormats.has(extension));
};

export const isSupportedImageUrl = (url: string): boolean => {
  const imageUrlExtension = getUrlExtension(url)?.toLowerCase();
  if (imageSourceRegexes.some((regex) => regex.test(url))) {
    return true;
  }
  return !!(
    imageUrlExtension &&
    supportedImageFormats.find((imageFormat) => imageUrlExtension.startsWith(imageFormat))
  );
};

export const isSupportedFilePreviewUrl = (url: string): boolean => {
  const fileExtension = getUrlExtension(url)?.toLowerCase();
  return !!fileExtension && supportedFilePreviewFormats.includes(fileExtension);
};

export const isFileSupportedForPreview = (url: string): boolean => {
  const fileExtension = getUrlExtension(url)?.toLowerCase();
  return !!(
    fileExtension &&
    supportedPdfFormats.has(fileExtension) &&
    supportedCsvFormats.has(fileExtension) &&
    supportedDocumentsFormats.has(fileExtension) &&
    supportedMsWordFormats.has(fileExtension)
  );
};

export const getSupportedImageAttachments = (attachments?: MediaSchema[]) => {
  if (!attachments) {
    return [];
  }
  return attachments.filter((attachment) => {
    return attachment.url && isSupportedImageUrl(attachment.url);
  });
};
export const getAttachmentPreviewImage = (url: string) => {
  const attachmentExtension = getUrlExtension(url);

  if (!attachmentExtension) {
    return FileIcon;
  }

  if (isSupportedImageUrl(url)) {
    return url;
  } else if (supportedPdfFormats.has(attachmentExtension)) {
    return PDFIcon;
  } else if (supportedCsvFormats.has(attachmentExtension)) {
    return CSVIcon;
  } else if (supportedXlsFormats.has(attachmentExtension)) {
    return ExcelIcon;
  } else if (supportedDocumentsFormats.has(attachmentExtension)) {
    return DocumentIcon;
  } else if (supportedMsWordFormats.has(attachmentExtension)) {
    return WordIcon;
  } else if (supportedPptFormats.has(attachmentExtension)) {
    return PPTIcon;
  }

  return FileIcon;
};

export const assetInventoryAttachmentAccepts: Accept = {
  'image/apng': [],
  'image/avif': [],
  'image/gif': [],
  'image/jpg': [],
  'image/jpeg': [],
  'image/jfif': [],
  'image/pjpeg': [],
  'image/pjp': [],
  'image/png': [],
  'image/svg': [],
  'image/webp': [],
  'image/bmp': [],
  'image/ico': [],
  'image/cur': [],
  'image/tif': [],
  'image/tiff': [],
  'application/pdf': [],
  '.csv': [],
  '.doc': [],
  '.docx': [],
  '.ppt': [],
  '.pptx': [],
  '.xls': [],
  '.xlsx': [],
  '.txt': [],
  '.rtf': [],
};

export const imageAttachmentAccepts: Accept = {
  'image/apng': [],
  'image/avif': [],
  'image/gif': [],
  'image/jpg': [],
  'image/jpeg': [],
  'image/jfif': [],
  'image/pjpeg': [],
  'image/pjp': [],
  'image/png': [],
  'image/svg': [],
  'image/webp': [],
  'image/ico': [],
};

export const orderRequestAttachmentAccepts: Accept = {
  'image/apng': [],
  'image/avif': [],
  'image/gif': [],
  'image/jpg': [],
  'image/jpeg': [],
  'image/jfif': [],
  'image/pjpeg': [],
  'image/pjp': [],
  'image/png': [],
  'image/svg': [],
  'image/webp': [],
  'image/bmp': [],
  'image/ico': [],
  'image/cur': [],
  'image/tif': [],
  'image/tiff': [],
};
