import { createExternalProductCodesData } from 'app/modules/assets/utils/dto';
import {
  CreateInventoryItemInput,
  CreateInventoryKitItemInput,
  UpdateInventoryItemInput,
  UpdateInventoryKitItemInput,
} from 'app/types/schema';

export const createInventoryItemInputDTO = (
  values: any,
  isItemTypeProduct: boolean,
): CreateInventoryItemInput => {
  const {
    costOverride,
    unitCost,
    type,
    brand,
    description,
    mName,
    categoryId,
    vendorId,
    title,
    attachments,
    pickableThroughOrderRequest,
    manufacturerId,
    formattedDescription,
    productCodeType
  } = values || {};

  return {
    title,
    description,
    formattedDescription,
    categoryId,
    attachments,
    unitCost: unitCost ? Number(unitCost) : 0,
    costOverride: costOverride ? Number(costOverride) : 0,
    externalProductCodes: createExternalProductCodesData(values),
    brand: isItemTypeProduct && brand ? brand : undefined,
    vendorId: isItemTypeProduct && vendorId ? vendorId : undefined,
    mName: isItemTypeProduct && mName ? mName : undefined,
    type,
    pickableThroughOrderRequest,
    manufacturerId: manufacturerId || undefined,
  };
};

export const createInventoryKitItemInputDTO = (
  values: any,
  kitItems: any[],
): CreateInventoryKitItemInput => {
  const {
    costOverride,
    unitCost,
    type,
    description,
    categoryId,
    title,
    attachments,
    destinationSiteId,
    destinationLocationId,
    quantity,
    pickableThroughOrderRequest,
    formattedDescription,
  } = values || {};

  const _kitItems =
    kitItems?.map((row: any) => {
      return { itemId: row.id, count: row.quantity, locationId: row.locationId };
    }) || [];

  return {
    title,
    description,
    categoryId,
    attachments,
    unitCost: unitCost ? Number(unitCost) : 0,
    costOverride: costOverride ? Number(costOverride) : 0,
    type,
    siteId: destinationSiteId,
    locationId: destinationLocationId,
    quantity: quantity ? Number(quantity) : 0,
    kitConfigs: _kitItems,
    pickableThroughOrderRequest,
    formattedDescription,
  };
};

export const createUpdateInventoryItemInputDTO = (
  inventoryId: string,
  values: any,
  isItemTypeProduct: boolean,
): UpdateInventoryItemInput => {
  const {
    costOverride,
    unitCost,
    brand,
    description,
    mName,
    categoryId,
    vendorId,
    title,
    attachments,
    pickableThroughOrderRequest,
    manufacturerId,
    formattedDescription,
  } = values;

  return {
    itemId: inventoryId || '',
    title,
    attachments,
    unitCost: unitCost ? Number(unitCost) : 0,
    externalProductCodes: createExternalProductCodesData(values),
    costOverride: costOverride ? Number(costOverride) : 0,
    categoryId,
    description,
    formattedDescription,
    brand: isItemTypeProduct && brand ? brand : '',
    vendorId: isItemTypeProduct && vendorId ? vendorId : '',
    mName: isItemTypeProduct && mName ? mName.trim() : '',
    pickableThroughOrderRequest: pickableThroughOrderRequest,
    manufacturerId: manufacturerId || '',
  };
};

export const createUpdateInventoryKitItemInputDTO = (
  inventoryId: string,
  values: any,
): UpdateInventoryKitItemInput => {
  const {
    costOverride,
    description,
    formattedDescription,
    categoryId,
    title,
    attachments,
    quantity,
    pickableThroughOrderRequest,
  } = values;

  return {
    inventoryKitItemId: inventoryId || '',
    title,
    attachments: {
      set: attachments,
    },
    costOverride: costOverride ? Number(costOverride) : 0,
    categoryId,
    quantity: quantity ? Number(quantity) : 0,
    description,
    formattedDescription,
    pickableThroughOrderRequest,
  };
};
