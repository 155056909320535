import { useMemo } from 'react';

import {
  InventoryItemFilters,
  InventoryItemSchema,
  ItemLocationSchema,
  SortProps,
} from '../../../../../../types/schema';
import { parseSearchAssetInventoryStockInformationToRows } from '../../../../components/QuantityBySite/utils/stockInormation';
import { useInventoryTableQuery } from '../../graphql/queries/generated/inventoryTable';
import useOffsetPagination from '../../../../../../hooks/useOffsetPagination';

interface Props {
  filters: InventoryItemFilters;
  siteId?: string;
  numberOfRowsPerPage: number;
  sorts: SortProps[];
}

const useInventoryItemRows = (props: Props) => {
  const { filters, sorts, siteId, numberOfRowsPerPage } = props;
  let sortsfield =
    Array.isArray(sorts) && sorts.length > 0
      ? sorts.map((sort) => {
          return { ...sort, sortField: sort.sortField === 'model' ? 'mName' : sort.sortField };
        })
      : [];

  const { fetching, stale, data, onNextPage, onPrevPage, onReset } = useOffsetPagination(
    useInventoryTableQuery,
    {
      filters,
      sorts: sortsfield,
      siteId,
    },
    { edgeKey: 'items', pageSize: numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    return (
      data?.items.edges?.map(({ node }) => {
        const _node = node as InventoryItemSchema;
        return {
          ...node,
          parsedStockInformation: parseSearchAssetInventoryStockInformationToRows(
            (_node.stockInformation || []) as ItemLocationSchema[],
          ),
        };
      }) || []
    );
  }, [data?.items.edges]);

  return {
    rows,
    fetching: fetching || stale,
    onNextPage,
    onPrevPage,
    onReset,
    totalCount: data?.items.totalCount || 0,
  };
};

export default useInventoryItemRows;
