import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  OrderRequestItemSchema,
  OrderRequestItemStatusEnum,
  OrderRequestItemTypeEnum,
  UpdateOrderRequestItemStatusByOrderRequestIdInput,
} from 'app/types/schema';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import { Option } from './type';
import { createUpdateOrderRequestItemsStatusInput, getCurrentOption, getOptions } from './utils';
import useOrderedNotePopoverState, { OrderedNotePopoverDefaultValue } from '../FormOrderDetailStatus/hook/useOrderedNotePopoverState';
import OrderedNotePopover from '../OrderedNotePopover';
import { useUpdateOrderRequestItemStatusByOrderRequestIdMutation } from '../../graphql/mutations/generated/updateOrderRequestItemStatusByOrderRequestIdInput';
import { OrderDetailsData } from '../../views/OrderDetail/type';
import { SnackbarService } from '../../../../components/Snackbar';
import Orders from '../../../../i18n/Orders';
import TableLoaderNode from '../../../../components/TableLoaderNode';
import useCurrentUser from '../../../auth/hooks/useCurrentUser';
import { isAccessible } from '../../../../components/AccessControl';
import WarningAlert from 'app/components/WarningAlert/WarningAlert';
import analytics from 'app/analytics';

interface Props {
  orderDetail: OrderDetailsData;
  orderRequestItem: OrderRequestItemSchema;
  disabled?: boolean;
}

const getOptionLabel = (option: Option) => option.label;

const FormOrderRequestItemStatus = (props: Props) => {
  const { orderRequestItem, disabled, orderDetail } = props;
  const { state: notesPopoverState, action: notesPopoverActions } = useOrderedNotePopoverState({ orderRequestItem });
  const [selectedValue, setSelectedValue] = useState<Option>(getCurrentOption(orderRequestItem));
  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLDivElement | null>(null);
  const [openModal, setOpenModal] = useState<Option | null>(null);

  const [{ fetching }, onUpdateOrderRequestItemStatusByOrderRequestId] = useUpdateOrderRequestItemStatusByOrderRequestIdMutation();

  const { workspacePermissions, id } = useCurrentUser();

  const isOrderCreatedByCurrentUser = useMemo(
    () => orderDetail.createdById === id,
    [orderDetail, id],
  );

  const canCreateShipment = useMemo(() => {
    switch (orderRequestItem.type) {
      case OrderRequestItemTypeEnum.Asset:
      case OrderRequestItemTypeEnum.AssetKit:
        return isAccessible(
          workspacePermissions,
          AllowedPermissionActionsEnum.Create,
          AllowedPermissionsSubjectEnum.AssetShipment,
        );
      case OrderRequestItemTypeEnum.Inventory:
      case OrderRequestItemTypeEnum.InventoryKit:
        return isAccessible(
          workspacePermissions,
          AllowedPermissionActionsEnum.Create,
          AllowedPermissionsSubjectEnum.InventoryShipment,
        );
      case OrderRequestItemTypeEnum.NoSku:
        return true;
      default:
        return false;
    }
  }, [orderRequestItem.type, workspacePermissions]);

  const canChangeStatus = useMemo(() => {
    return canCreateShipment || isOrderCreatedByCurrentUser;
  }, [canCreateShipment, isOrderCreatedByCurrentUser]);

  const options = useMemo(() => {
    return getOptions(orderRequestItem, {
      canCreateShipment,
      isOrderCreatedByCurrentUser,
    });
  }, [orderRequestItem, isOrderCreatedByCurrentUser, canCreateShipment]);

  const updateOrderStatus = useCallback(
    async (input: UpdateOrderRequestItemStatusByOrderRequestIdInput) => {
      const response = await onUpdateOrderRequestItemStatusByOrderRequestId({ input });
      analytics?.track('Edited', { name: 'Order Status' });
      const success = response.data?.updateOrderRequestItemStatusByOrderRequestId?.success;
      if (success) {
        SnackbarService.show({ message: Orders.SuccessMessages.OrderStatusUpdated });
      }
      return { success };
    },
    [onUpdateOrderRequestItemStatusByOrderRequestId],
  );

  const onValueChange = useCallback(
    async (_: any, option: Option) => {
      if (option.value === OrderRequestItemStatusEnum.Cancelled || option.value === OrderRequestItemStatusEnum.Closed) {
        setOpenModal(option);
      } else if (option.value === OrderRequestItemStatusEnum.Ordered) {
        notesPopoverActions.onOpen();
      } else {
        const response = await updateOrderStatus({
          orderRequestId: orderDetail.id,
          orderRequestItemId: orderRequestItem.id,
          status: option.value as OrderRequestItemStatusEnum,
        });
        if (response?.success) {
          setSelectedValue(option);
        }
      }
    },
    [setSelectedValue, notesPopoverActions, updateOrderStatus, orderRequestItem.id, orderDetail.id],
  );

  const onAddOrderedStatusNote = useCallback(
    async (nonRemovableNote: OrderedNotePopoverDefaultValue) => {
      const input = createUpdateOrderRequestItemsStatusInput({
        orderRequestId: orderDetail.id,
        orderRequestItemId: orderRequestItem.id,
        status: OrderRequestItemStatusEnum.Ordered,
        nonRemovableNote,
      });

      const response = await updateOrderStatus(input);
      if (response?.success) {
        notesPopoverActions.onClose();
        notesPopoverActions.reset();
      }
    },
    [orderRequestItem.id, orderDetail.id, notesPopoverActions, updateOrderStatus],
  );

  const onCancelledOrClosedOrderStatusModal = useCallback(async () => {
    if (!openModal) return;
    const response = await updateOrderStatus({
      orderRequestId: orderDetail.id,
      orderRequestItemId: orderRequestItem.id,
      status: openModal.value as OrderRequestItemStatusEnum,
    });
    if (response?.success) {
      setSelectedValue(openModal);
    }
    setOpenModal(null);
  }, [openModal, updateOrderStatus, orderDetail.id, orderRequestItem.id]);

  useEffect(() => {
    setSelectedValue(getCurrentOption(orderRequestItem));
  }, [orderRequestItem.status]);

  return (
    <>
      {fetching && !notesPopoverState.open ? (
        <div className="w-[100%]">
          <TableLoaderNode />
        </div>
      ) : (
        <Autocomplete
          disableClearable
          className="w-[140px]"
          disabled={disabled || fetching || !canChangeStatus}
          getOptionDisabled={(option: Option) => option.disabled}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          label=""
          options={options}
          renderInput={(params) => (
            <TextField {...params} onClick={(event) => setAnchorEl(event.currentTarget)} style={{ display: "block" }} />
          )}
          value={selectedValue}
          onChange={onValueChange}
        />
      )}
      <OrderedNotePopover
        state={notesPopoverState}
        disabled={fetching}
        action={notesPopoverActions}
        onSubmit={onAddOrderedStatusNote}
        anchorEl={anchorEl}
      />
      <WarningAlert
        open={Boolean(openModal)}
        message="Marking this item Cancelled or Closed is not reversible and will not be logged as a transaction.&#10;Would you like to proceed?"
        onClose={() => setOpenModal(null)}
        onConfirm={onCancelledOrClosedOrderStatusModal}
        className="w-full whitespace-pre-wrap break-words"
      />
    </>
  );
};

export default FormOrderRequestItemStatus;
