import { memo, useEffect, useRef, useState } from 'react';
import { getParentsUntil } from 'app/utils/parentNode';

interface Props {
  charLimit?: number;
  text: string;
  redirectLink?: string;
  isLinkContent?: boolean;
  classNameExtends?: string;
  clampLine?: string | number;
  index?: string;
  hideShowMoreBtn?: boolean;
}

const ReadMore = (props: Props) => {
  const { text, redirectLink, isLinkContent, clampLine = '1', hideShowMoreBtn = true } = props;
  const [isReadMore, setIsReadMore] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (hideShowMoreBtn) return;
    // If Read more button is not needed to show then further execution is not needed.

    let element = textRef.current;
    let parentCell: Element | null = null;
    let resizeObserver: ResizeObserver | null = null;

    const checkOverflow = () => {
      // Determines if the text is overflowing by comparing scroll height and client height
      if (!element) return;

      const isTextOverflowing = element.scrollHeight > element.clientHeight;
      setIsTruncated(isTextOverflowing);
    };

    resizeObserver = new ResizeObserver(checkOverflow);

    if (element) {
      checkOverflow();
      const parents = getParentsUntil(element, '[role="cell"]');

      if (parents?.[0]) {
        parentCell = parents[0];
      }

      resizeObserver.observe(element);
    }

    return () => resizeObserver?.disconnect();
  }, [text, hideShowMoreBtn]);

  const toggle = () => {
    setIsReadMore(!isReadMore);
  };

  const handleUrlRedirect = () => {
    if (isLinkContent) window.open(redirectLink, '_blank');
  };

  return (
    <>
      <div className={`readMore-wrap-word ${isReadMore ? '!py-20' : ''}`}>
        <p
          ref={textRef}
          title={text}
          className={`
            ${!isReadMore ? `text-line-clamp-${clampLine}` : ''} 
            ${isLinkContent ? 'cursor-pointer' : ''} text-wrap`}
          onClick={() => handleUrlRedirect()}>
          {text}
        </p>
        {/* When user click on Read More button, it shows full content which is scrollable. So due to scrollbar it triggers 
          resize observer and it will set isTruncated to false as texts is not truncated. So we need to check isReadMore to show Read Less button.
        */}
        {(isTruncated || isReadMore) && (
          <div className="readMore text-[12px]" onClick={toggle}>
            {isReadMore ? 'Show less' : 'Show more'}
          </div>
        )}
      </div>
    </>
  );
};

export default memo(ReadMore, (prevProps, nextProps) => prevProps.text === nextProps.text);
