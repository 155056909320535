import _ from 'lodash';

import { CreateMediaInput, ItemExternalProductCodeTypeEnum, MediaSchema } from '../../../../types/schema';
import { format } from '../../../../utils/cost';
import { Option as AssetModelOption } from '../../../assetModels/components/FormAssetModelSelect';
import { isCategoryActive } from '../../../categories/utils/utils';
import { parseMediaSchemaToAttachment } from '../../../components/FindUPC/components/ProductDetailFormModal/hook/usePersistUpcProductData/utils';
import { isCompanyActive } from '../../../contacts/utils/utils';
import { AssetItemFormInput } from '../../components/AssetForm/types';

export function parseProduceDataToAssetFormValues(values: any) {
  const formValues: any = {};
  _.forEach(_.keys(values), (key) => {
    switch (key) {
      case 'title':
        formValues.title = values.title.substring(0, 32);
        break;

      case 'productCode':
        formValues.productCode = values?.productCode || '';
        break;

      case 'description':
        formValues.description = values?.description || '';
        break;

      case 'model':
        formValues.mName = values?.model || '';
        break;

      case 'brand':
        formValues.brand = values?.brand || '';
        break;
      
      case 'productCodeType':
        formValues.productCodeType = values?.productCodeType || '';
        break;
      
      case 'asinCode':
        formValues.asinCode = values?.asinCode || '';
        break;
      
      case 'upcCode':
        formValues.upcCode = values?.upcCode || ''
        break;
      
      case 'gtinCode':
        formValues.gtinCode = values?.gtinCode || ''
        break;
    }
  });
  return formValues;
}

export function parseAssetModelDataToAssetFormValues(
  assetModelData?: AssetModelOption,
): Partial<AssetItemFormInput> {
  if (!assetModelData) {
    return {};
  }
  const {
    brand,
    category,
    costOverride,
    description,
    externalProductCodes,
    mName,
    manufacturerId,
    modelNumber,
    pickableThroughOrderRequest,
    title,
    unitCost,
    vendor,
  } = assetModelData;

  const externalUpcCodeData = externalProductCodes?.[0] || undefined;
  const upcCode = externalProductCodes?.find(item => item.type === ItemExternalProductCodeTypeEnum.Upc)?.code;
  const asinCode = externalProductCodes?.find(item => item.type === ItemExternalProductCodeTypeEnum.Asin)?.code;

  return {
    brand,
    categoryId: category?.id && isCategoryActive(category?.status) ? category?.id : '',
    costOverride: costOverride === null ? '0' : costOverride,
    description: description || '',
    noUpcCode: Boolean(!externalUpcCodeData?.code),
    productCodeType: externalUpcCodeData?.type,
    upcCode: upcCode || '',
    asinCode: asinCode || '',
    mName,
    modelNumber: modelNumber || '',
    manufacturerId: manufacturerId || '',
    pickableThroughOrderRequest:
      pickableThroughOrderRequest !== null && pickableThroughOrderRequest !== undefined
        ? pickableThroughOrderRequest
        : false,
    title: title.substring(0, 32),
    unitCost: unitCost === null || unitCost === undefined ? '0.00' : format(unitCost),
    vendorId: vendor?.id && isCompanyActive(vendor?.status) ? vendor?.id : '',
  };
}

export function prepareDefaultAssetItemAttachments(attachments?: CreateMediaInput[]) {
  return (
    attachments?.map((attachment: CreateMediaInput, index: number) => {
      return {
        id: `attachment-${index}`,
        ...attachment,
        resolutions: attachment?.resolutions || [],
      };
    }) || []
  );
}

export function prepareKitItemAttachments(attachments: CreateMediaInput[]) {
  return (
    attachments?.map((attachment: CreateMediaInput, index: number) => {
      return {
        ...parseMediaSchemaToAttachment(attachment),
        hideCloseButton: true,
      };
    }) || []
  );
}

export function prepareItemsForFilePreview(attachments: any[]) {
  return attachments.map((attachment) => {
    return {
      ...attachment,
      id: attachment.id,
      preview: attachment.url,
      isLoading: attachment.url === '',
      hideCloseButton: attachment.hideCloseButton,
    };
  });
}
