import { useMemo } from 'react';
import ReportPreviewTable from 'app/modules/reports/components/ReportPreviewTable';
import useReportPreviewTable from 'app/modules/reports/hooks/useReportPreviewTable';
import { getInventoryItemTypes } from 'app/modules/reports/utils/itemtypes';
import { SortOrderEnum } from 'app/types/schema';

import { useInventoryCostReportQuery } from '../../graphql/generated/inventoryCostReport';
import { CostReportFormValues } from '../CostReportForm/types';
import { getInventoryCostTableColumns } from './columns';
import { Box } from '@mui/material';
import useOffsetPagination from 'app/hooks/useOffsetPagination';

interface Props {
  values: CostReportFormValues;
}

const InventoryCostTable = (props: Props) => {
  const { values } = props;
  const { table } = useReportPreviewTable();

  const { initialFetching, fetching, data, onNextPage, onPrevPage } = useOffsetPagination(
    useInventoryCostReportQuery,
    {
      filters: { itemIds: values.skuIds, categoryIds: values.categoryIds, types: getInventoryItemTypes() },
      sorts: [{ sortField: 'updatedAt', sortOrder: SortOrderEnum.Desc }],
    },
    { edgeKey: 'items', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    return data?.items.edges?.map(({ node }) => node) || [];
  }, [data?.items.edges]);

  return (
    <Box className='mt-[24px] mx-[24px] border border-grey-300 rounded-[12px]' sx={{boxShadow:'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)'}}>
    <ReportPreviewTable
      columns={getInventoryCostTableColumns()}
      data={rows}
      initialLoading={initialFetching}
      loading={fetching}
      pagination={true}
      persistKey="inventory_cost_table"
      setState={table.setState}
      state={table.state}
      total={data?.items.totalCount}
      onNextPage={onNextPage}
      onPrevPage={onPrevPage}
      paginationClass='p-[24px]'
      className='px-[0px]'
    />
    </Box>
  );
};

export default InventoryCostTable;
