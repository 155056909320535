import { useMemo } from 'react';
import { Box } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import Common from 'app/i18n/Common';
import Receivables from 'app/i18n/Receivables';
import { withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';

import { selectItemTableColumns } from '../../../utils/columns';
import useItemsToBeReceived from '../../hook/useItemsToBeReceived';
import ScheduleReceiveFormStep2 from '../ScheduleForm/ScheduleReceiveFormStep2';

const SelectItemsReceived = (props: any) => {
  const { step1Data, onPrevious, history } = props;
  const {
    formState,
    control,
    handleSubmit,
    onReset,
    table,
    itemsOrdered,
    setVendor,
    isLoadingOrderedItems,
    isCreating,
    createReceivables,
    resetRowSelection,
    requiredInfoAvailable,
    handleSortModelChange,
  } = useItemsToBeReceived({
    step1Data,
    history,
  });

  const columns = useMemo(() => selectItemTableColumns(), []);
  return (
    <>
      <Box className="flex flex-col flex-wrap gap-[24px]  md:flex-col  lg:w-full  lg:flex-row xl:w-full xl:flex-row">
        <Box
          className="
            m-[auto] 
            w-[300px]
            pt-6 
            md:m-[0px]
            md:min-w-[416px] 
            md:max-w-[416px] 
            lg:m-[0px] 
            lg:min-w-[416px]  
            xl:m-[0px] 
            xl:max-w-[416px] 
          ">
          <EntityManager.Title
            documentTitle={Receivables.Schedule}
            title={Receivables.Schedule}
            titleWrapperClass="!pt-[0px]"
          />
          <ScheduleReceiveFormStep2
            {...{
              formState,
              control,
              handleSubmit,
              onReset,
              createReceivables,
              onPrevious,
              isLoadingOrderedItems,
              hasSelectedRows: table.state.selectedRowIds.length > 0,
              setVendor,
              requiredInfoAvailable,
            }}
          />
        </Box>
        <Box className="flex-1" style={{ overflowX: "auto" }}>
          <EntityManager.Title
            title={'Select item(s) scheduled to be received'}
            titleWrapperClass="!pt-[0px] !mt-[6px]"
          />
          <Box
            className="mt-[24px] rounded-[12px] border border-grey-300"
            sx={{
              boxShadow:
                '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
            }}>
            <EntityManager.MultiSelectTable
              key={'default-asset-table'}
              pagination
              persistSelectionData
              actions={table.actions}
              columns={columns}
              data={itemsOrdered || []}
              defaultSortState={{
                id: undefined,
              }}
              getDetailPanelHeight={() => 'auto'}
              loading={false}
              minWidth={0}
              paginationWrapperClass="p-[24px]"
              persistKey="assets_search_table"
              setState={table.setState}
              state={table.state}
              total={itemsOrdered.length}
              onSortModelChangeFunc={handleSortModelChange}
            />
            <div className="flex justify-end gap-[16px] py-[16px] px-[24px]">
              <Button
                classes="min-w-[94px] h-[44px]"
                disabled={isCreating || !table.state.selectedRowIds.length}
                loading={isCreating}
                theme="success"
                onClick={handleSubmit(createReceivables)}>
                Schedule
              </Button>
              <Button
                classes="min-w-[94px] h-[44px]"
                onClick={onPrevious}>
                {Common.Actions.Cancel}
              </Button>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default withRouter(SelectItemsReceived);
