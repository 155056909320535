import {
  Button,
  DataGridHeadCell,
  TableHeaderCell,
  ValueComponentProps,
} from '@procurenetworks/procure-component-library';
import Common from 'app/i18n/Common';
import QuantityInput from 'app/modules/components/QuantityInput';
import { useEffect, useState } from 'react';

const MinimumQuantity = (props: ValueComponentProps) => {
  const { tableProps, row } = props;
  const { editable } = row;
  const { extraProps } = tableProps;


  // value handling in case of assetItemSerialNumber present
  const getValue = () => {
    const _value = parseInt(row?.editableFields?.minimumQuantity);

    if (extraProps?.assetItemSerialNumber) {
      if (_value === 0 || _value === 1) {
        return _value;
      } else if (extraProps?.setEditableSiteState) {
        extraProps.setEditableSiteState(row.id, { minimumQuantity: "0" });
        return "0";
      }
    }

    return _value || '';
  };

  const [value, setValue] = useState(getValue())

  useEffect(() => {
    setValue(getValue())
  }, [row?.editableFields?.minimumQuantity])

  if (!editable) {
    return <span>{row.minimumQuantity}</span>;
  }

  return (
    <QuantityInput
      key={`minimum-quantity-input${row.id}`}
      maximumLength={9}
      size="small"
      maximumValue={extraProps?.assetItemSerialNumber ? 1 : undefined}
      value={value}
      onChange={(value) => {
        if (extraProps?.setEditableSiteState) {
          extraProps.setEditableSiteState(row.id, {
            minimumQuantity: value,
          });
        }
      }}
    />
  );
};
const MaximumQuantity = (props: ValueComponentProps) => {
  const { tableProps, row } = props;
  const { editable } = row;
  const { extraProps } = tableProps;

  // Setting max value to 1 if serail number present
  useEffect(() => {
    if (editable && extraProps?.assetItemSerialNumber) {
      if (extraProps?.setEditableSiteState) {
        extraProps.setEditableSiteState(row.id, {
          maximumQuantity: "1",
        });
      }
    }

  }, [extraProps?.assetItemSerialNumber, editable])


  if (!editable) {
    return <span>{row.maximumQuantity}</span>;
  }

  return (
    <QuantityInput
      key={`maximum-quantity-input${row.id}`}
      maximumLength={9}
      size="small"
      value={extraProps?.assetItemSerialNumber ? "1" : (row?.editableFields?.maximumQuantity || '')}
      disabled={!!extraProps?.assetItemSerialNumber}
      onChange={(value) => {
        if (extraProps?.setEditableSiteState) {
          extraProps.setEditableSiteState(row.id, {
            maximumQuantity: value,
          });
        }
      }}
    />
  );
};

export const getQuantityBySiteColumns = (disabled?: boolean): TableHeaderCell[] => [
  {
    id: 'site',
    label: 'Site',
    value: 'site',
    valueNode: ({ row }) => (
      <span className=" text-[14px] text-grey-900">{row?.site?.name || ''}</span>
    ),
  },
  {
    id: 'totalQuantityFromLocations',
    label: 'Quantity',
    value: 'totalQuantityFromLocations',
    valueNode: ({ row }) => (
      <span
        className={
          row?.totalQuantityFromLocations < row?.minimumQuantity
            ? 'mr-56 text-[red]'
            : ' mr-56 text-[14px] text-grey-900'
        }>
        {row?.totalQuantityFromLocations}
      </span>
    ),
    labelAlign: 'center',
    valueAlign: 'right',
    width: 200,
  },
  {
    id: 'minimumQuantity',
    label: 'Min',
    value: 'minimumQuantity',
    labelAlign: 'center',
    valueAlign: 'center',
    valueNode: MinimumQuantity,
    width: 150,
  },
  {
    id: 'Site',
    label: 'Max',
    value: 'Max',
    labelAlign: 'center',
    valueAlign: 'center',
    valueNode: MaximumQuantity,
    width: 150,
  },
  {
    id: 'id',
    label: 'Action',
    value: 'id',
    labelAlign: 'center',
    valueAlign: 'center',
    valueNode: ({ row, tableProps }) => {
      const { editable } = row;
      const { extraProps } = tableProps;
      return (
        <Button
          disabled={disabled}
          theme={!editable ? 'info' : null}
          onClick={() => {
            if (extraProps?.toggleEditableSiteState) {
              extraProps.toggleEditableSiteState(row.id);
            }
          }}
          classes="min-w-[92px] h-[44px]">
          {editable ? Common.Actions.Cancel : Common.Actions.Edit}
        </Button>
      );
    },
    width: 150,
  },
];

export const getLocationWithQuantityColumns = (windowWidth: number): DataGridHeadCell[] => [
  {
    id: 'name',
    label: 'Location',
    value: 'name',
    sortable: false,
    width: 200,
  },
  {
    id: 'totalQuantity',
    label: 'Quantity',
    value: 'totalQuantity',
    labelAlign: 'right',
    rowAlign: 'right',
    width: 150,
    labelNode: (props) => {
      return (
        <>
          <span className="font-[14px] mr-[35px] font-semibold text-grey-900">Quantity</span>
        </>
      );
    },
    valueNode: ({ row }) => <span className="mr-[25px] text-grey-900">{row?.totalQuantity}</span>,
  },
];

export const getLocationColumns = (): DataGridHeadCell[] => {
  return [
    {
      id: 'name',
      label: 'Location',
      value: 'name',
      sortable: false,
      width: 200,
    },
    {
      id: 'totalQuantity',
      label: 'Quantity',
      value: 'totalQuantity',
      sortable: false,
      rowAlign: 'right',
      width: 100,
    },
  ] as DataGridHeadCell[];
};
