import { Avatar } from '@mui/material';
import { DataGridHeadCell } from '@procurenetworks/procure-component-library';

export const getDeletedUserTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'profilePicture',
    label: 'Image',
    labelAlign: 'center',
    rowAlign: 'center',
    sortable: false,
    value: 'profilePicture',
    valueNode: ({ row }) => (
      <Avatar src={row.profilePicture} sx={{ width: 45, height: 45, marginX: 'auto' }} />
    ),
    width: 100,
  },
  {
    id: 'firstName',
    label: 'First Name',
    value: 'firstName',
    sortable: true,
    width: 150,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    value: 'lastName',
    sortable: true,
    width: 150,
  },
  {
    id: 'emailId',
    label: 'Email',
    value: 'emailId',
    sortable: true,
    width: 200,
  },
  {
    id: 'roles',
    label: 'User Role',
    value: 'roles',
    valueNode: ({ row }) => {
      const roles = row.roles;
      const roleNames = roles?.length ? roles.map((role: any) => role.name).join(', ') : '-';
      return (
        <span className="truncate" title={roleNames}>
          {roleNames}
        </span>
      );
    },
    sortable: false,
    width: 150,
  },
];
