import { useMemo } from 'react';

import {
  InventoryItemSchema,
  InventoryKitItemSchema,
  ItemExternalProductCodeTypeEnum,
  SortOrderEnum,
} from '../../../../../../types/schema';
import { format } from '../../../../../../utils/cost';
import { isCategoryActive } from '../../../../../categories/utils/utils';
import { isCompanyActive } from '../../../../../contacts/utils/utils';
import { CreateInventoryFormInput } from '../../../../components/InventoryForm/types';
import { useUserFilterQuery } from '../../../../graphql/queries/generated/userFilter';
import { useEditInventoryDataQuery } from '../../graphql/queries/generated/editInventoryData';

const useEditInventoryState = (inventoryId: string) => {
  const [{ data, fetching }] = useEditInventoryDataQuery({
    variables: {
      filters: {
        itemIds: [inventoryId],
      },
    },
  });

  const inventoryData = useMemo(() => {
    return data?.items.edges?.[0]?.node;
  }, [data?.items.edges]);

  const inventoryFormData = useMemo((): CreateInventoryFormInput | undefined => {
    if (inventoryData) {
      const {
        sku,
        attachments,
        costOverride,
        category,
        vendor,
        title,
        description,
        formattedDescription,
        model,
        brand,
        externalProductCodes,
        type,
        pickableThroughOrderRequest,
        unitCost,
        manufacturerId,
      } = inventoryData as InventoryItemSchema;

      const externalUpcCodeData = externalProductCodes?.[0] || undefined;
      const upcCode = externalProductCodes?.find(item => item.type === ItemExternalProductCodeTypeEnum.Upc)?.code;
      const asinCode = externalProductCodes?.find(item => item.type === ItemExternalProductCodeTypeEnum.Asin)?.code;
    
      return {
        sku: sku || '',
        attachments: attachments || [],
        costOverride: costOverride || 0,
        unitCost: unitCost === null || unitCost === undefined ? '0.00' : format(unitCost),
        upcCode: upcCode || '',
        asinCode: asinCode || '',
        noUpcCode: Boolean(!externalUpcCodeData?.code),
        productCodeType: externalUpcCodeData?.type,
        findUpc: false,
        brand: brand || '',
        categoryId: category?.id && isCategoryActive(category.status) ? category?.id : '',
        description: formattedDescription || description || '',
        mName: model || '',
        title,
        vendorId: vendor?.id && isCompanyActive(vendor?.status) ? vendor?.id : '',
        type,
        pickableThroughOrderRequest:
          pickableThroughOrderRequest !== null && pickableThroughOrderRequest !== undefined
            ? pickableThroughOrderRequest
            : false,
        manufacturerId: manufacturerId || '',
      };
    }

    return undefined;
  }, [inventoryData]);

  // User Filter
  const [userResult] = useUserFilterQuery({
    variables: {
      sorts: [
        {
          sortField: 'firstName',
          sortOrder: SortOrderEnum.Asc,
        },
        {
          sortField: 'lastName',
          sortOrder: SortOrderEnum.Asc,
        },
      ],
      limit: 10000,
    },
  });

  return {
    fetching,
    inventoryData,
    inventoryFormData,
    stockInformation: (inventoryData as InventoryItemSchema | InventoryKitItemSchema)
      ?.stockInformation,
  };
};

export type UseEditInventoryStateReturnType = ReturnType<typeof useEditInventoryState>;
export default useEditInventoryState;
