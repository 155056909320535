import { Radio } from '@mui/material';
import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import { ValueComponentProps } from '@procurenetworks/procure-component-library/build/components/ui/DataGrid/DataGrid.types';

const RadioControlValueNode = (valueComponentProps: ValueComponentProps) => {
  const { row, state } = valueComponentProps;
  const { extraProps } = state;

  const handleChange = () => {
    if (extraProps?.onProductCodeClick) {
      extraProps.onProductCodeClick(row);
    }
  }
  return (
    <Radio
      checked={extraProps?.selectedRowId === row.id}
      onClick={handleChange}
      sx={{
        '&.Mui-checked': {
          color: 'var(--blue)',
        },
      }}
      onChange={handleChange}
    />
  );
}

export const getUPCResultTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'radioButton',
    label: '',
    value: 'radioButton',
    valueNode: RadioControlValueNode,
    sortable: false,
    width: 30,
  },
  {
    id: 'upcCode',
    label: 'UPC Code',
    value: 'upcCode',
    valueNode: (valueComponentProps) => {
      const { row } = valueComponentProps;
      return (
        <span
          title={row.upc}>
          {row.upc}
        </span>
      );
    },
    sortable: false,
  },
  {
    id: 'asinCode',
    label: 'ASIN Code',
    value: 'asinCode',
    valueNode: (valueComponentProps) => {
      const { row } = valueComponentProps;
      return (
        <span
          title={row.asin}>
          {row.asin}
        </span>
      );
    },
    sortable: false,
  },
  {
    id: 'name',
    label: 'Title',
    value: 'name',
    sortable: false,
    width: 300,
  },
  {
    id: 'modelName',
    label: 'Model',
    value: 'modelName',
    sortable: false,
  },
  {
    id: 'description',
    label: 'Description',
    value: 'description',
    sortable: false,
    width: 300,
    addReadMore: true,
  },
  {
    id: 'manufacturer',
    label: 'Brand',
    value: 'manufacturer',
    sortable: false,
  },
];
