import { OrderRequestItemSchema, OrderRequestItemStatusEnum } from 'app/types/schema';
import { OrderItemsExtraPropType } from '../OrderItems/type';
import { ORDER_VIEW } from '../../views/OrderDetail/type';
import FormProjectSelect from '../../../../components/ProcureForm/FormProjectSelect';
import { useCallback } from 'react';
import useOrderEditPermissionCheck from '../../hook/useOrderEditPermissionCheck';

interface Props {
  orderRequestItem: OrderRequestItemSchema;
  state: OrderItemsExtraPropType['state'];
  actions: OrderItemsExtraPropType['actions'];
}

const OrderRequestItemProjectView = (props: Props) => {
  const { orderRequestItem, state, actions } = props;
  const { orderView, orderDetail, editableOrderRequestItems } = state;

  const { isOrderRequestItemEditable, getEditableOrderItems } = useOrderEditPermissionCheck({
    orderDetail,
  });

  const onProjectChange = useCallback(
    (value: string, projectData: any) => {
      actions.onUpdateOrderItem(orderRequestItem.id, {
        projectId: value,
        project: projectData,
      });
    },
    [
      actions.onUpdateOrderItem,
      orderRequestItem.id,
      getEditableOrderItems,
      editableOrderRequestItems,
    ],
  );

  if (orderView === ORDER_VIEW.EDIT_ORDER_VIEW && isOrderRequestItemEditable(orderRequestItem)) {
    return (
      <FormProjectSelect
        key={`edit-order-project-${orderRequestItem.id}`}
        className="w-[160px]"
        innerStyle={{ display: "block"}}
        placeholder="No Project"
        size="small"
        value={orderRequestItem.projectId || ''}
        onChange={onProjectChange}
      />
    );
  }

  return (
    <div className="truncate" title={orderRequestItem?.project?.name || ''}>
      {orderRequestItem?.project?.name || ''}
    </div>
  );
};

export default OrderRequestItemProjectView;
