import { Button, DataGridHeadCell } from '@procurenetworks/procure-component-library';
import { Maybe } from 'graphql/jsutils/Maybe';
import Receivables from 'app/i18n/Receivables';
import DateTableFilter from 'app/modules/components/DateTableFilter';
import { dropdownHeaderStyle } from 'app/modules/components/EntityManager/EntityManagerTable/styles';
import ReadMore from 'app/modules/components/ReadMore';
import OrderLink from 'app/modules/orders/components/OrderLink';
import OrderRequestItemImageView from 'app/modules/orders/components/OrderRequestItemImageView';
import OrderRequestItemSku from 'app/modules/orders/components/OrderRequestItemSku';
import TableOrderIdView from 'app/modules/orders/views/OrderHistory/components/OrderIdNode';
import UsersTableFilter from 'app/modules/users/components/UsersTableFilter';
import { ReceivableStatusEnum, ValuesInEntitiesDistinctByKeysEnum } from 'app/types/schema';
import { DateFormatEnum, formatDate } from 'app/utils/date';

import ReceivingHistoryNotesNode from '../history/components/ReceivingNotesHistroyNode';
import StatusTableFilter from '../history/components/StatusTableFilter';
import ReceiveToLocation from '../Receive/components/ReceiveLocationValueNode';
import ReceiveQuantity from '../Receive/components/ReceiveQuantityValueNode';
import ReceiveSiteValueNode from '../Receive/components/ReceiveSiteValueNode';
import ReceiveNotesValueNode from '../Receive/components/ReceivingNoteValueNode';
import TableColumnFilter from '../Receive/components/TableColumnFilter';
import { getReceivingSKUItemData } from './getReceivingOrderRequestItem';
import { getReceiveStatusLabel } from './receivingStatuses';

const OrderRequestItemDescription = ({ row }: any) => {
  const title =
    row.orderRequestItem?.upcCode ||
    row.orderRequestItem?.website ||
    row.orderRequestItem?.description ||
    row.item?.title ||
    '';
  return (
    <>
      <span className="truncate" title={title}>
        {title}
      </span>
    </>
  );
};

export const getReceiveTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'carrier',
    label: 'Carrier',
    value: 'carrier',
  },
  {
    id: 'trackNumber',
    label: 'Tracking Number',
    value: 'trackingNumber',
  },
  {
    id: 'vendor',
    label: 'Vendor/Sender',
    value: 'vendor',
    valueNode: ({ row }: any) => (
      <span className="truncate" title={row?.vendor?.companyName || ''}>
        {row?.vendor?.companyName || ''}
      </span>
    ),
  },
  {
    id: 'dueDate',
    label: 'Due Date/ETA',
    value: 'dueDate',
    valueNode: ({ row }: any) => (
      <span
        className="truncate"
        title={row?.dueDate ? formatDate(row.dueDate, DateFormatEnum.Date) : ''}>
        {row?.dueDate ? formatDate(row.dueDate, DateFormatEnum.Date) : ''}
      </span>
    ),
  },

  {
    id: 'notes',
    label: 'Shipping Notes',
    value: 'notes',
    valueNode: ({ row }: any) => (
      <span className="truncate" title={row?.notes || ''}>
        {row?.notes || ''}
      </span>
    ),
  },
  {
    id: 'procureOrderId',
    label: 'Order ID',
    value: 'procureOrderId',
    valueNode: ({ row }: any) => (
      <span className="truncate" title={row?.orderRequest?.orderRequestCode || ''}>
        {row?.orderRequest?.orderRequestCode || ''}
      </span>
    ),
  },
  {
    id: 'procureOrderTitle',
    label: 'Order Title',
    value: 'procureOrderTitle',
    valueNode: ({ row }) => (
      <span className="truncate" title={row?.orderRequest?.title || ''}>
        {row?.orderRequest?.title || ''}
      </span>
    ),
  },
  {
    id: 'deliverTo',
    label: 'Deliver To',
    value: 'deliverTo',
    valueNode: ({ row }) => (
      <span className="truncate" title={row?.orderRequest?.deliverTo?.name || ''}>
        {row?.orderRequest?.deliverTo?.name || ''}
      </span>
    ),
  },
];

export const getShipmentReceiveTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'procureOrderId',
    label: 'Order ID',
    value: 'procureOrderId',
    valueNode: ({ row }: any) => (
      <span className="truncate" title={row?.orderRequest?.orderRequestCode || ''}>
        {row?.orderRequest?.orderRequestCode || ''}
      </span>
    ),
  },
  {
    id: 'procureOrderTitle',
    label: 'Order Title',
    value: 'procureOrderTitle',
    valueNode: ({ row }) => (
      <span className="truncate" title={row?.orderRequest?.title || ''}>
        {row?.orderRequest?.title || ''}
      </span>
    ),
  },
  {
    id: 'deliverTo',
    label: 'Deliver To',
    value: 'deliverTo',
    valueNode: ({ row }) => (
      <span className="truncate" title={row?.orderRequest?.deliverTo?.name || ''}>
        {row?.orderRequest?.deliverTo?.name || ''}
      </span>
    ),
  },
  {
    id: 'notes',
    label: 'Shipping Notes',
    value: 'notes',
    valueNode: ({ row }: any) => (
      <span className="truncate" title={row?.notes || ''}>
        {row?.notes || ''}
      </span>
    ),
  },
  {
    id: 'action',
    label: 'Action',
    value: 'action',
    width: 150,
    valueNode: ({ row, state }) => {
      return (
        <Button
          theme="danger"
          onClick={() => {
            state?.extraProps?.onRemoveRow(row.id);
          }}>
          Remove
        </Button>
      );
    }
  }
];

export const getReceivedItemsInProgressColumns = () => [
  {
    id: 'carrier',
    label: 'Carrier',
    value: 'carrier',
    width: 100,
  },
  {
    id: 'trackNumber',
    label: 'Tracking Number',
    value: 'trackingNumber',
  },
  {
    id: 'vendor',
    label: 'Vendor/Sender',
    value: 'vendor',
    labelNode: (props: any) => {
      const { extraProps } = props.state;
      const { filtersState, setFilterState } = extraProps || {};
      return (
        <div>
          <TableColumnFilter
            isDistinct
            name="vendor"
            placeholder={'Vendor/Sender'}
            queryVariables={{
              distinctKeys: [ValuesInEntitiesDistinctByKeysEnum.VendorId],
              shouldSortOptions: true,
            }}
            value={filtersState?.vendor}
            onChange={(value: Maybe<string[]>) => {
              setFilterState?.({
                vendor: value,
              });
            }}
          />
        </div>
      );
    },
    valueNode: ({ row }: any) => (
      <span className="truncate" title={row?.vendor?.companyName || ''}>
        {row?.vendor?.companyName || ''}
      </span>
    ),
  },
  {
    id: 'dueDate',
    labelNode: (props: any) => {
      const { extraProps } = props.state;
      const { filterState, setFilterState } = extraProps || {};
      return (
        <div>
          <DateTableFilter
            key="due-date-filter"
            placeholder="Due Date/ETA"
            value={filterState?.dueDate}
            onChange={(value) => {
              setFilterState?.({
                dueDate: value,
              });
            }}
          />
        </div>
      );
    },
    value: 'dueDate',
    valueNode: ({ row }: any) => (
      <span
        className="truncate"
        title={row?.dueDate ? formatDate(row.dueDate, DateFormatEnum.Date) : ''}>
        {row?.dueDate ? formatDate(row.dueDate, DateFormatEnum.Date) : ''}
      </span>
    ),
  },

  {
    id: 'notes',
    label: 'Shipment Notes',
    value: 'notes',
    valueNode: ({ row }: any) => <ReadMore text={row?.notes || ''} />,
    minWidth: 155,
  },
  {
    id: 'procureOrderId',
    labelNode: (props: any) => {
      const { extraProps } = props.state;
      const { filtersState, setFilterState } = extraProps || {};
      return (
        <div>
          <TableColumnFilter
            isDistinct
            name="procureOrderId"
            placeholder={'OrderID'}
            queryVariables={{
              distinctKeys: [ValuesInEntitiesDistinctByKeysEnum.OrderRequestId],
              statuses: [ReceivableStatusEnum.InProgress],
            }}
            value={filtersState?.vendor}
            onChange={(value: Maybe<string[]>) => {
              setFilterState?.({
                orderRequestIds: value,
              });
            }}
          />
        </div>
      );
    },
    value: 'procureOrderId',
    minWidth: 175,
    valueNode: ({ row }: any) => {
      return (
        <>
          {row?.orderRequest ? (
            <TableOrderIdView row={row?.orderRequest || {}} target="_blank" />
          ) : (
            ''
          )}
        </>
      );
    },
  },
  {
    id: 'procureOrderTitle',
    label: 'Order Title',
    value: 'procureOrderTitle',
    valueNode: ({ row }: any) => (
      <span className="truncate" title={row?.orderRequest?.title || ''}>
        {row?.orderRequest?.title || ''}
      </span>
    ),
    minWidth: 100,
  },
  {
    id: 'title',
    label: 'Item Description/Title',
    value: 'title',
    valueNode: ({ row }: any) => <OrderRequestItemDescription row={row} />,
    width: 220,
  },

  {
    id: 'quantityOrdered',
    label: 'Quantity Ordered',
    value: 'quantityOrdered',
    valueNode: ({ row }: any) => (
      <span className="truncate" title={row?.quantityPending || ''}>
        {row?.quantityPending || ''}
      </span>
    ),
    minWidth: 155,
  },
  {
    id: 'quantityReceived',
    label: 'Quantity Received',
    value: 'quantityReceived',
    valueNode: ReceiveQuantity,
    width: 210,
  },
  {
    id: 'Receiving Notes',
    value: 'receivingNotes',
    label: 'Receiving Notes',
    valueNode: ReceiveNotesValueNode,
    width: 200,
  },
  {
    id: 'receiveSite',
    value: 'receiveSite',
    classes: dropdownHeaderStyle,
    sortable: false,
    valueNode: ReceiveSiteValueNode,
    labelNode: ({ row }: any) => (
      <span className="text-[14px] font-semibold text-grey-900">Receive To Site <span className="text-red-800">*</span></span>
    ),
    minWidth: 175,
  },
  {
    id: 'receiveLocation',
    value: 'receiveLocation',
    classes: dropdownHeaderStyle,
    sortable: false,
    valueNode: ReceiveToLocation,
    labelNode: ({ row }: any) => (
      <span className="text-[14px] font-semibold text-grey-900">Receive To Location <span className="text-red-800">*</span></span>
    ),
    minWidth: 195,
  },
];

export const getReceivingHistoryOrderTypeColumn = () => [
  {
    id: 'procureOrderId',
    label: 'Order ID',
    value: 'procureOrderId',
    valueNode: ({ row }: any) => <TableOrderIdView row={row?.orderRequest || {}} target="_blank" />,
    labelNode: (props: any) => {
      const { extraProps } = props.state;
      const { filtersState, setFilterState } = extraProps || {};
      return (
        <div>
          <TableColumnFilter
            isDistinct
            name="procureOrderId"
            placeholder={'OrderID'}
            queryVariables={{
              distinctKeys: [ValuesInEntitiesDistinctByKeysEnum.OrderRequestId],
              statuses: [],
            }}
            value={filtersState?.orderRequestIds}
            onChange={(value: Maybe<string[]>) => {
              setFilterState?.({
                orderRequestIds: value,
              });
            }}
          />
        </div>
      );
    },
    width: 200,
  },
  {
    id: 'procureOrderTitle',
    label: 'Order Title',
    value: 'procureOrderTitle',
    valueNode: ({ row }: any) => (
      <span className="truncate" title={row?.orderRequest?.title || ''}>
        {row?.orderRequest?.title || ''}
      </span>
    ),
  },
  {
    id: 'deliverTo',
    label: 'Deliver To',
    value: 'deliverTo',
    valueNode: ({ row }: any) => (
      <span className="truncate" title={row?.orderRequest?.deliverTo?.name || ''}>
        {row?.orderRequest?.deliverTo?.name || ''}
      </span>
    ),
    labelNode: (props: any) => {
      const { extraProps } = props.state;
      const { filterState, setFilterState } = extraProps || {};
      return (
        <div>
          <UsersTableFilter
            name="deliverTo"
            placeholder="Deliver To"
            value={filterState?.deliverTo}
            onChange={(values: string[]) => {
              setFilterState?.({
                deliverTo: values,
              });
            }}
          />
        </div>
      );
    },
  },
  {
    id: 'notes',
    label: 'Shipment Notes',
    value: 'notes',
    valueNode: ({ row }: any) => (
      <span className="truncate" title={row?.notes || ''}>
        {row?.notes || ''}
      </span>
    ),
  },
  {
    id: 'status',
    label: 'Status',
    value: 'status',
    valueNode: ({ row }: any) => (
      <span className="truncate" title={getReceiveStatusLabel(row?.status || '')}>
        {getReceiveStatusLabel(row?.status || '')}
      </span>
    ),
    labelNode: (props: any) => {
      const { extraProps } = props.state;
      const { filtersState, setFilterState } = extraProps || {};
      return (
        <div>
          <StatusTableFilter
            name="createdBy"
            placeholder={Receivables.FormLabels.Status}
            value={filtersState?.statuses}
            onChange={(value) => {
              setFilterState?.({
                statuses: value,
              });
            }}
          />
        </div>
      );
    },
  },
  {
    id: 'action',
    label: 'Action',
    value: 'action',
    valueNode: ({ row, state }: { row: any; state: any }) => {
      const { extraProps } = state;
      const { deleteOrderState, handleBulkDelete } = extraProps || {};
      const disabled =
        row.status !== ReceivableStatusEnum.Completed ||
        (extraProps.isDeleting && extraProps.deletingEntityId === row.id);
      return (
        <Button
          classes="min-w-[94px] h-[44px]"
          disabled={disabled}
          loading={deleteOrderState?.deletingOrderId === row.id}
          theme="danger"
          onClick={() => {
            handleBulkDelete?.(row.id);
          }}>
          Delete
        </Button>
      );
    },
  },
];

export const getReceivingHistoryItemTypeColumn = () => {
  return [
    {
      id: 'carrier',
      label: 'Carrier',
      value: 'carrier',
    },
    {
      id: 'trackNumber',
      label: 'Tracking Number',
      value: 'trackingNumber',
    },
    {
      id: 'vendor',
      label: 'Vendor/Sender',
      value: 'vendor',
      valueNode: ({ row }: any) => (
        <span className="truncate" title={row?.vendor?.companyName || ''}>
          {row?.vendor?.companyName || ''}
        </span>
      ),
    },
    {
      id: 'dueDate',
      label: 'Due Date/ETA',
      value: 'dueDate',
      valueNode: ({ row }: any) => (
        <span className="truncate">
          {row?.dueDate ? formatDate(row.dueDate, DateFormatEnum.Date) : ''}
        </span>
      ),
      width: 135,
    },
    {
      id: 'imageUrl',
      label: 'Image',
      value: 'imageUrl',
      valueNode: ({ row }: { row: any }) => (
        <OrderRequestItemImageView orderRequestItem={row || {}} />
      ),
      width: 100,
    },
    {
      id: 'sku',
      label: 'SKU',
      value: 'sku',
      valueNode: ({ row }: { row: any }) => {
        return (
          <OrderRequestItemSku
            withLink
            orderRequestItem={getReceivingSKUItemData(row)}
            target="_blank"
          />
        );
      },
    },
    {
      id: 'title',
      label: 'Item Description/Title',
      value: 'title',
      valueNode: ({ row }: any) => <OrderRequestItemDescription row={row} />,
      width: 200,
    },
    {
      id: 'notes',
      label: 'Notes',
      value: 'notes',
      valueNode: ({ row }: any) => (
        <ReceivingHistoryNotesNode receivingHistory={row.receivingHistory} />
      ),
      width: 200,
    },
    {
      id: 'status',
      label: 'Status',
      value: 'status',
      valueNode: ({ row }: any) => (
        <span className="truncate" title={getReceiveStatusLabel(row?.status || '')}>
          {getReceiveStatusLabel(row?.status || '')}
        </span>
      ),
    },
  ];
};

export const selectItemTableColumns = (options?: {}): DataGridHeadCell[] => {
  return [
    {
      id: 'orderId',
      label: 'OrderId',
      value: 'orderRequestCode',
      valueNode: ({ row }: any) => {
        return (
          <OrderLink shouldTruncate id={row?.orderRequestId} target={'_blank'} type={row.orderType}>
            {row?.orderRequest?.orderRequestCode}
          </OrderLink>
        );
      },
      sortable: true,
    },
    {
      id: 'orderTitle',
      label: 'Order Title',
      value: 'orderRequest.title',
      valueNode: ({ row }: any) => (
        <span className="truncate" title={row.orderRequest?.title}>
          {row.orderRequest?.title}
        </span>
      ),
      sortable: true,
    },
    {
      id: 'itemDescription',
      label: 'Item Description/Title',
      value: 'itemDescription',
      valueNode: ({ row }: any) => (
        <span className="truncate" title={row?.itemDescription || ''}>
          {row?.itemDescription || ''}
        </span>
      ),
      sortable: true,
      width: 220,
    },
    {
      id: 'quantity',
      label: 'Quantity Ordered',
      value: 'quantity',
      valueNode: ({ row }: any) => (
        <span className="truncate" title={row.quantity}>
          {row.quantity}
        </span>
      ),
      sortable: true,
    },
  ] as DataGridHeadCell[];
};
