import { memo, useCallback, useEffect, useMemo } from 'react';
import { useUpdateEffect } from 'react-use';
import { cx } from '@emotion/css';
import { SxProps, Theme } from '@mui/material';
import {
  Button,
  DataGrid,
  DataGridComponentState,
  GridColDef,
  GridSortModel,
  useLocalStorage,
} from '@procurenetworks/procure-component-library';
import { isEmpty } from 'lodash';
import Common from 'app/i18n/Common';
import { useCategoriesTableQuery } from 'app/modules/categories/views/Categories/graphql/queries/generated/categoriesTable';
import { useSitesTableQuery } from 'app/modules/sites/views/Sites/graphql/queries/generated/sitesTable';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  SortOrderEnum,
  UserSchema,
} from 'app/types/schema';
import Box from 'app/ui-components';
import Stack from 'app/ui-components/Stack';

import { useAccessControl } from '../../../../components/AccessControl';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import DeleteManagersModal, { DeleteManagers } from '../DeleteManagersModal';
import { tableDropDownStyle, tableLoadingStyles } from '../EntityManagerTable/styles';
import { EntityMangerMultiSelectTableProps } from '../EntityManagerTable/types';
import { useEntityManagerContext } from '../index';
import {
  getPageSettingsFromStorage,
  savePageSettingsToStorage,
} from './../../../../utils/paginationSettingsUtil';
import ReadMore from '../../ReadMore';

function EntityManagerMultiSelectTable(props: EntityMangerMultiSelectTableProps) {
  const {
    actions,
    columns: headers,
    multipleSelection = true,
    data,
    minWidth = 1250,
    state,
    setState,
    total = 0,
    loading,
    pagination,
    fetchMore,
    extraProps,
    defaultSortState,
    ignoreRelayPagination,
    onNextPage,
    onPrevPage,
    onReset,
    persistSelectionData,
    persistKey,
    getDetailPanelContent,
    getDetailPanelHeight,
    getRowId,
    onRowClick,
    isRowSelectable,
    disableSelection,
    isItAsset,
    disableEdit,
    hideAllSelectionCheckbox,
    rowHeight,
    footerRows,
    sortingMode = 'server',
    onSortModelChangeFunc,
    filterNodes,
    paginationWrapperClass,
  } = props;

  const { permissions, subject } = useEntityManagerContext();
  const canEdit = useAccessControl(
    permissions,
    subject === AllowedPermissionsSubjectEnum.PartnerTenant
      ? AllowedPermissionActionsEnum.Manage
      : AllowedPermissionActionsEnum.Edit,
    subject,
  );
  const canDelete = useAccessControl(
    permissions,
    subject === AllowedPermissionsSubjectEnum.PartnerTenant
      ? AllowedPermissionActionsEnum.Manage
      : AllowedPermissionActionsEnum.Delete,
    subject,
  );
  const canEditOrDelete = canEdit || canDelete;

  const canImport = useAccessControl(
    permissions,
    AllowedPermissionActionsEnum.Import,
    AllowedPermissionsSubjectEnum.ItAsset,
  );

  const [datagridState, setDatagridState] = useLocalStorage(persistKey);
  const selection = useMemo(() => {
    if (subject && subject === AllowedPermissionsSubjectEnum.Undelete) {
      return state.selection;
    } else if (subject) {
      return canEditOrDelete;
    } else {
      return state.selection;
    }
  }, [canEditOrDelete, state.selection, subject]);

  useEffect(() => {
    const sortModel = datagridState?.sorting?.sortModel;
    setState({
      activePage: 0,
      sortState: sortModel?.length
        ? {
          id: sortModel[0].field,
          orderBy: sortModel[0].sort
            ? sortModel[0].sort
            : defaultSortState?.orderBy || ('asc' as any),
        }
        : undefined,
      sorts: sortModel?.length
        ? [
          {
            sortField: sortModel[0].field,
            sortOrder: sortModel[0].sort === 'asc' ? SortOrderEnum.Asc : SortOrderEnum.Desc,
          },
        ]
        : [],
    });
    onReset?.();
  }, [setState, total]);

  const [{ data: categoryManagerName }] = useCategoriesTableQuery({
    variables: {
      filters: {
        categoryManagerIds: state.selectedRowIds,
      },
    },
    requestPolicy: 'network-only',
  });

  const [{ data: siteManagerName }] = useSitesTableQuery({
    variables: {
      filters: {
        siteManagerIds: state.selectedRowIds,
      },
    },
    requestPolicy: 'network-only',
  });

  const managersName = useMemo(() => {
    const categoryManagerNames = state.selectedRowData
      .filter((item) => {
        return item?.roles?.some((role: any) => role.name === 'Category Manager');
      })
      .map((item) => `${item.firstName} ${item.lastName}`);

    const siteManagerNames = state.selectedRowData
      .filter((item) => {
        return item?.roles?.some((role: any) => role.name === 'Site Manager');
      })
      .map((item) => `${item.firstName} ${item.lastName}`);

    return {
      categoryManagerNames,
      siteManagerNames,
    };
  }, [state.selectedRowData.length]);

  const rows = useMemo(() => {
    if (ignoreRelayPagination) {
      return data || [];
    }

    const initialIndex = state.activePage * state.numberOfRowsPerPage;
    return (data || []).slice(initialIndex, initialIndex + state.numberOfRowsPerPage);
  }, [data, state.activePage, state.numberOfRowsPerPage, ignoreRelayPagination]);

  const deletingManagersInfo = useMemo(() => {
    const categoriesAffected = categoryManagerName?.categories.edges?.filter?.((category: any) =>
      category.node.categoryManagers.every((categoryManager: UserSchema) =>
        state.selectedRowIds.includes(categoryManager.id),
      ),
    );

    const userWithCategory: DeleteManagers[] = [];

    categoriesAffected?.forEach?.((category) =>
      category.node.categoryManagers.forEach((categoryManager: Partial<UserSchema>) => {
        userWithCategory.push({
          assignedTo: category?.node?.name,
          userName: categoryManager?.name as string,
          role: 'Category Manager',
        });
      }),
    );

    const siteAffected = siteManagerName?.locations?.edges?.filter?.((location: any) =>
      location.node.siteManagers.every((siteManager: UserSchema) =>
        state.selectedRowIds.includes(siteManager.id),
      ),
    );
    const userWithSites: DeleteManagers[] = [];
    siteAffected?.forEach?.((site) => {
      site.node.siteManagers.forEach((siteManager: Partial<UserSchema>) => {
        userWithSites.push({
          assignedTo: site.node.name,
          userName: siteManager?.name as string,
          role: 'Site Manager',
        });
      });
    });

    return {
      categoryManagers: userWithCategory || [],
      siteManagers: userWithSites || [],
    };
  }, [categoryManagerName, siteManagerName]);

  const onRowSelectionChange = useCallback(
    (rowIds: any[]) => {
      setState({ selectedRowIds: rowIds });
    },
    [setState],
  );

  const onTableChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      const fetchedRows = data?.length || 0;
      const fetchedPages = Math.ceil(fetchedRows / state.numberOfRowsPerPage);

      if (page > fetchedPages) {
        fetchMore?.();
      }

      if (page > state.activePage) {
        onNextPage?.();
      } else if (page < state.activePage) {
        onPrevPage?.();
      }
      setState({ activePage: page, ...(!persistSelectionData ? { selectedRowIds: [] } : {}) });
    },
    [
      data?.length,
      fetchMore,
      onNextPage,
      onPrevPage,
      setState,
      state.activePage,
      state.numberOfRowsPerPage,
      persistSelectionData,
    ],
  );

  const onTableChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      savePageSettingsToStorage(event.target.value);
      setState({
        activePage: 0,
        numberOfRowsPerPage: +event.target.value,
        ...(!persistSelectionData ? { selectedRowIds: [] } : {}),
      });
    },
    [setState, persistSelectionData],
  );

  const onSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      if (onSortModelChangeFunc) {
        return onSortModelChangeFunc(sortModel);
      } else {
        setState({
          activePage: 0,
          sortState: sortModel.length
            ? {
              id: sortModel[0].field,
              orderBy: sortModel[0].sort
                ? sortModel[0].sort
                : defaultSortState?.orderBy || ('asc' as any),
            }
            : undefined,
          sorts: sortModel.length
            ? [
              {
                sortField: sortModel[0].field,
                sortOrder: sortModel[0].sort === 'asc' ? SortOrderEnum.Asc : SortOrderEnum.Desc,
              },
            ]
            : [],
        });
      }
    },
    [setState],
  );

  const dataGridComponentState = {
    extraProps,
    headers,
    page: state.activePage,
    rows,
    rowsPerPage: state.numberOfRowsPerPage,
  } as DataGridComponentState;

  const columns: GridColDef[] = useMemo(() => {
    const originalColumns = headers.map((headCell: any, index: number) => ({
      align: headCell.rowAlign,
      field: headCell.value,
      headerName: headCell.label,
      headerClassName: headCell.classes,
      editable: headCell.editable,
      type: headCell.type,
      valueOptions: headCell.valueOptions,
      renderCell: headCell.valueNode
        ? (parameters: any) => (
          <headCell.valueNode
            headCell={headCell}
            row={parameters.row}
            state={dataGridComponentState}
          />
        )
        : headCell.addReadMore
          ? (parameters: any) => <ReadMore text={parameters.value} />
          : undefined,
      renderHeader: headCell.labelNode
        ? () => {
          const LabelComponent = headCell.labelNode;
          return <LabelComponent headCell={headCell} state={dataGridComponentState} />;
        }
        : undefined,
      valueGetter: headCell.valueGetter ? headCell.valueGetter : undefined,
      sortable: headCell.sortable || false,
      hideable: index === 0 ? false : true,
      width: headCell.width ? headCell.width : 200,
      minWidth: (headCell.minWidth || 80) + (headCell.sortable ? 20 : 0),
    }));

    let savedOrder = datagridState?.columns?.all;
    if (persistKey) {
      const savedState = localStorage.getItem(persistKey);
      if (savedState && savedState !== "null") {
        const parsedState = JSON.parse(savedState);
        savedOrder = parsedState.columns.all || datagridState?.columns?.all;
      }
    }

    if (!savedOrder || savedOrder.length === 0) {
      return originalColumns;
    }

    const columnMap = Object.fromEntries(originalColumns.map(col => [col.field, col]));

    const persistedColumns = savedOrder
      .filter((field: string) => columnMap[field])
      .map((field: string, index: number) => ({
        ...columnMap[field],
        hideable: index === 0 ? false : columnMap[field].hideable,
      }));

    return persistedColumns.length === originalColumns.length ? persistedColumns : originalColumns;
  }, [headers, dataGridComponentState, datagridState, persistKey]);

  useUpdateEffect(() => {
    if (!loading && !rows.length && state.activePage > 0 && ignoreRelayPagination) {
      setState({ activePage: 0 });
      onReset?.();
    }
  }, [rows.length]);

  const sx = useMemo(() => {
    return hideAllSelectionCheckbox
      ? ({
        '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
          display: 'none',
        },
        '& .MuiDataGrid-row--detailPanelExpanded.MuiDataGrid-row.MuiDataGrid-row--lastVisible': {
          borderBottom: '1px solid #E0E0E0FF',
        },
      } as SxProps<Theme>)
      : ({
        '& .MuiDataGrid-row--detailPanelExpanded.MuiDataGrid-row.MuiDataGrid-row--lastVisible': {
          borderBottom: '1px solid #E0E0E0FF',
        },
      } as SxProps<Theme>);
  }, [hideAllSelectionCheckbox]);

  // Read Records Per Page From Session Storage.
  let rowsPerPage = getPageSettingsFromStorage(state.numberOfRowsPerPage);

  return (
    <>
      {!isEmpty(deletingManagersInfo.categoryManagers) ||
        !isEmpty(deletingManagersInfo.siteManagers) ? (
        <DeleteManagersModal
          deletingManagersInfo={deletingManagersInfo}
          open={state.showDeleteConfirmation}
          onCancel={actions.onCancelDelete}
          onConfirm={actions.onConfirmDelete}
        />
      ) : (
        <DeleteConfirmationModal
          open={state.showDeleteConfirmation}
          totalSelectedRows={state.selectedRowIds.length}
          onCancel={actions.onCancelDelete}
          onConfirm={actions.onConfirmDelete}
        />
      )}
      <Box className={cx(tableDropDownStyle, loading && tableLoadingStyles)}>
        <DataGrid
          columns={columns}
          count={total}
          currentPage={state.activePage}
          footerRow={footerRows}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          getRowId={getRowId}
          isRowSelectable={(params) => (isRowSelectable ? isRowSelectable?.(params.row) : true)}
          keepNonExistentRowsSelected={persistSelectionData}
          loading={!!loading}
          multipleSelection={multipleSelection}
          pagination={!!pagination}
          paginationMode="server"
          paginationWrapperClass={paginationWrapperClass}
          persistKey={persistKey}
          rowHeight={rowHeight || 52}
          rows={rows}
          rowsPerPage={rowsPerPage}
          selectedItems={state.selectedRowIds}
          selection={disableSelection ? !disableSelection : selection}
          sortingMode={sortingMode}
          sx={sx}
          tableFilters={filterNodes}
          onRowClick={onRowClick}
          onRowSelectionChange={onRowSelectionChange}
          onSortModelChange={onSortModelChange}
          onTablePageChange={onTableChangePage}
          onTableRowsPerPageChange={onTableChangeRowsPerPage}
        />
      </Box>

      {!!actions && canEditOrDelete && !actions?.hideActions ? (
        <Stack className="mt-[16px] gap-[16px] px-[24px]" justifyContent="end">
          {canEdit && actions.onEdit ? (
            <Button
              classes="min-w-[94px] h-[44px]"
              disabled={disableEdit || !(state.selectedRowIds?.length === 1)}
              theme="info"
              onClick={actions.onEdit}>
              {Common.Actions.Edit}
            </Button>
          ) : null}
          {canDelete && !isItAsset ? (
            <Button
              classes="min-w-[94px] h-[44px]"
              disabled={!state.selectedRowIds.length}
              theme="danger"
              onClick={actions.onShowDeleteConfirmation}>
              {Common.Actions.Delete}
            </Button>
          ) : null}
          {isItAsset && canImport ? (
            <Box className="py-[16px]">
              <Button
                classes="min-w-[94px] h-[44px]"
                disabled={!state.selectedRowIds.length}
                theme="info"
                onClick={actions.onImport}>
                {Common.Actions.Import}
              </Button>
            </Box>
          ) : null}
        </Stack>
      ) : null}
    </>
  );
}

export default memo(EntityManagerMultiSelectTable);
